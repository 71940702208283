import React from 'react';
import { Alert, Breadcrumb, Divider, PageHeader, Tabs } from 'antd';
import { isAuth, _setAxios } from '../../../../lib/Helper';
import { Row, Col } from 'antd';
import { connect } from 'react-redux'
import AuthRedirect from '../../../../components/AuthRedirect'
import { DoubleRightOutlined } from '@ant-design/icons';

import "../style.css"
import ProfileHeader from './ProfileHeader';
import DataDiri from './DataDiri';
import OrangTua from './OrangTua';
import Berkas from './Berkas';

const { TabPane } = Tabs;
class ProfilCalonPesertaDidik extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      auth: true,
      loading: true,
      id: this.props.match.params.id,
      dataDiri: null,

      isOpen: true
    };
  }

  componentDidMount() {
    this.getDataCalonSiswa(this.state.id)
  }

  getDataCalonSiswa = (id) => {
    _setAxios("ppdb/siswa/data-diri/" + id, "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data

        let dataHeader = {
          loading: false,
          imageProfile: data.foto ? data.main_path + data.foto : null,
          data1: [{
            title: 'Jenis Jalur',
            description: data.ppdb_jalur_kategori ? data.ppdb_jalur_kategori + " ("+data.ppdb_jalur_nama+")" : "-",
          }, {
            title: 'NISN',
            description: data.nisn ? data.nisn : "-",
          }, {
            title: 'NIK',
            description: data.nik ? data.nik : "-",
          }],
        }

        this.setState({
          dataHeader: dataHeader,
        })
      }
    })
  }

  render() {
    const access = this.props.privilege.access["/peserta-didik/calon"]
    const action = access !== undefined ? access.action : null

    if (!isAuth(this.props.privilege) || access === undefined || !action.detail) {
      return <AuthRedirect />
    }

    const { dataHeader } = this.state
    const tabData = [
      {
        title: "Data Diri",
        component: <DataDiri action={action} dataID={this.state.id} dataState={this.state} {...this.props} />
      },
      {
        title: "Data Orang Tua",
        component: <OrangTua action={action} dataID={this.state.id} dataState={this.state} {...this.props} />
      },
      {
        title: "Berkas",
        component: action.file_view ? <Berkas action={action} dataID={this.state.id} dataState={this.state} {...this.props} /> : <Alert message="Mohon maaf, kamu tidak memiliki akses untuk melihat halaman ini, silahkan hubungi operator/admin" type="warning" showIcon />
      },
    ]


    if (isAuth(this.props.privilege)) {
      return (
        <>
          <PageHeader
            style={{
              padding: 0
            }}
            onBack={() => window.history.back()}
            title={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
              <Breadcrumb.Item>Profil Calon Peserta Didik</Breadcrumb.Item>
            </Breadcrumb>}
          />

          {this.state.isOpen === null ? "" : (!this.state.isOpen ? <Alert message="Data tidak bisa diubah ketika masa pendaftaran telah di tutup.." type="warning" showIcon /> : "")}

          <br />
          <ProfileHeader data={dataHeader} />
          <Divider />
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Tabs defaultActiveKey={0} activeKey={this.state.tabIndex} onChange={this.handleChangeTab}>
                  {tabData.map((row, i) => <TabPane tab={row.title} key={i}> {row.component} </TabPane>)}
                </Tabs>
              </Col>
            </Row>
          </div>˝

        </>
      )
    } else {
      return <AuthRedirect />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ProfilCalonPesertaDidik)