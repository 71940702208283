import React from 'react';
import { Modal, Button, Form, DatePicker } from 'antd';
import { _success, _setAxios } from '../../../../../lib/Helper';
import moment from 'moment';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';


class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("keuangan/laporan/saldo/ekspor", "POST").then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        const { values } = this.state
        return (
            <Modal
                title="Export data laporan saldo"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Durasi">
                        <RangePicker
                            style={{
                                width: "100%", borderRadius: 4, padding: "6px 10px"
                            }}
                            format={dateFormat}
                            value={values?.tgl_awal && values?.tgl_akhir ? [moment(values.tgl_awal, dateFormat), moment(values.tgl_akhir, dateFormat)] : null}
                            onChange={val => {
                                if (!val || val.length === 0) {
                                    this.handleChangeSetValue("tgl_awal", null)
                                    this.handleChangeSetValue("tgl_akhir", null)
                                    return
                                }

                                let periodeAwal = moment(val[0]).format(dateFormat)
                                let periodeAkhir = moment(val[1]).format(dateFormat)

                                this.handleChangeSetValue("tgl_awal", periodeAwal)
                                this.handleChangeSetValue("tgl_akhir", periodeAkhir)
                            }}
                        />
                    </Form.Item>
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data laporan pemasukan akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
