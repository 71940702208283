import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Modal, Typography, Badge, Card, Image, Divider } from 'antd';

import "./style.css"
const { Title, Text } = Typography;

class DetailContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            validateFile: false,
            ddl: {
                jenis_izin: [],
                siswa: []
            }
        };
    }

    render() {

        const { data } = this.props
        const namaLengkap = data.nama

        data.lat_masuk = data.lat_masuk ? data.lat_masuk : "-"
        data.long_masuk = data.long_masuk ? data.long_masuk : "-"
        let coordinateMasuk = "-"
        if (data.lat_masuk && data.long_masuk) {
            coordinateMasuk = data.lat_masuk + ", " + data.long_masuk
        }

        data.lat_pulang = data.lat_pulang ? data.lat_pulang : "-"
        data.long_pulang = data.long_pulang ? data.long_pulang : "-"
        let coordinatePulang = "-"
        if (data.lat_pulang && data.long_pulang) {
            coordinatePulang = data.lat_pulang + ", " + data.long_pulang
        }


        const dataProfile = [
            {
                title: 'Kelas/Rombel',
                description: data.rombel_nama,
            },
        ]
        const dataProfile2 = [
            {
                title: 'Durasi Telat',
                description: data.durasi_telat,
            },
            {
                title: 'Durasi Pulang Cepat',
                description: data.durasi_pulang_cepat,
            },
        ]
        const dataMasuk = [
            {
                title: 'Jam Masuk',
                description: data.waktu_masuk,
            },
            {
                title: 'Jarak Masuk',
                description: data.radius_masuk,
            },
            {
                title: 'IP Masuk',
                description: data.ip_masuk,
            },
            {
                title: 'Koordinat Masuk',
                description: coordinateMasuk
            },
        ]

        const dataPulang = [
            {
                title: 'Jam Pulang',
                description: data.waktu_pulang,
            },
            {
                title: 'Jarak Pulang',
                description: data.radius_pulang,
            },
            {
                title: 'IP Pulang',
                description: data.ip_pulang,
            },
            {
                title: 'Koordinat Pulang',
                description: coordinatePulang
            },
        ]

        return (
            <Modal
                title={"Detail absensi"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                </>}
            >
                <div>
                    <Badge.Ribbon text={data.tgl_masuk}>
                        <Card title={<Title level={4}>{namaLengkap}</Title>} size="small">
                            <Row gutter={[16, 16]}>
                                <Col xs={12}>
                                    {

                                        dataProfile.map((item, i) =>
                                            <div key={i} style={{ marginBottom: 16 }}>
                                                <div><Text strong>{item.title}</Text></div>
                                                <div><Text>{item.description ? item.description : "-"}</Text></div>
                                            </div>
                                        )
                                    }
                                </Col>
                                <Col xs={12}>
                                    {

                                        dataProfile2.map((item, i) =>
                                            <div key={i} style={{ marginBottom: 16 }}>
                                                <div><Text strong>{item.title}</Text></div>
                                                <div><Text>{item.description ? item.description : "-"}</Text></div>
                                            </div>
                                        )
                                    }
                                </Col>
                            </Row>
                        </Card>
                    </Badge.Ribbon>
                    <Divider />
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <div><Text strong>Foto Masuk</Text></div>
                            {data.foto_masuk ? <Image src={data.main_path + data.foto_masuk} /> : "-"}
                        </Col>
                        <Col xs={12}>
                            <div><Text strong>Foto Pulang</Text></div>
                            {data.foto_pulang ? <Image src={data.main_path + data.foto_pulang} /> : "-"}
                        </Col>
                        <Col xs={12}>
                            {

                                dataMasuk.map((item, i) =>
                                    <div key={i} style={{ marginBottom: 16 }}>
                                        <div><Text strong>{item.title}</Text></div>
                                        <div><Text>{item.description ? item.description : "-"}</Text></div>
                                    </div>
                                )
                            }
                        </Col>
                        <Col xs={12}>
                            {

                                dataPulang.map((item, i) =>
                                    <div key={i} style={{ marginBottom: 16 }}>
                                        <div><Text strong>{item.title}</Text></div>
                                        <div><Text>{item.description ? item.description : "-"}</Text></div>
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </div>

            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(DetailContent);
