import React from 'react';
import { Modal, Button, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';

const dateFormat = 'YYYY-MM';
dayjs.extend(customParseFormat);

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    componentDidMount() {
        console.log("this.props.data", this.props.data)
        this.setState({
            values: this.props.data
        })
    }

    render() {
        const { values } = this.state;
        return (
            <Modal
                title="Filter"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Nama GTK *">
                        <SelectTenagaPendidik
                            value={values?.tk_id ? values.tk_id : null}
                            allowClear
                            onChange={(val) => {
                                this.props.setTempTableFilter("tk_id", val ? val : null)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Periode *">
                        <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={this.props.data.periode ? dayjs(this.props.data.periode, dateFormat) : null}
                            format={dateFormat}
                            picker="month"
                            onChange={(e, val) => {
                                this.props.setTempTableFilter("periode", val)
                            }} />
                    </Form.Item>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
