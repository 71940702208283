import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Input, Divider, Select } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';
import "./style.css"
import { daysOptions } from '../../../../data/options';

const { TextArea } = Input;
const { Option } = Select;

const schema = {
    nama_ekstrakurikuler: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tahun_ajaran: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    guru_ekstrakurikuler: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
            ddl: {
                tahunAjaran: [],
                ruangKelas: []
            }
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
        nama_ekstrakurikuler: this.props.data.nama,
                guru_ekstrakurikuler: this.props.data.tk_id,
                ruangan: this.props.data.ruangan_id
            },
        })
        this.getTahunAjaranDDL()
        this.getRuangKelasDDL()
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran/tahun", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    getRuangKelasDDL = () => {
        _setAxios("ruangan/dropdown", "POST", {
            jenis_ruangan_id: 5,
            is_booking: 0
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        ruangKelas: resp.data.data
                    },
                })
            }
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });
        let jadwals = []
        if (Array.isArray(values.jadwal) && values.jadwal.length > 0) {
            values.jadwal.forEach(el => {
                if (el.value) {
                    jadwals.push(el.value)
                } else {
                    jadwals.push(el)
                }
            });
        }
        const params = {
            nama: values.nama_ekstrakurikuler ? values.nama_ekstrakurikuler : null,
            tk_id: values.guru_ekstrakurikuler ? values.guru_ekstrakurikuler : null,
            ruangan_id: values.ruangan ? values.ruangan : null,
            tahun_ajaran: values.tahun_ajaran ? values.tahun_ajaran : null,
            keterangan: values.keterangan ? values.keterangan : null,
            jadwal: jadwals.length > 0 ? jadwals.toString() : null,
        }

        let endpoint = "kaldik-kegiatan/ekstrakurikuler/master"
        let method = "POST"
        if (values.id) {
            endpoint = "kaldik-kegiatan/ekstrakurikuler/master/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        const { values, ddl } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form Ekstrakurikuler"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Nama Ekstrakurikuler *">
                                <Input
                                    name="nama_ekstrakurikuler"
                                    placeholder="Nama Ekstrakurikuler"
                                    defaultValue={this.props.data.nama}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nama_ekstrakurikuler", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Tahun Ajaran *">
                                <Select
                                    allowClear
                                    name="tahun_ajaran"
                                    placeholder="Tahun ajaran"
                                    defaultValue={this.props.data.tahun_ajaran}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("tahun_ajaran", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {ddl.tahunAjaran.map((row, i) => {
                                        let value = row.thn_awal + "-" + row.thn_akhir
                                        return <Option key={i} value={value}>{value}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Guru Ekstrakurikuler *">
                                <SelectTenagaPendidik
                                    setValue={values.tk_id}
                                    setLabel={(values.gelar_depan ? values.gelar_depan + ". " : "") + values.nama_lengkap + (values.gelar_belakang ? ". " + values.gelar_belakang : "")}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("guru_ekstrakurikuler", val)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Nama Ruangan">
                                <Select
                                    allowClear
                                    name="ruangan"
                                    placeholder="Nama Ruangan"
                                    defaultValue={this.props.data.ruangan_id}
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("ruangan", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {ddl.ruangKelas.map((row, i) => <Option key={i} value={row.id}>{row.nama}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Jadwal Pertemuan">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    name="jadwal"
                                    placeholder="Jadwal pertemuan"
                                    defaultValue={this.props.data.jadwal}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("jadwal", val)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {daysOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="Keterangan"
                                    defaultValue={this.props.data.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
