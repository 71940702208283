import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Modal, Button, Table } from 'antd';
import TextField from '@mui/material/TextField';
import { _success, _setAxios } from '../../../../lib/Helper';

const schema = {
    attr2_val: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    attr3_val: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormWaktu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: [],
            errors: {},
            loading: false,
            visible: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getAbsensiRule()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    handleChangeSetValueArray = (idx, name, value) => {
        const { values } = this.state;
        values[idx][name] = value

        this.setState({
            values: values,
        });
    };



    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        this.setState({
            loading: true
        });

        let endpoint = "presensi/absensi/setting/rule"
        let method = "PUT"

        _setAxios(endpoint, method, values).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getAbsensiRule = () => {
        _setAxios("presensi/absensi/setting/rule", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10
            },
            "search": "",
            "sorting": []
        }).then(resp => {
            if (resp.status === true) {
                var data = []
                let no = 1
                for (let i = 0; i < resp.data.list.length; i++) {
                    const el = resp.data.list[i];
                    if (el.param_name === "Setting_Waktu") {
                        el.rownum = no
                        data.push(el)
                        no++
                    }
                }
                this.setState({
                    data: data,
                    values: data,
                });
            }
        })
    }

    render() {

        const columns = [
            {
                title: 'No',
                dataIndex: 'rownum',
                key: 'rownum',
            },
            {
                title: 'Hari',
                dataIndex: 'attr1_val',
            },
            {
                title: 'Jam Masuk',
                dataIndex: 'attr2_val',
                render: (i, row) => <>
                    <TextField
                        fullWidth
                        label={row.attr2_name}
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="attr2_val"
                        type="text"
                        onChange={(e) => {
                            this.handleChangeSetValueArray(row.rownum - 1, 'attr2_val', e ? e.target.value : null)
                        }}
                        value={values[row.rownum - 1] ? values[row.rownum - 1].attr2_val : row.attr2_val}
                        error={this.hasError('attr2_val')}
                        helperText={
                            this.hasError('attr2_val') ? errors.attr2_val[0] : null
                        }
                    />
                </>
            },
            {
                title: 'Jam Pulang',
                dataIndex: 'attr3_val',
                render: (i, row) => <>
                    <TextField
                        fullWidth
                        label={row.attr3_name}
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="attr3_val"
                        type="text"
                        onChange={(e) => {
                            this.handleChangeSetValueArray(row.rownum - 1, 'attr3_val', e ? e.target.value : null)
                        }}
                        value={values[row.rownum - 1] ? values[row.rownum - 1].attr3_val : row.attr3_val}
                        error={this.hasError('attr3_val')}
                        helperText={
                            this.hasError('attr3_val') ? errors.attr3_val[0] : null
                        }
                    />
                </>
            }
        ];

        const { values, errors } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Ubah Konfigurasi Waktu"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                width={800}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>&nbsp;
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit.bind(this)} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Table columns={columns} dataSource={this.state.data} pagination={false} />
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormWaktu);
