import React from 'react';
import { Modal, Button, Select, DatePicker, Form } from 'antd';
import moment from 'moment';

import { _setAxios, _success } from '../../../../../lib/Helper';
import { jenisTransaksiOptions } from '../../../../../data/options';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                kategoriAssetLancar: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        });
        _setAxios("sarpras/asset-lancar/transaksi/export", "POST", {
            jenis_transaksi: values.jenis_transaksi ? values.jenis_transaksi : null,
            tgl_awal: values.tgl_awal ? values.tgl_awal : null,
            tgl_akhir: values.tgl_akhir ? values.tgl_akhir : null,
            asset_id: this.props.match.params.asset_id

        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getKategoriAssetLancarDDL = () => {
        _setAxios("sarpras/kategori", "POST", {
            "jenis": "Asset Lancar"
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kategoriAssetLancar: resp.data.data
                    }
                });
            }
        })
    }

    componentDidMount() {
        this.getKategoriAssetLancarDDL()
    }

    render() {

        return (
            <Modal
                title="Export"
                // width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Jenis Transaksi">
                        <Select
                            allowClear
                            name="jenis_transaksi"
                            placeholder="Jenis Transaksi"
                            onChange={(e, val) => {
                                this.handleChangeSetValue("jenis_transaksi", val.value)
                            }}
                            style={{ width: '100%' }}
                        >
                            {jenisTransaksiOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Periode">
                        <RangePicker
                            style={{
                                width: "100%", borderRadius: 4, padding: "6px 10px"
                            }}
                            format={dateFormat}
                            onChange={val => {

                                if (val) {
                                    let periodeAwal = moment(val[0]).format(dateFormat)
                                    let periodeAkhir = moment(val[1]).format(dateFormat)

                                    this.handleChangeSetValue("tgl_awal", periodeAwal)
                                    this.handleChangeSetValue("tgl_akhir", periodeAkhir)
                                }else{
                                    this.handleChangeSetValue("tgl_awal", null)
                                    this.handleChangeSetValue("tgl_akhir", null)
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default Export;
