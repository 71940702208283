import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, Input } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../../lib/Helper';
import { UploadFile } from '../../../../../components/upload-files';
import { SelectBerkasKategoriSupervisi, SelectBerkasJenisSupervisi, SelectTahunAjaran } from '../../../../../components/select';

const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
const schema = {
    gtk_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    supervisi_berkas_kategori_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    supervisi_berkas_jenis_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tahun_ajaran: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    link_video: {
        format: {
          pattern: urlRegex,
          message: "URL link video tidak valid"
        }
      }
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            values: {
                ...data,
                file_berkas: null,
                file_foto: null
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        if (values.id) {
            schema.file_berkas = null
            schema.file_foto = null
        } else {
            schema.file_berkas = {
                presence: { allowEmpty: false },
            }
            schema.file_foto = {
                presence: { allowEmpty: false },
            }
        }
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            gtk_id: values.gtk_id ? values.gtk_id : null,
            supervisi_berkas_kategori_id: values.supervisi_berkas_kategori_id ? values.supervisi_berkas_kategori_id : null,
            supervisi_berkas_jenis_id: values.supervisi_berkas_jenis_id ? values.supervisi_berkas_jenis_id : null,
            tahun_ajaran: values.tahun_ajaran ? values.tahun_ajaran : null,
            link_video: values.link_video ? values.link_video : null,
            file_berkas: values.file_berkas ? values.file_berkas : null,
            file_foto: values.file_foto ? values.file_foto : null,
        }

        let endpoint = "supervisi/penilaian-kinerja-guru/berkas"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false,
                    values: {}
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state
        const { data } = this.props
        return (
            <Modal
                maskClosable={false}
                title={"Form Berkas"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
                width={800}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Kategori *">
                                <SelectBerkasKategoriSupervisi
                                    defaultValue={values.supervisi_berkas_kategori_id}
                                    value={values.supervisi_berkas_kategori_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("supervisi_berkas_kategori_id", val ? val : null)
                                        this.handleChangeSetValue("supervisi_berkas_jenis_id", null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item label="Jenis Berkas *">
                                <SelectBerkasJenisSupervisi
                                    disabled={!values.supervisi_berkas_kategori_id ? true : false}
                                    kategoriId={values.supervisi_berkas_kategori_id}
                                    defaultValue={values.supervisi_berkas_jenis_id}
                                    value={values.supervisi_berkas_jenis_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("supervisi_berkas_jenis_id", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item label="Tahun Ajaran *">
                                <SelectTahunAjaran
                                    defaultValue={values.tahun_ajaran}
                                    value={values.tahun_ajaran}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tahun_ajaran", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Link Video *">
                                <Input
                                    name="link_video"
                                    placeholder="link video"
                                    defaultValue={data.link_video}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("link_video", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label={values.id ? "File Berkas" : "File Berkas *"}>
                                <UploadFile
                                    isValidation={true}
                                    type="pdf/docx"
                                    onChange={(val) => {
                                        this.handleChangeSetValue("file_berkas", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label={values.id ? "File Bukti Foto" : "File Bukti Foto *"}>
                                <UploadFile
                                    isValidation={true}
                                    type="image"
                                    onChange={(val) => {
                                        this.handleChangeSetValue("file_foto", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal} disabled={this.state.loading}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
