import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Input, Divider, Form } from 'antd';
import { _success, _setAxios, _validationFormMsg, getValueDate } from '../../../../lib/Helper';

import { Date } from '../../../../components/datetime';
import { UploadFile } from '../../../../components/upload-files';
const { TextArea } = Input;

const schema = {
    judul: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_pengesahan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    deskripsi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        const { data } = this.props
        this.setState({
            values: {
                ...data,
                tgl_pengesahan: getValueDate(data.tgl_pengesahan).toString(),
                file: null,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { data } = this.props
        const { values } = this.state
        if(!data.id){
            schema.file = {
                presence: { allowEmpty: false, message: 'wajib diisi' },
            }
        }else{
            delete schema.file
        }
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        
        const params = {
            tk_id: values.tk_id ? values.tk_id : null,
            judul: values.judul ? values.judul : null,
            deskripsi: values.deskripsi ? values.deskripsi : null,
            file: values.file ? values.file : null,
            tgl_pengesahan: getValueDate(values.tgl_pengesahan).toString(),
        }

        console.log("params", params)
        
        let endpoint = "tk/karya-tulis"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { data } = this.props

        return (
            <Modal
                maskClosable={false}
                title={"Form Karya Tulis"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal Pengesahan *">
                                <Date
                                    defaultValue={data.tgl_pengesahan}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_pengesahan", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Judul Karya Tulis *">
                                <Input
                                    name="judul"
                                    placeholder="Judul karya tulis"
                                    defaultValue={data.judul}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("judul", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Deskripsi *">
                                <TextArea
                                    rows={4}
                                    placeholder="deskripsi"
                                    defaultValue={data.deskripsi}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("deskripsi", e.target.value)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label={`File ${data.id ? "" : "*"}`}>
                                <UploadFile
                                    isValidation={true}
                                    maxSize={20}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("file", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal} disabled={this.state.loading}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
