import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../lib/Helper';

import { Breadcrumb, Row, Col, Divider, PageHeader } from 'antd';
import { UserOutlined, DoubleRightOutlined } from '@ant-design/icons';
import AuthRedirect from '../../components/AuthRedirect'
import "./Profile.css"

class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        };
    }

    render() {
        if (!isAuth(this.props.privilege)) {
            return <AuthRedirect />
        }
        return (
            <>
                <Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                    <Breadcrumb.Item><UserOutlined /> Profil</Breadcrumb.Item>
                </Breadcrumb>
                <Divider className='breadcrumb-line' orientation="left"></Divider>
                <PageHeader
                    className="site-page-header"
                    title="Profil"
                    subTitle="Data profil"
                />

                <Row gutter={[24, 4]}>
                    <Col xs={24} sm={12} md={8}>
                        <div className="site-layout-background">
                            Foto
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={16}>
                        <div className="site-layout-background">
                            Informasi akun
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <div className="site-layout-background">
                            Informasi akun
                        </div>
                    </Col>
                </Row>


            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Profile);
