import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Breadcrumb, Button, message } from 'antd';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../../lib/Helper';

import AuthRedirect from '../../../../../components/AuthRedirect'
import Form from '../modal/Form';
import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktopWithFilter from '../../../../../components/sorting-table/SortingTableDesktopWithFilter';
import SortingTableMobileWithFilter from '../../../../../components/sorting-table/SortingTableMobileWithFilter';
import SearchTable from '../../../../../components/search-table/SearchTable';
import { DoubleRightOutlined, PlusOutlined } from '@ant-design/icons';
import Delete from '../modal/Delete';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}]

class ListContent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
          siswa_id: this.props.match.params.siswa_id,
        },
      },
      form: {
        data: {},
        visible: false
      },
      delete: {
        data: {},
        visible: false
      },
      filter: {
        values: {
          status: null,
        },
        visible: false,
      },
      detail: {},
      ddl: {
        kecakapan: []
      }
    };
  }

  componentDidMount() {
    this.getSiswaPramukaByID(this.props.match.params.siswa_id)
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("kaldik-kegiatan/pramuka/penilaian/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  getSiswaPramukaByID = (id) => {
    _setAxios("kaldik-kegiatan/pramuka/penilaian/siswa/" + id, "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          detail: resp.data.data,
        })
      }
    })
  }

  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }

  modalForm = (visible = false, data = {}) => {
    if (this.state.detail) {
      if (!this.state.detail.agama) {
        return message.warn(`Maaf, belum bisa menambahkan data Syarat Kecakapan Umum Siswa karena Agama siswa belum terisi, isi data Agama terlebih dahulu.`)
      }
    }else{
      return message.info(`Loading...`)
    }

    this.setState({
      form: {
        data: {
          ...data,
          siswa_id: this.props.match.params.siswa_id ? this.props.match.params.siswa_id : null
        },
        visible: visible
      },
    })
  }



  render() {

    const access = this.props.privilege.access["/master/sku"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Ekstrakurikuler & Pramuka</Breadcrumb.Item>
            <Breadcrumb.Item>Pramuka </Breadcrumb.Item>
            <Breadcrumb.Item>Detail </Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.create ? <Button type='primary' onClick={this.modalForm.bind(this, true)}><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

          <PageHeader
            className="site-page-header"
            title="Detail Syarat Kecakapan Umum Siswa"
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} >
              <div><b>NISN :</b> {this.state.detail ? this.state.detail.nisn : "-"}</div>
              <div><b>NIS :</b> {this.state.detail ? this.state.detail.nis : "-"}</div>
              <div><b>Siswa :</b> {this.state.detail ? this.state.detail.nama : "-"}</div>
            </Col>
            <Col xs={24} sm={12} >
              <div><b>Bantara :</b> {this.state.detail ? this.state.detail.total_bantara : "-"}</div>
              <div><b>Laksana :</b> {this.state.detail ? this.state.detail.total_laksana : "-"}</div>
            </Col>
            <Col xs={24} sm={12} >
              <br />
            </Col>

            {/* SEARCH LIST/TABLE */}
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* DATA LIST/TABLE */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDelete={this.modalDelete}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalForm={this.modalForm}
                  modalDelete={this.modalDelete}
                />
              </MobileView>
            </Col>
          </Row>
        </div>
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              dataSiswa={this.state.detail}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false)}
              getSiswaPramukaByID={this.getSiswaPramukaByID.bind(this, this.props.match.params.siswa_id)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ListContent);
