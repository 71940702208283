import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination, Tag } from 'antd';
import { SettingOutlined, DownCircleOutlined, CloseCircleOutlined, SendOutlined, CheckCircleOutlined, UploadOutlined, FileDoneOutlined, FileSyncOutlined, IssuesCloseOutlined, DownloadOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../../lib/Helper';

const statusLabels = {
  "Draft": "yellow",
  "Diajukan": "",
  "Disetujui": "blue",
  "Ditolak": "red",
  "Selesai": "green",
}

const statusIcons = {
  "Draft": <FileSyncOutlined />,
  "Diajukan": <SendOutlined style={{ transform: "rotate(-30deg)" }} />,
  "Disetujui": <CheckCircleOutlined />,
  "Ditolak": <CloseCircleOutlined />,
  "Selesai": <FileDoneOutlined />,
}

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Nama',
                  dataIndex: 'nama',
                  sorter: false,
                }, {
                  title: 'Kelas/Rombel',
                  dataIndex: 'rombel_nama',
                  sorter: false,
                }, {
                  title: 'Tanggal',
                  dataIndex: '',
                  sorter: false,
                  render: (a, row) => <>
                    <span style={{
                      fontSize: "100%",
                      fontStyle: "italic"
                    }}>{row.tgl_awal}</span> <br />
                    to <br />
                    <span style={{
                      fontSize: "100%",
                      fontStyle: "italic"
                    }}>{row.tgl_akhir}</span> <br />
                  </>,
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  sorter: false,
                }, {
                  title: 'File',
                  dataIndex: 'file',
                  render: (a, row) => row.file ? <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
                }, {
                  title: 'Status',
                  dataIndex: 'status',
                  sorter: false,
                  render: (a, row) => <Tag style={{ width: "100%" }} color={statusLabels[row.status]} icon={statusIcons[row.status]}>{row.status}</Tag>
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (this.props.privilege.profile.roles === "Guru" && row.status === "Diajukan" ? "-" : row.status === "Selesai" || row.status === "Ditolak" ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {/* {action.update && row.status === "Draft" ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""} */}
                    {action.request_approval && !action.approval && row.status === "Draft" ? (<Menu.Item key={1} onClick={this.props.modalRequestApproval.bind(this, true, row)}><SendOutlined style={{ transform: "rotate(-30deg)" }} /> Minta Persetujuan</Menu.Item>) : ""}
                    {action.approval && row.status === "Diajukan" ? (<Menu.Item key={2} onClick={this.props.modalApprove.bind(this, true, row)}><CheckCircleOutlined /> Setujui</Menu.Item>) : ""}
                    {action.approval && row.status === "Diajukan" ? (<Menu.Item key={3} onClick={this.props.modalReject.bind(this, true, row)}><CloseCircleOutlined /> Tolak</Menu.Item>) : ""}
                    {action.approval && row.status === "Disetujui" ? (<Menu.Item key={4} onClick={this.props.modalCancel.bind(this, true, row)}><IssuesCloseOutlined /> Batalkan</Menu.Item>) : ""}
                    {action.request_approval && !action.approval && row.status === "Disetujui" ? (<Menu.Item key={5} onClick={this.props.modalUploadDocumentation.bind(this, true, row)}><UploadOutlined /> Upload Dokumentasi (Selesaikan)</Menu.Item>) : ""}
                    {action.delete && row.status === "Draft" ? (<Menu.Item key={6} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
