import React from 'react';
import { Row, Col, Modal, Button, Select, Form } from 'antd';

import { _setAxios } from '../../../lib/Helper';
import { genderOptions, jenjangSekolahAsalOptions } from '../../../data/options';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                tahunAjaran: [],
                rombel: [],
            },
        };
    }

    componentDidMount() {
        this.getTahunAjaranDDL()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    getRombelByTahunAjaranIDDDL = (id) => {
        _setAxios("rombel/rombel-by-semester/" + id, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombel: resp.data.data
                    }
                })
            }
        })
    }

    render() {

        const { ddl } = this.state

        return (
            <Modal
                title="Filter Peserta Didik"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Ajaran">
                        <Select
                            showSearch
                            allowClear
                            name="tahun_ajaran_id"
                            placeholder="Tahun Ajaran"
                            onChange={(e, newValue) => {
                                let tahunAjaranID = newValue ? newValue.value : null
                                this.props.setTempTableFilter("tahun_ajaran_id", tahunAjaranID)
                                this.getRombelByTahunAjaranIDDDL(tahunAjaranID)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.tahunAjaran.map((row, i) => <Option key={i} value={row.id}>{row.thn_awal} / {row.thn_akhir} - {row.tipe_semester}</Option>)}
                        </Select>

                    </Form.Item>

                    <Form.Item label="Rombongan Belajar">
                        <Select
                            showSearch
                            allowClear
                            name="rombel_id"
                            placeholder="Rombongan Belajar"
                            onChange={(e, newValue) => {
                                this.props.setTempTableFilter("rombel_id", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.rombel.map((row, i) => <Option key={i} value={row.id}>{row.nama}</Option>)}
                        </Select>
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Jenis Kelamin">
                                <Select
                                    showSearch
                                    allowClear
                                    name="jenis_kelamin"
                                    placeholder="Jenis Kelamin"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenis_kelamin", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {genderOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Jenjang Sekolah Asal">
                                <Select
                                    showSearch
                                    allowClear
                                    name="jenjang_sekolah_asal"
                                    placeholder="Jenjang Sekolah Asal"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenjang_sekolah_asal", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenjangSekolahAsalOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default Filter;
