import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';
const fetch = (keyword, callback) => {
  _setAxios("tk/dropdown/all?search=" + (keyword ? keyword : ""), "GET")
    .then(body => {
      let results = []
      body.data.data.forEach(row => {

        results.push({
          value: row.id,
          label: (row.gelar_depan ? row.gelar_depan + ". " : "") + row.nama_lengkap + (row.gelar_belakang ? ". " + row.gelar_belakang : ""),
        })
      });

      callback(results);
    })
};
const SearchInput = (props) => {
  return (
    <Select
      {...props}
      showSearch
      allowClear
      placeholder={
        props.placeholder ? props.placeholder : 'Pilih tenaga pendidik'
      }
      style={props.style ? props.style : { width: '100%' }}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
    />
  );
};
const SelectTenagaPendidik = ({ ...props }) => {
  const [data, setData] = useState([]);
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };

  useEffect(() => {
    fetch(null, setData);
  }, []);

  return <SearchInput
    {...props}
    onSearch={handleSearch}
    options={data}
  />
}

export default SelectTenagaPendidik;