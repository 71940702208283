import React from 'react';
import { Modal, Button, Form, DatePicker } from 'antd';
import { SelectPosPemasukan } from '../../../../../components/select';
import moment from 'moment';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        };
    }

    render() {

        const { values } = this.props

        return (
            <Modal
                title="Filter"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >

                    <Form.Item label="Pos Pemasukan *">
                        <SelectPosPemasukan
                            value={values?.pos_pemasukan_id ? parseInt(values.pos_pemasukan_id) : null}
                            onChange={(val, row) => {
                                this.props.setTempTableFilter("pos_pemasukan_id", val ? val : null)
                            }}
                        />
                    </Form.Item>
                    <Form.Item label="Durasi">
                        <RangePicker
                            style={{
                                width: "100%", borderRadius: 4, padding: "6px 10px"
                            }}
                            format={dateFormat}
                            value={values?.tgl_awal && values?.tgl_akhir ? [moment(values.tgl_awal, dateFormat), moment(values.tgl_akhir, dateFormat)] : null}
                            onChange={val => {
                                if (!val || val.length === 0) {
                                    this.props.setTempTableFilter("tgl_awal", null)
                                    this.props.setTempTableFilter("tgl_akhir", null)
                                    return
                                }

                                let periodeAwal = moment(val[0]).format(dateFormat)
                                let periodeAkhir = moment(val[1]).format(dateFormat)

                                this.props.setTempTableFilter("tgl_awal", periodeAwal)
                                this.props.setTempTableFilter("tgl_akhir", periodeAkhir)
                            }}
                        />
                    </Form.Item>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
