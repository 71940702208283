import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../../lib/Helper';
import LabelPredikat from '../../../../../components/labels/LabelIsActive';

class Desktop extends React.Component {
 
  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Tanggal Hafalan',
                  dataIndex: 'tgl_hafalan',
                }, {
                  title: 'Tingkat',
                  align: "center",
                  dataIndex: 'tingkatan_kelas',
                }, {
                  title: 'Nama Surat',
                  dataIndex: 'ubudiyah_aspek_nama',
                }, {
                  title: 'Kategori',
                  dataIndex: 'ubudiyah_kategori_nama',
                }, {
                  title: 'Predikat',
                  align: "center",
                  render: (a, row) => (<LabelPredikat predikat={row.predikat_penilaian} />),
                }, {
                  title: 'Penilai',
                  dataIndex: 'penilai_nama',
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.update && !action.delete ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.update ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                    {action.delete ? (<Menu.Item key={1} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
