import React from 'react';
import { Modal, Button } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';

class DownloadFile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        };
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("supervisi/instrumen/get-file", "POST", {
            tahun_ajaran: this.props.data.tahun_ajaran,
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    let url = resp.data.data.main_path + resp.data.data.file;
                    window.open(url, '_blank').focus();
                }, 500);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {
        return (
            <Modal
                title="Download instrument evaluasi PKG"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Download</Button>
                </>}
            >
                <div style={{ textAlign: "left" }}>
                    Apakah Anda yakin? silahkan klik "Download" untuk melanjutkan. </div>

            </Modal>
        )
    }
}

export default DownloadFile;
