import React from 'react';
import { Table, Row, Col, Button, Menu, Dropdown, List, Tag, Pagination } from 'antd';
import { InfoCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, EditOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { getParamTable, _tableLogActivity } from '../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const statusSiswa = this.props.statusSiswa
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                },
                {
                  title: 'Peserta Didik',
                  dataIndex: 'ttl',
                  width: '20%',
                  render: (a, row) => <List.Item style={{ padding: 0 }}>
                    <List.Item.Meta
                      title={row.nama}
                      description={<small>
                        NISN: {row.nisn ? row.nisn : "-"}
                        {/* NIS: {row.nis ? row.nis : "-"} */}
                      </small>}
                    />
                  </List.Item>,
                },
                {
                  title: 'Jenis Kelamin',
                  dataIndex: 'jenis_kelamin',
                  sorter: false,
                },
                {
                  title: 'Tempat/Tgl Lahir',
                  dataIndex: 'ttl',
                  sorter: false,
                  render: (a, row) => <List.Item style={{ padding: 0 }}>
                    <List.Item.Meta
                      title={row.tempat_lahir}
                      description={<small>{row.tgl_lahir}</small>}
                    />
                  </List.Item>,
                },
                {
                  title: 'Rombel',
                  dataIndex: 'rombel_nama',
                  sorter: false,
                },
                {
                  title: 'Status',
                  dataIndex: 'ttl',
                  sorter: false,
                  render: (a, row) => <>
                    {row.status_siswa === "Aktif" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>Tidak Aktif</Tag>}
                  </>,
                },
                {
                  title: 'Status Penangguhan',
                  dataIndex: 'akun_ditangguhkan',
                  sorter: false,
                  render: (a, row) => <>
                    {row.akun_ditangguhkan === "0" ? "-" : <Tag color="red">Ditangguhkan</Tag>}
                  </>,
                },
                {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail === true ? (<Menu.Item key={0}><Link to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                    {action.update === true ? (<Menu.Item key={1} onClick={this.props.modalPenagguhan.bind(this, true, row)}><EditOutlined /> Ubah Status Penangguhan</Menu.Item>) : ""}
                    {action.delete === true && statusSiswa === "aktif" ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Non Aktifkan</Menu.Item>) : ""}
                    {action.restore === true && statusSiswa === "non-aktif" ? (<Menu.Item key={3} onClick={this.props.modalRestore.bind(this, row)}><CheckCircleOutlined /> Aktifkan</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>,
                },
                {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
