import React from 'react';
import { Modal, Button, Form, Select } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';

const { Option } = Select;

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
            ddl: {
                rombels: []
            }
        };
    }

    componentDidMount() {
        this.getRombelDDL()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("kaldik-kegiatan/pramuka/master/ekspor", "POST", {
            rombel_id: this.state.values.rombel_id ? this.state.values.rombel_id : null,
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    getRombelDDL = () => {
        _setAxios("rombel/dropdown", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 100,
            },
            "search": "",
            "sorting": []
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        rombels: resp.data.list
                    },
                })
            }
        })
    }

    render() {
        const { ddl } = this.state

        return (
            <Modal
                title="Export data pramuka"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Rombel">
                        <Select
                            allowClear
                            name="rombel_id"
                            placeholder="Rombel"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("rombel_id", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.rombels.map((row, i) => <Option key={i} value={row.id}>{row.nama} ({row.tingkatan_kelas})</Option>)}
                        </Select>
                    </Form.Item>
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data pramuka akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
