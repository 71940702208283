import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { isAuth, _setAxios } from '../../../lib/Helper';

import { Breadcrumb, Table, Row, Col, Divider, Button, PageHeader, Tooltip, Skeleton, List, Empty, Badge } from 'antd';
import { EditOutlined, DoubleRightOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'

class LahanDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,

            luas_lahan: null,
            luas_digunakan: null,
            luas_sblm_digunakan: null,
            status: "-",
            luas_lahan_kepemilikan: null,
            luas_lahan_penggunaan: null,

            data1: [],
            data2: [],
            dataKepemilikan: [],
            dataPenggunaan: [],
            coordinate: {
                lat: 0,
                long: 0,
            },
            distance: []
        };
    }

    componentDidMount() {
        this.getLahanByID()
    }

    getLahanByID = () => {
        this.setState({ loading: true });
        _setAxios("lahan/" + this.props.match.params.id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data.master
                const dataKepemilikan = []
                const dataPenggunaan = []

                resp.data.data.detail.forEach(row => {
                    if (row.tipe === "Lahan") {
                        dataKepemilikan.push(row)
                    } else if (row.tipe === "Penggunaan") {
                        dataPenggunaan.push(row)
                    }
                });

                this.setState({
                    loading: false,
                    luas_lahan: data.luas_lahan ? data.luas_lahan : null,
                    luas_digunakan: data.luas_digunakan ? data.luas_digunakan : null,
                    luas_sblm_digunakan: data.luas_sblm_digunakan ? data.luas_sblm_digunakan : null,
                    status: data.status ? data.status : null,
                    luas_lahan_kepemilikan: data.luas_lahan_kepemilikan ? data.luas_lahan_kepemilikan : null,
                    luas_lahan_penggunaan: data.luas_lahan_penggunaan ? data.luas_lahan_penggunaan : null,

                    data1: [
                        {
                            title: 'Nama Lahan',
                            description: data.nama ? data.nama : null
                        },
                        {
                            title: 'Alamat',
                            description: (data.provinsi_nama ? (" " + data.provinsi_nama) : "") + (data.kabkot_nama ? ", " + data.kabkot_nama : "") + (data.kecamatan_nama ? ", " + data.kecamatan_nama : "") + (data.kelurahan_nama ? ", " + data.kelurahan_nama : "") + (data.alamat ? data.alamat : "") + (data.kode_pos ? " (" + data.kode_pos + ")" : ""),
                        },
                        {
                            title: 'Titik Koordinat',
                            description: data.lat && data.long ? (data.lat ? data.lat : 0) + ", " + (data.long ? data.long : 0) : "-",
                        }
                    ],
                    data2: [
                        {
                            title: 'Kategori Geografis',
                            description: data.kategori_geografis ? data.kategori_geografis : null,
                        },
                        {
                            title: 'Wilayah',
                            description: data.wilayah ? data.wilayah : null,
                        },
                        {
                            title: 'Potensi WIlayah',
                            description: data.potensi_wilayah ? data.potensi_wilayah : null,
                        }
                    ],
                    dataKepemilikan: dataKepemilikan,
                    dataPenggunaan: dataPenggunaan,
                    coordinate: {
                        lat: data.lat ? data.lat : 0,
                        long: data.long ? data.long : 0,
                    },
                    distance: [
                        { label: "Jarak ke Ibukota", value: data.jarak_ke_ibukota ? data.jarak_ke_ibukota : "-" },
                        { label: "Jarak ke Kota", value: data.jarak_ke_kota ? data.jarak_ke_kota : "-" },
                        { label: "Jarak ke Kecamatan", value: data.jarak_ke_kecamatan ? data.jarak_ke_kecamatan : "-" },
                        { label: "Jarak ke Kanwil Propinsi", value: data.jarak_ke_kanwil_propinsi ? data.jarak_ke_kanwil_propinsi : "-" },
                        { label: "Jarak ke Kanwil Kota", value: data.jarak_ke_kanwil_kota ? data.jarak_ke_kanwil_kota : "-" },
                        { label: "Jarak ke RA", value: data.jarak_ke_ra ? data.jarak_ke_ra : "-" },
                        { label: "Jarak ke RD", value: data.jarak_ke_rd ? data.jarak_ke_rd : "-" },
                        { label: "Jarak ke MI", value: data.jarak_ke_mi ? data.jarak_ke_mi : "-" },
                        { label: "Jarak ke MTS", value: data.jarak_ke_mts ? data.jarak_ke_mts : "-" },
                        { label: "Jarak ke MA", value: data.jarak_ke_ma ? data.jarak_ke_ma : "-" },
                        { label: "Jarak ke SD", value: data.jarak_ke_sd ? data.jarak_ke_sd : "-" },
                        { label: "Jarak ke SMP", value: data.jarak_ke_smp ? data.jarak_ke_smp : "-" },
                        { label: "Jarak ke SMA", value: data.jarak_ke_sma ? data.jarak_ke_sma : "-" },
                        { label: "Jarak ke Pontren", value: data.jarak_ke_pontren ? data.jarak_ke_pontren : "-" },
                        { label: "Jarak ke PTKI", value: data.jarak_ke_ptki ? data.jarak_ke_ptki : "-" },
                    ]
                });
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    render() {
        const access = this.props.privilege.access["/sarana-prasarana/lahan"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || !action.detail) {
            return <AuthRedirect />
        }
        const { loading, distance } = this.state;

        const columnsKepemilikan = [
            {
                title: 'Status Kepemilikan',
                dataIndex: 'nama',
                width: '30%',
            },
            {
                title: 'Bersertifikat (m2)',
                dataIndex: 'bersertifikat',
            },
            {
                title: 'Tidak Bersertifikat (m2)',
                dataIndex: 'tidak_bersertifikat',
            },
            {
                title: 'Total (m2)',
                dataIndex: 'total',
            },
        ];

        const columnsPenggunaan = [
            {
                title: 'Status Kepemilikan',
                dataIndex: 'nama',
                width: '30%',
            },
            {
                title: 'Bersertifikat (m2)',
                dataIndex: 'bersertifikat',
            },
            {
                title: 'Tidak Bersertifikat (m2)',
                dataIndex: 'tidak_bersertifikat',
            },
            {
                title: 'Total (m2)',
                dataIndex: 'total',
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/sarana-prasarana/lahan`}>Lahan</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                />
                <Divider className='breadcrumb-line' orientation="left"></Divider>
                <PageHeader
                    className="site-page-header"
                    title="Lahan"
                    subTitle="detail"
                    extra={
                        !loading && action.update ?
                            <Col xs={24} style={{ textAlign: "right" }}>
                                <Tooltip placement="left" title={"Ubah data lahan"}>
                                    <Link to={"/sarana-prasarana/lahan/update/" + this.props.match.params.id}>
                                        <Button shape="circle" icon={<EditOutlined />}></Button>
                                    </Link>
                                </Tooltip>
                            </Col>
                            : ""
                    }
                />


                <Badge.Ribbon text={this.state.status}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <Divider orientation="left">Data Lahan</Divider>
                                    </Col>
                                    <Col xs={24}>
                                        {
                                            !loading ?
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={this.state.data1}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                title={item.title}
                                                                description={item.description}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                                :
                                                <Skeleton />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <Divider orientation="left">Letak lahan</Divider>
                                    </Col>
                                    <Col xs={24}>
                                        {
                                            !loading ?
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={this.state.data2}
                                                    renderItem={item => (
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                title={item.title}
                                                                description={item.description}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                                :
                                                <Skeleton />
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24}>
                                <iframe title={"unik"}
                                    src={"https://maps.google.com/maps?q=" + (this.state.coordinate.lat ? this.state.coordinate.lat : -7.052652) + "," + (this.state.coordinate.long ? this.state.coordinate.long : 112.5685274) + "&hl=id&z=14&output=embed"}
                                    style={{ width: "100%", border: 0, borderRadius: 4 }}
                                    height="440" loading="lazy">
                                </iframe>
                            </Col>
                        </Row>
                    </div>
                </Badge.Ribbon>


                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Divider orientation="left">Luas Lahan</Divider>
                                </Col>
                                <Col xs={24}>
                                    <Table
                                        columns={columnsKepemilikan}
                                        dataSource={this.state.dataKepemilikan}
                                        footer={() => <Row gutter={[16, 16]} justify='end'>
                                            <Col xs={12} sm={20}>
                                                Total
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                {this.state.luas_lahan}
                                            </Col>
                                        </Row>}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Row gutter={[16, 16]}>
                                <Col key={0} xs={24}>
                                    <Divider orientation="left">Penggunaan Lahan</Divider>
                                </Col>
                                <Col key={1} xs={24}>
                                    <Table
                                        columns={columnsPenggunaan}
                                        dataSource={this.state.dataPenggunaan}
                                        footer={() => <Row gutter={[16, 16]} justify='end'>
                                            <Col xs={12} sm={20}>
                                                Total
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                {this.state.luas_digunakan}
                                            </Col>
                                        </Row>}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]} style>
                        <Col xs={24}>
                            <Divider orientation="left">Jarak ke Lokasi Tertentu</Divider>
                        </Col>
                        {!loading ?
                            (distance.length > 0 ? distance.map((row, i) => <Col span={8}><Row gutter={[16, 16]}><Col xs={12}>{row.label}</Col><Col xs={12}>{row.value}</Col></Row></Col>) : <Empty style={{ margin: "0 auto" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />)
                            : <Skeleton />}
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LahanDetail);
