import React from 'react';
import { Tag } from 'antd';

const predikatLabels = {
  "A" : "blue",
  "B" : "green",
  "C" : "orange",
  "D" : "red",
}

const LabelPredikat = ({ ...props }) => {

  if(props.predikat) {
    return (
      <Tag color={predikatLabels[props.predikat]}> {props.predikat}</Tag>
    );
  }
  return (
    "-"
  );
};
export default LabelPredikat;
