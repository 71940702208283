import validate from 'validate.js';

export const schemaBeasiswa = {
  beasiswa_nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  beasiswa_kategori: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  beasiswa_nama_instansi: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  beasiswa_jenis_instansi: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  beasiswa_tahun: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  beasiswa_jangka_waktu: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Jangka waktu harus berupa agka");
      }
    },
  },
  beasiswa_nominal: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nominal harus berupa agka");
      }
    },
  },
};

export const schemaPendidikan = {
  pendidikan_nama_lembaga: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  pendidikan_jenis_lembaga: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  pendidikan_tahun: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  pendidikan_frekuensi_belajar: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  pendidikan_lokasi_lembaga: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
};

export const schemaPrestasi = {
  prestasi_tahun: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  prestasi_nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  prestasi_nama_penyelenggara: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  prestasi_bidang_lomba: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  prestasi_lomba_tingkat: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  prestasi_peringkat: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
};

export const schemaBerkas = {
  berkas_tipe: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  berkas_nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
};

export const schemaAyah = {
  ayah_nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    // format: {
    //   pattern: /^$|[a-zA-Z\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  ayah_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ayah_nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  ayah_no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  ayah_kode_pos: {
    length: {
      maximum: 6,
      message: "terlalu panjang (maksimal 6 karakter)"
    },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Kode pos harus berupa agka");
      }
    },
  },
}

export const schemaIbu = {
  ibu_nama: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
    // format: {
    //   pattern: /^$|[a-zA-Z\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  ibu_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ibu_nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  ibu_no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  ibu_kode_pos: {
    length: {
      maximum: 6,
      message: "terlalu panjang (maksimal 6 karakter)"
    },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Kode pos harus berupa agka");
      }
    },
  },
}

export const schemaWali = {
  wali_nama: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
    // format: {
    //   pattern: /^$|[a-zA-Z\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  wali_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  wali_nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  wali_no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  wali_kode_pos: {
    length: {
      maximum: 6,
      message: "terlalu panjang (maksimal 6 karakter)"
    },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Kode pos harus berupa agka");
      }
    },
  },
  wali_email: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|\S+@\S+\.\S+/,
      message: function (value) {
        return validate.format("^Email %{email} tidak valid", {
          email: value
        });
      }
    },
  },
  wali_tgl_lahir: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },

};


export const schemaDataDiri = {
  nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    length: {
      maximum: 200
    },
    // format: {
    //   pattern: /^$|[a-zA-Z'-\s]+/,
    //   message: function () {
    //     return validate.format("^Nama peserta didik harus berupa huruf");
    //   }
    // },
  },
  nisn: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nisn harus berupa agka");
      }
    },
    length: {
      minimum: 10,
      maximum: 10,
      message: "jumlah karakter harus 10 digit"
    },
  },
  nis: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nis harus berupa agka");
      }
    },
    length: {
      minimum: 18,
      maximum: 18,
      message: "jumlah karakter harus 18 digit"
    },
  },
  no_kip: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^No.KIP harus berupa agka");
      }
    },
  },
  nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  no_kk: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^No.KK harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  no_akta_kelahiran: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^No.Akta Kelahiran harus berupa agka");
      }
    },
  },
  kepala_keluarga: {
    length: {
      maximum: 200
    },
    // format: {
    //   pattern: /^$|[a-zA-Z\s]+/,
    //   message: function () {
    //     return validate.format("^Kepala keluarga harus berupa huruf");
    //   }
    // },
  },
  jml_saudara: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Jumlah saudara harus berupa agka");
      }
    },
  },
  anak_ke: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Anak ke berapa, harus berupa agka");
      }
    },
  },
  tgl_lahir: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|\S+@\S+\.\S+/,
      message: function (value) {
        return validate.format("^Email %{email} tidak valid", {
          email: value
        });
      }
    },
  },
  kode_pos: {
    length: {
      maximum: 6,
      message: "terlalu panjang (maksimal 6 karakter)"
    },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Kode pos harus berupa agka");
      }
    },
  },
  lat: {
    format: {
      pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
      message: function () {
        return validate.format("^Latitude belum sesuai");
      }
    },
  },
  long: {
    format: {
      pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
      message: function () {
        return validate.format("^Longitude belum sesuai");
      }
    },
  },
  berat_badan: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Berat badan harus berupa agka");
      }
    },
  },
  tinggi_badan: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Tinggi badan harus berupa agka");
      }
    },
  },
  lingkar_kepala: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Lingkar badan harus berupa agka");
      }
    },
  },
};

export const schemaActivitas = {
  jenis_pendaftaran: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  tgl_masuk: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  sekolah_asal: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  // no_ijazah: {
  //   format: {
  //     pattern: /^$|[0-9]+/,
  //     message: function () {
  //       return validate.format("^No Ijazah harus berupa agka");
  //     }
  //   },
  // },
};