import React from 'react';
import { Modal, Button, Upload } from 'antd';

import { _success, _setAxios, _beforeUploadFile, _getFile } from '../../../../lib/Helper';
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

class UploadDocumentation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            validateFile: false,
            visible: false,
            fileList: [],
        };
    }

     // HANDLE FILE START --------------------------------------
     beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    handleSubmit = async () => {
        this.setState({
            loading: true
        });

        _setAxios("sarpras/ruangan/booking/upload-berkas/" + this.props.data.id, "PUT", {
            "file": await _getFile(this.state.fileList),
        }).then(resp => {
            if (resp.status === true) {

                _setAxios("sarpras/ruangan/booking/status/" + this.props.data.id, "PUT", {
                    "approval": false,
                    "status": "Selesai"
                }).then(resp => {
                    if (resp.status === true) {
                        _success('topRight', 'Success', resp.data.message)
                        this.props.fetchTable(true)
                        this.props.hideModal()
        
                        this.setState({
                            loading: false
                        })
                    } else {
                        this.setState({
                            loading: false
                        });
                    }
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={<><InfoCircleOutlined /> Upload Dokumentasi - Selesaikan</>}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Lanjutkan</Button>
                </>}
            >
                <Dragger
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    fileList={this.state.fileList}
                    onChange={this.handleChangeFile}
                    beforeUpload={this.beforeUploadFile}
                    maxCount={1}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, JPEG, PNG & PDF</p>
                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                </Dragger>
            </Modal>
        )
    }
}

export default UploadDocumentation;
