import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList() {

  return await _setAxios("supervisi/penilaian-kinerja-guru/berkas/kategori/dropdown", "GET")
    .then(body => {
      let results = []
      body.data.data.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
        })
      });

      return results
    })
}

const SelectBerkasKategoriSupervisi = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  return (
    <Select
      {...props}
      fetchOptions={fetchList}
      placeholder="Pilih berkas kategori"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectBerkasKategoriSupervisi;
