import React from 'react';
import { Table, Row, Col, Button, Menu, Dropdown, Pagination, Tag } from 'antd';
import { SettingOutlined, DownCircleOutlined, CloseCircleOutlined, CheckCircleOutlined, EditOutlined, StopOutlined, DownloadOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                },
                {
                  title: 'Judul',
                  dataIndex: 'judul',
                  sorter: false,
                  render: (a, row) => <>
                    {row.judul ? row.judul : "-"}<br /><span style={{ color: "#aaa" }}>{row.deskripsi ? row.deskripsi : "-"}</span>
                  </>,
                }, {
                  title: 'Foto',
                  dataIndex: 'file',
                  sorter: false,
                  width: '7%',
                  render: (a, row) => <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>,

                }, {
                  title: 'Status Publish',
                  dataIndex: 'is_tampilkan',
                  sorter: false,
                  width: '10%',
                  render: (a, row) => <>
                    {row.is_tampilkan === "1" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>Tidak Aktif</Tag>}
                  </>,
                },
                {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                    {action.update === true ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                    {action.update === true ? (<Menu.Item key={1} onClick={this.props.modalPublish.bind(this, true, row)}>{row.is_tampilkan === "1" ? <><StopOutlined /> Non Aktfikan</> : <><CheckCircleOutlined /> Aktfikan</>}</Menu.Item>) : ""}
                    {action.delete === true ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>,
                },
                {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
