import React from 'react';
import { Row, Col, Image, Button, Skeleton, Typography } from 'antd';
import { connect } from 'react-redux'
import { CheckCircleOutlined, ClockCircleFilled, ScheduleOutlined } from '@ant-design/icons';
import { noImage } from '../../data/image';
import AbsensiCheckLog from './AbsensiCheckLog';

const { Text, Title } = Typography;

class Absensi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      absensi: {
        data: {},
        visible: false
      },
    };
  }

  modalAbsensi = (visible = false, data = {}) => {
    this.setState({
      absensi: {
        data: data,
        visible: visible
      },
    })
  }

  render() {
    const { loading, data } = this.props
    const accessKehadiran = data.access["/presensi/kehadiran"]
    const actionKehadiran = accessKehadiran === undefined ? null : accessKehadiran.action

    if (loading) {
      return <Skeleton.Input active={true} className="skeleton-statistik" />
    } else {
      return (
        <div className="dashboard-column" style={{
          minHeight: 210,
          borderColor: "#F7BE2A",
          // opacity: .3
        }}>
          <Row gutter={[16, 16]}>
            <Col xs={24}> <Title level={5} style={{marginBottom: 0}}>Presensi hari ini <CheckCircleOutlined /></Title></Col>
            <Col xs={24} sm={8}>
              <div className="dashboard-column" style={{
                borderColor: "#52C41A",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 5
              }}>
                <Row gutter={[16, 16]}>
                <Col xs={10}><Image src={data.absensi.data && data.absensi.data.foto_masuk ? data.absensi.data.main_path + data.absensi.data.foto_masuk : noImage} className="image-circle" /></Col>
                  <Col xs={14}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold"
                      }}
                    >Masuk</div>
                    <div style={{
                      color: "#52C41A",
                      fontSize: 20
                    }}>{data.absensi.data && data.absensi.data.waktu_masuk ? data.absensi.data.waktu_masuk.substring(0, 5) : "-"}</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="dashboard-column" style={{
                borderColor: "#52C41A",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: 5
              }}>
                <Row gutter={[16, 16]}>
                  <Col xs={10}><Image src={data.absensi.data && data.absensi.data.foto_pulang ? data.absensi.data.main_path + data.absensi.data.foto_pulang : noImage} className="image-circle" /></Col>
                  <Col xs={14}>
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "bold"
                      }}
                    >Pulang</div>
                    <div style={{
                      color: "#52C41A",
                      fontSize: 20
                    }}>{data.absensi.data && data.absensi.data.waktu_pulang ? data.absensi.data.waktu_pulang.substring(0, 5) : "-"}</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div style={{
                width: "100%"
              }}>
                <div
                  style={{
                    padding: "16px 0px"
                  }}
                >
                  <Text strong>Check Log</Text><br />
                  <ClockCircleFilled style={{ color: "#F7BE2A" }} /> {data.absensi.setting.jam_masuk} - {data.absensi.setting.jam_pulang} </div>
                <div><Button
                  block
                  disabled={actionKehadiran && actionKehadiran.check_log && (!data.absensi.data || !data.absensi.data.tgl_pulang) ? false : true}
                  style={{
                    background: "#10B582",
                    color: "#fff",
                    border: "1px solid #10B582",
                    boxShadow: "0px 2px 0px rgba(0, 0, 0, 0.043)",
                    borderRadius: 5,
                    width: "100%"
                  }}
                  onClick={
                    this.modalAbsensi.bind(this, true)
                  }
                ><ScheduleOutlined /> Rekam Kehadiran</Button></div>
              </div>
            </Col>
          </Row>

          {
            this.state.absensi.visible ?
              <AbsensiCheckLog
                data={this.state.absensi.data}
                visible={this.state.absensi.visible}
                hideModal={this.modalAbsensi.bind(this, false)}
              /> : ""
          }
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Absensi)