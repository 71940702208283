import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Pagination, Tag, Divider, Tooltip, Dropdown, Menu, Button, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined, DownCircleOutlined, SettingOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';
import PreloadContent from '../../../../components/preload/PreloadContent';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedRowKeys: [],
      loading: false
    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  onSelectChange = (newSelectedRowKeys) => {
    console.log("newSelectedRowKeys", newSelectedRowKeys);
    this.setState({
      selectedRowKeys: newSelectedRowKeys
    })
  };

  render() {
    const { table, loading } = this.props.state
    const { selectedRowKeys } = this.state
    const action = this.props.access.action

    if (loading) {
      return <PreloadContent />
    }

    return (
      <>{selectedRowKeys && selectedRowKeys.length > 0 ? <Space wrap>
          Selected {selectedRowKeys.length} Items
          <Button type='danger' onClick={this.props.modalDeleteMulti.bind(this, true, selectedRowKeys)}>Delete</Button>
        </Space> : ""}
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              rowSelection={{
                selectedRowKeys,
                onChange: this.onSelectChange,
                preserveSelectedRowKeys: true,
              }}
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Payload & Response',
                  dataIndex: 'payload',
                  sorter: false,
                  render: (a, row) =>
                    <>
                      Payload
                      <div><b>Phone:</b> {JSON.parse(row.payload) ? JSON.parse(row.payload).phone_no : "-"}</div>
                      <div>
                        <Tooltip title={JSON.parse(row.payload) ? JSON.parse(row.payload).message : "-"} width={300}>
                          <b>Message:</b> {JSON.parse(row.payload) ? JSON.parse(row.payload).message.substring(1, 150) + "..." : "-"}
                        </Tooltip>
                      </div>
                      <Divider />
                      Response
                      <div><b>Status:</b> {row.response ? (JSON.parse(row.response).status ? JSON.parse(row.response).status : "-") : "-"}</div>
                      <div><b>Message:</b> {row.response ? (JSON.parse(row.response).message ? JSON.parse(row.response).message : "-") : "-"}</div>
                    </>,
                }, {
                  title: 'Status',
                  dataIndex: 'is_open',
                  sorter: false,
                  render: (a, row) => <>
                    {row.status === "Done" ? <Tag color="blue" icon={<CheckCircleOutlined />}>{row.status}</Tag> : (row.status === "Pending" ? <Tag icon={<ClockCircleOutlined />}>{row.status}</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>{row.status}</Tag>)}
                  </>,
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.delete ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.delete ? (<Menu.Item key={0} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.rownum+"."+record.notif_wa_id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Selected ${this.state.selectedRowKeys.length} Items |  ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
