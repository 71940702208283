import React from 'react';
import { Table, Row, Col, Pagination, Tag } from 'antd';

import { getParamTable, _tableLogActivity, _fmtRupiah } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Tanggal Bayar',
                  dataIndex: 'tgl_transaksi',
                }, {
                  title: 'Siswa',
                  render: (i, row) => <>
                    {row.siswa_nama}
                    <br />
                    <Tag color='green'>{row.siswa_nisn}</Tag>
                  </>
                }, {
                  title: 'Tahun Ajaran',
                  dataIndex: 'tahun_ajaran',
                }, {
                  title: 'Nama Pos',
                  dataIndex: 'pos_pemasukan_nama',
                }, {
                  title: 'Nominal Tagihan',
                  render: (i, row) => "Rp. " + (row.nominal_tagihan ? _fmtRupiah(row.nominal_tagihan) : "0")
                }, {
                  title: 'Nominal Pembayaran',
                  render: (i, row) => "Rp. " + (row.nominal_pembayaran ? _fmtRupiah(row.nominal_pembayaran) : "0")
                }, {
                  title: "Jenis Pembayaran",
                  dataIndex: 'jenis_pembayaran',
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  render: (i, row) => row.keterangan ? row.keterangan : "-"
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
