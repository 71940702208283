export const excludeWidgetPath = [
    "/",
    "#lembaga",
    "#configuration",
    "#log-export-import",
    "#notification",
    "#master",
    "#keuangan",
    "#konten",
    "/buku-tamu",
    "#ekstrakulikuler-pramuka",
    "#kurikulum",
    "#sarana-prasarana"
  ]