import React from 'react';
import { Modal, Button, Form, Row, Col, Select } from 'antd';
import { _setAxios } from '../../../../lib/Helper';

const { Option } = Select;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                tahunAjaran: []
            }
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran/tahun", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }

    componentDidMount() {
        this.getTahunAjaranDDL()
    }

    render() {
        const { ddl } = this.state

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Tahun Ajaran">
                                <Select
                                    name="tahun_ajaran"
                                    placeholder="Tahun ajaran"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("tahun_ajaran", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {ddl.tahunAjaran.map((row, i) => <Option key={i} value={`${row.thn_awal}-${row.thn_akhir}`}>{row.thn_awal}-{row.thn_akhir}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
