import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { DownCircleOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={(row.nama ? row.nama : "-")}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Penanggung Jawab: {(row.gelar_depan ? row.gelar_depan + " " : "") + (row.nama_lengkap ? row.nama_lengkap : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")}<br />
                              NIP: {row.nip}<br />
                              Tanggal Awal: {row.tgl_awal ? row.tgl_awal : "-"} <br />
                              Tanggal Akhir: {row.tgl_akhir ? row.tgl_akhir : "-"} <br />
                              Uraian: {row.uraian.slice(0, 255) + (row.uraian.length > 255 ? "..." : "")} <br />
                              Proposal: {row.proposal ? <a target="_blank" rel="noopener noreferrer" download={true} href={row.main_path + row.proposal} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-"}<br />
                              LPJ: {row.lpj ? <a target="_blank" rel="noopener noreferrer" download={true} href={row.main_path + row.lpj} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(!action.detail ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0} onClick={this.props.modalDetail.bind(this, true, row)}><InfoCircleOutlined /> Detail</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
