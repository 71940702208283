import React from 'react';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import Config from "./Config";
import { _getToken, _setAxios } from './lib/Helper';
import axios from 'axios';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import './App.css';
import 'antd/dist/antd.css';

//Global
import HeaderLayout from './layouts/header/Header';
import Sidebar from './layouts/sidebar/Sidebar';
import Preload from './components/preload/Preload'
import Home from './pages/home/Home'
import Login from './pages/login/Login';

// Error Page
import Error403 from './pages/errors/403';
import Error404 from './pages/errors/404';

//Configuration
import ConfMenu from './pages/configuration/ConfMenu';
import ConfUser from './pages/configuration/ConfUser';
import ConfRole from './pages/configuration/ConfRole';
import ConfPrivilege from './pages/configuration/ConfPrivilege';
import Profile from './pages/profile/Profile';
import ConfAbsensi from './pages/configuration/absensi/list/index';
import ConfDropdownList from './pages/configuration/dropdown/list/index';

//Master
import JenisRuangan from './pages/master/JenisRuangan';
import Jurusan from './pages/master/Jurusan';
import JenisJalurPPDB from './pages/master/jenis-jalur-ppdb/list/index';
import MasterSKU from './pages/master/master-sku/list/index';
import MasterPOS from './pages/master/master-pos/index';
import BerkasPersyaratan from './pages/master/berkas-persyaratan/list/index';

//Kurikulum
import MataPelajaran from './pages/kurikulum/MataPelajaran';
import Rombel from './pages/kurikulum/rombel/Rombel';
import RombelCreate from './pages/kurikulum/rombel/RombelCreate';
import RombelDetail from './pages/kurikulum/rombel/RombelDetail';
import Jadwal from './pages/kurikulum/jadwal/Jadwal';
import JadwalPelajaranDetail from './pages/kurikulum/jadwal/JadwalPelajaranDetail';
import JadwalMengajarDetail from './pages/kurikulum/jadwal/JadwalMengajarDetail';
import TahunAjaran from './pages/kurikulum/tahun-ajaran/TahunAjaran';

//Sarana Prasarana
import Lahan from './pages/sarana-prasarana/lahan/Lahan';
import LahanCreate from './pages/sarana-prasarana/lahan/LahanCreate';
import LahanDetail from './pages/sarana-prasarana/lahan/LahanDetail';
import LahanUpdate from './pages/sarana-prasarana/lahan/LahanUpdate';

import Gedung from './pages/sarana-prasarana/gedung/Gedung';
import Ruangan from './pages/sarana-prasarana/ruangan/Ruangan';
import RuanganDetail from './pages/sarana-prasarana/ruangan/RuanganDetail';

import SanitasiAir from './pages/sarana-prasarana/sanitasi-air/index';
import AssetTetap from './pages/sarana-prasarana/asset-tetap/list/index';
import AssetTetapDetail from './pages/sarana-prasarana/asset-tetap/detail/index';
import AssetTetapUnitDetail from './pages/sarana-prasarana/asset-tetap/UnitDetail';
import AssetLancar from './pages/sarana-prasarana/asset-lancar/list/index';
import AssetLancarHistory from './pages/sarana-prasarana/asset-lancar/history/list/index';
import SaranaPembelajaran from './pages/sarana-prasarana/sarana-pembelajaran/list/index';

import BookingRuangan from './pages/sarana-prasarana/booking-ruangan/list/index';
import PeminjamanBarang from './pages/sarana-prasarana/peminjaman-barang/list/index';
import Laporan from './pages/sarana-prasarana/laporan/Laporan';

//Lembaga
import ProfilLembaga from './pages/lembaga/ProfilLembaga';
import RiwayatBantuan from './pages/lembaga/riwayat-bantuan/list/index';
import StrukturOrganisasi from './pages/lembaga/struktur-organisasi/list/index';
import StrukturOrganisasiDetail from './pages/lembaga/struktur-organisasi/detail/list/index';

// Peserta didik
import Siswa from './pages/peserta-didik/list/index';
import SiswaCreate from './pages/peserta-didik/SiswaCreate';
import SiswaDetail from './pages/peserta-didik/detail/SiswaDetail';
import Alumni from './pages/peserta-didik/alumni/list/index';
import AlumniDetailInternal from './pages/peserta-didik/alumni/detail/Internal';
import AlumniDetailExternal from './pages/peserta-didik/alumni/detail/External';
import CalonPesertaDidik from './pages/peserta-didik/calon/list/index';
import CalonPesertaDidikDetail from './pages/peserta-didik/calon/detail/index';


//GTK-PTK
import PTKNonAktif from './pages/tenaga-kependidikan/PTKNonAktif';

import Guru from './pages/tenaga-kependidikan/guru/Guru';
import GuruCreate from './pages/tenaga-kependidikan/guru/GuruCreate';
import GuruDetail from './pages/tenaga-kependidikan/guru/GuruDetail';

import Tendik from './pages/tenaga-kependidikan/tendik/Tendik';
import TendikCreate from './pages/tenaga-kependidikan/tendik/TendikCreate';
import TendikDetail from './pages/tenaga-kependidikan/tendik/TendikDetail';

//Kesiswaan
import TataTertibSekolah from './pages/kesiswaan/tata-tertib-sekolah/TataTertibSekolah';
import PelanggaranSiswa from './pages/kesiswaan/pelanggaran-siswa/PelanggaranSiswa';
import PelanggaranSiswaDetail from './pages/kesiswaan/pelanggaran-siswa/PelanggaranSiswaDetail';
import PelanggaranSiswaCreate from './pages/kesiswaan/pelanggaran-siswa/PelanggaranSiswaCreate';
import PenghargaanSiswa from './pages/kesiswaan/penghargaan-siswa/PenghargaanSiswa';
import PenghargaanSiswaDetail from './pages/kesiswaan/penghargaan-siswa/PenghargaanSiswaDetail';

//Bimbingan Konseling
import ProfilBk from './pages/bimbingan-konseling/profil-bk/ProfilBk';
import HasilTesPesertaDidik from './pages/bimbingan-konseling/hasil-tes/HasilTesPesertaDidik';
import HasilTestPesertaDidikDetail from './pages/bimbingan-konseling/hasil-tes/HasilTestPesertaDidikDetail';
import BimbinganKonselingLaporan from './pages/bimbingan-konseling/laporan/Laporan';
import BimbinganKonselingLaporanDetail from './pages/bimbingan-konseling/laporan/LaporanDetail';
import MediaInformasi from './pages/bimbingan-konseling/media-informasi/MediaInformasi';
import Konsultasi from './pages/bimbingan-konseling/konsultasi/Konsultasi';
import KonsultasiDetail from './pages/bimbingan-konseling/konsultasi/KonsultasiDetail';

//Konten
import KontenPrestasi from './pages/konten/prestasi/list/index';
import KontenArtikel from './pages/konten/artikel/list/index';
import KontenKegiatanPublikasi from './pages/konten/kegiatan-publikasi/list/index';
import KontenPengumuman from './pages/konten/pengumuman/list/index';
import KontenPengumumanPPDB from './pages/konten/pengumuman-ppdb/list/index';
import KontenPeringatanPPDB from './pages/konten/peringatan-ppdb/list/index';

//Presensi
import Kehadiran from './pages/presensi/kehadiran/index';
import KetidakHadiran from './pages/presensi/ketidakhadiran/index';

//Kalender & Kegiatan
import KalenderPendidikan from './pages/kegiatan/kalender-pendidikan/list/index';
import KalenderKegiatan from './pages/kegiatan/kegiatan/list/index';
import Kalender from './pages/kegiatan/kalender-pendidikan/calendar/index'
import Ekstrakurikuler from './pages/kegiatan/ekstrakurikuler/list/index';
import EkstrakurikulerDetail from './pages/kegiatan/ekstrakurikuler/detail/list/index';
import EkstrakurikulerDetailForm from './pages/kegiatan/ekstrakurikuler/detail/form/index';
import Pramuka from './pages/kegiatan/pramuka/list/index';
import PramukaDetail from './pages/kegiatan/pramuka/detail/list/index';

//Keuangan

// Pemasukan
import Pemasukan from './pages/keuangan/pemasukan/list/index';
import PemasukanDetail from './pages/keuangan/pemasukan/detail/list/index';
import BiayaOpsional from './pages/keuangan/biaya-opsional/list/index';
import PembayaranSiswa from './pages/keuangan/pembayaran-siswa/list/index';
import PembayaranSiswaTagihan from './pages/keuangan/pembayaran-siswa/tagihan/list/index';

// Pengeluaran
import Pengeluaran from './pages/keuangan/pengeluaran/list/index';
import PengeluaranDetail from './pages/keuangan/pengeluaran/detail/list/index';

// Tabungan
import Tabungan from './pages/keuangan/tabungan/list/index';
import TabunganDetail from './pages/keuangan/tabungan/detail/list/index';

// Gaji
import Gaji from './pages/keuangan/gaji/list/index';
import GajiConfig from './pages/keuangan/gaji/config/list/index';

// Laporan
import LaporanKeuangan from './pages/keuangan/laporan/index';

//Buku tamu
import BukuTamu from './pages/buku-tamu/list/index';

//Log
import LogImport from './pages/log/Import';
import LogExport from './pages/log/Export';

// Notification
import NotifPPDB from './pages/notification/ppdb/list/index';
import NotifPresensi from './pages/notification/presensi/list/index';

// Ubudiyah
import CapaianUbudiyah from './pages/ubudiyah/capaian-ubudiyah/list/index';
import PenilaianUbudiyah from './pages/ubudiyah/penilaian-ubudiyah/list/index';
import PenilaianUbudiyahDetail from './pages/ubudiyah/penilaian-ubudiyah/detail/list/index';
import AspekPenilaianUbudiyah from './pages/ubudiyah/aspek-penilaian/list/index';
import AspekKategoriUbudiyah from './pages/ubudiyah/aspek-kategori/list/index';

// Supervisi
import SupervisiBerkasKategori from './pages/supervisi/berkas-kategori/list/index';
import SupervisiBerkasJenis from './pages/supervisi/berkas-jenis/list/index';
import SupervisiInstrumen from './pages/supervisi/instrumen/list/index';
import SupervisiPKG from './pages/supervisi/pkg/list/index';
import SupervisiPKGBerkas from './pages/supervisi/pkg/detail/list/index';
import SupervisiRekapitulasiPKG from './pages/supervisi/rekapitulasi-pkg/list/index';
import SupervisiRekapitulasiPKKM from './pages/supervisi/rekapitulasi-pkkm/list/index';



const { Content, Footer } = Layout;

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isAuth: false,
      privilege: {
        status: false,
        access: {}
      },
      menu: [],
      isMobile: false,
    }
  }

  componentWillMount() {
    console.disableYellowBox = true;
    this.checkAuth()
    this.getMenu()
  }

  checkAuth() {
    if (!_getToken()) {
      this.setState({
        loading: false
      })
    } else {
      const pathname = window.location.pathname;
      _setAxios("privilege/access", "GET").then(resp => {
        if (resp.status === true) {
          if (pathname === "/login") {
            window.location.href = Config.base_url
          } else {
            this.setState({
              loading: false,
              isAuth: true,
              privilege: resp.data.data
            })
          }
        } else {
          this.setState({
            loading: false,
            isAuth: false,
          })
        }
      })
    }
  }

  getMenu() {
    if (!_getToken()) {
      this.setState({
        loading: false
      })
    } else {
      const headers = {
        'Accept': 'application/json',
        'Authorization': _getToken()
      };
      axios.get(Config.api + '/privilege/menu', { headers })
        .then((resp) => {
          this.setState({ menu: resp.data.data });
        })
        .catch(function (error) {
          if (error.response) {
            // _error('topRight', 'Failed', error.response.data.message)
          }
        });
    }
  }
  render() {
    const { loading, privilege, menu, isMobile } = this.state

    const globalState = {
      privilege: privilege,
      menu: menu,
      menuCollapse: false,
      isMobile: isMobile
    }

    if (loading === true) {
      return <Preload />
    }
    //reducer
    const rootReducer = (state = globalState, action) => {
      switch (action.type) {
        case "AUTH":
          return {
            ...state,
            privilege: action.privilege,
            menu: action.menu
          }
        case "MENU":
          return {
            ...state,
            menuCollapse: action.menuCollapse,
          }
        default:
          return state
      }
    }



    //store
    const storeRedux = createStore(rootReducer);

    return (
      <Provider store={storeRedux}>
        <Router>
          {
            this.state.isAuth === true ?
              <Layout style={{ minHeight: '100vh' }}>


                <Sidebar {...this.props} />

                <Layout className="site-layout">
                  <HeaderLayout {...this.props} />
                  <Content>
                    <Switch>
                      <Route key={0} exact path="/login" component={(props) => <Redirect to="/" />} />
                      <Route key={1} exact path="/" component={(props) => <Home />} />

                      {/* LEMBAGA*/}
                      <Route key={2} exact path="/lembaga/profil" component={(props) => <ProfilLembaga {...props} />} />
                      <Route key={3} exact path="/lembaga/riwayat-bantuan" component={(props) => <RiwayatBantuan {...props} />} />
                      <Route key={4} exact path="/lembaga/struktur-organisasi" component={(props) => <StrukturOrganisasi {...props} />} />
                      <Route key={5} exact path="/lembaga/struktur-organisasi/detail/:id" component={(props) => <StrukturOrganisasiDetail {...props} />} />

                      {/* SISWA */}
                      <Route key={6} exact path="/peserta-didik/aktif" component={(props) => <Siswa {...props} />} />
                      <Route key={7} exact path="/peserta-didik/non-aktif" component={(props) => <Siswa {...props} />} />
                      <Route key={8} exact path="/peserta-didik/aktif/create" component={(props) => <SiswaCreate />} />
                      <Route key={9} exact path="/peserta-didik/aktif/detail/:id" component={(props) => <SiswaDetail {...props} />} />
                      <Route key={10} exact path="/peserta-didik/non-aktif/detail/:id" component={(props) => <SiswaDetail {...props} />} />
                      <Route key={11} exact path="/peserta-didik/calon" component={(props) => <CalonPesertaDidik {...props} />} />
                      <Route key={12} exact path="/peserta-didik/calon/detail/:id" component={(props) => <CalonPesertaDidikDetail {...props} />} />

                      {/* ALUMNI */}
                      <Route key={13} exact path="/peserta-didik/alumni" component={(props) => <Alumni {...props} />} />
                      <Route key={14} exact path="/peserta-didik/alumni/detail/internal/:id" component={(props) => <AlumniDetailInternal {...props} />} />
                      <Route key={15} exact path="/peserta-didik/alumni/detail/external/:id" component={(props) => <AlumniDetailExternal {...props} />} />

                      {/* TENAGA KEPENDIDIKAN */}
                      <Route key={16} exact path="/tenaga-kependidikan/guru" component={(props) => <Guru {...props} />} />
                      <Route key={17} exact path="/tenaga-kependidikan/guru/create" component={(props) => <GuruCreate {...props} />} />
                      <Route key={18} exact path="/tenaga-kependidikan/guru/detail/:id" component={(props) => <GuruDetail {...props} />} />
                      <Route key={19} exact path="/tenaga-kependidikan/tendik" component={(props) => <Tendik {...props} />} />
                      <Route key={20} exact path="/tenaga-kependidikan/tendik/non-aktif" component={(props) => <PTKNonAktif {...props} />} />
                      <Route key={21} exact path="/tenaga-kependidikan/tendik/create" component={(props) => <TendikCreate {...props} />} />
                      <Route key={22} exact path="/tenaga-kependidikan/tendik/detail/:id" component={(props) => <TendikDetail {...props} />} />

                      {/* KURIKULUM PAGE */}
                      <Route key={23} exact path="/kurikulum/mata-pelajaran" component={(props) => <MataPelajaran />} />
                      <Route key={24} exact path="/kurikulum/rombel" component={(props) => <Rombel />} />
                      <Route key={25} exact path="/kurikulum/rombel/create" component={(props) => <RombelCreate {...props} />} />
                      <Route key={26} exact path="/kurikulum/rombel/detail/:id" component={(props) => <RombelDetail {...props} />} />
                      <Route key={27} exact path="/kurikulum/jadwal" component={(props) => <Jadwal  {...props} />} />
                      <Route key={28} exact path="/kurikulum/jadwal/pelajaran/detail/:id" component={(props) => <JadwalPelajaranDetail {...props} />} />
                      <Route key={29} exact path="/kurikulum/jadwal/mengajar/detail/:id" component={(props) => <JadwalMengajarDetail {...props} />} />
                      <Route key={30} exact path="/kurikulum/tahun-ajaran" component={(props) => <TahunAjaran {...props} />} />

                      {/* CONFIGURATION PAGE */}
                      <Route key={31} exact path="/configuration/user" component={(props) => <ConfUser />} />
                      <Route key={32} exact path="/configuration/role" component={(props) => <ConfRole />} />
                      <Route key={33} exact path="/configuration/menu" component={(props) => <ConfMenu />} />
                      <Route key={34} exact path="/configuration/privilege/:id" component={(props) => <ConfPrivilege {...props} />} />
                      <Route key={35} exact path="/configuration/absensi" component={(props) => <ConfAbsensi {...props} />} />
                      <Route key={35} exact path="/configuration/dropdown" component={(props) => <ConfDropdownList {...props} />} />
                      <Route key={37} exact path="/profile" component={(props) => <Profile {...props} />} />

                      {/* MASTER PAGE */}
                      <Route key={7.1} exact path="/master/jenis-ruangan" component={(props) => <JenisRuangan />} />
                      <Route key={7.2} exact path="/master/jurusan" component={(props) => <Jurusan />} />
                      <Route key={7.3} exact path="/master/jenis-jalur-ppdb" component={(props) => <JenisJalurPPDB />} />
                      <Route key={7.4} exact path="/master/sku" component={(props) => <MasterSKU />} />
                      <Route key={7.5} exact path="/master/pos" component={(props) => <MasterPOS {...props} />} />
                      <Route key={7.6} exact path="/master/berkas-persyaratan" component={(props) => <BerkasPersyaratan {...props} />} />

                      {/* SARANA PRASARANA PAGE */}
                      <Route key={41} exact path="/sarana-prasarana/lahan" component={(props) => <Lahan {...props} />} />
                      <Route key={42} exact path="/sarana-prasarana/lahan/create" component={(props) => <LahanCreate {...props} />} />
                      <Route key={43} exact path="/sarana-prasarana/lahan/detail/:id" component={(props) => <LahanDetail {...props} />} />
                      <Route key={44} exact path="/sarana-prasarana/lahan/update/:id" component={(props) => <LahanUpdate {...props} />} />

                      <Route key={45} exact path="/sarana-prasarana/gedung" component={(props) => <Gedung />} />
                      <Route key={46} exact path="/sarana-prasarana/ruangan" component={(props) => <Ruangan />} />
                      <Route key={47} exact path="/sarana-prasarana/ruangan/detail/:id" component={(props) => <RuanganDetail {...props} />} />

                      <Route key={48} exact path="/sarana-prasarana/sanitasi-air" component={(props) => <SanitasiAir {...props} />} />
                      <Route key={49} exact path="/sarana-prasarana/asset-tetap" component={(props) => <AssetTetap {...props} />} />
                      <Route key={50} exact path="/sarana-prasarana/asset-tetap/:kategori_nama/:unit_nama/:kategori_id/:unit_id" component={(props) => <AssetTetapDetail {...props} />} />
                      <Route key={51} exact path="/sarana-prasarana/asset-tetap/unit/:reference_id" component={(props) => <AssetTetapUnitDetail {...props} />} />
                      <Route key={52} exact path="/sarana-prasarana/asset-lancar" component={(props) => <AssetLancar {...props} />} />
                      <Route key={53} exact path="/sarana-prasarana/asset-lancar/history/:asset_nama/:asset_id" component={(props) => <AssetLancarHistory {...props} />} />
                      <Route key={54} exact path="/sarana-prasarana/sarana-pembelajaran" component={(props) => <SaranaPembelajaran {...props} />} />

                      <Route key={55} exact path="/sarana-prasarana/booking-ruangan" component={(props) => <BookingRuangan {...props} />} />
                      <Route key={56} exact path="/sarana-prasarana/peminjaman-barang" component={(props) => <PeminjamanBarang {...props} />} />
                      <Route key={57} exact path="/sarana-prasarana/laporan" component={(props) => <Laporan {...props} />} />

                      {/* KESISWAAN */}
                      <Route key={58} exact path="/kesiswaan/tata-tertib-sekolah" component={(props) => <TataTertibSekolah {...props} />} />
                      <Route key={59} exact path="/kesiswaan/pelanggaran-siswa" component={(props) => <PelanggaranSiswa {...props} />} />
                      <Route key={60} exact path="/kesiswaan/pelanggaran-siswa/tambah" component={(props) => <PelanggaranSiswaCreate {...props} />} />
                      <Route key={61} exact path="/kesiswaan/pelanggaran-siswa/detail/:id/:rombel_id" component={(props) => <PelanggaranSiswaDetail {...props} />} />

                      <Route key={62} exact path="/kesiswaan/penghargaan-siswa" component={(props) => <PenghargaanSiswa {...props} />} />
                      <Route key={63} exact path="/kesiswaan/penghargaan-siswa/detail/:id/:rombel_id" component={(props) => <PenghargaanSiswaDetail {...props} />} />

                      {/* BIMBINGAN KONSELING */}
                      <Route key={64} exact path="/bimbingan-konseling/profil-bk" component={(props) => <ProfilBk {...props} />} />
                      <Route key={65} exact path="/bimbingan-konseling/hasil-tes" component={(props) => <HasilTesPesertaDidik {...props} />} />
                      <Route key={66} exact path="/bimbingan-konseling/hasil-tes/detail/:id/:rombel_id" component={(props) => <HasilTestPesertaDidikDetail {...props} />} />
                      <Route key={67} exact path="/bimbingan-konseling/media-informasi" component={(props) => <MediaInformasi {...props} />} />
                      <Route key={68} exact path="/bimbingan-konseling/konsultasi" component={(props) => <Konsultasi {...props} />} />
                      <Route key={69} exact path="/bimbingan-konseling/konsultasi/detail/:id/:rombel_id" component={(props) => <KonsultasiDetail {...props} />} />
                      <Route key={70} exact path="/bimbingan-konseling/laporan" component={(props) => <BimbinganKonselingLaporan {...props} />} />
                      <Route key={71} exact path="/bimbingan-konseling/laporan/detail/:rombel_id" component={(props) => <BimbinganKonselingLaporanDetail {...props} />} />

                      {/* KONTEN */}
                      <Route key={72} exact path="/konten/prestasi" component={(props) => <KontenPrestasi {...props} />} />
                      <Route key={73} exact path="/konten/artikel" component={(props) => <KontenArtikel {...props} />} />
                      <Route key={74} exact path="/konten/kegiatan-publikasi" component={(props) => <KontenKegiatanPublikasi {...props} />} />
                      <Route key={75} exact path="/konten/pengumuman" component={(props) => <KontenPengumuman {...props} />} />
                      <Route key={76} exact path="/konten/pengumuman-ppdb" component={(props) => <KontenPengumumanPPDB {...props} />} />
                      <Route key={77} exact path="/konten/peringatan-ppdb" component={(props) => <KontenPeringatanPPDB {...props} />} />

                      {/* PRESENSI */}
                      <Route key={78} exact path="/presensi/kehadiran" component={(props) => <Kehadiran {...props} />} />
                      <Route key={79} exact path="/presensi/ketidakhadiran" component={(props) => <KetidakHadiran {...props} />} />

                      {/* KALENDER & KEGIATAN */}
                      <Route key={80} exact path="/kalender-kegiatan/pendidikan" component={(props) => <KalenderPendidikan {...props} />} />
                      <Route key={81} exact path="/kalender-kegiatan/kegiatan" component={(props) => <KalenderKegiatan {...props} />} />
                      <Route key={82} exact path="/kalender-kegiatan/kalender" component={(props) => <Kalender {...props} />} />
                      <Route key={83} exact path="/ekstrakurikuler-pramuka/ekstrakurikuler" component={(props) => <Ekstrakurikuler {...props} />} />
                      <Route key={84} exact path="/ekstrakurikuler-pramuka/ekstrakurikuler/detail/:ekstrakurikuler_id" component={(props) => <EkstrakurikulerDetail {...props} />} />
                      <Route key={85} exact path="/ekstrakurikuler-pramuka/ekstrakurikuler/detail/form/:ekstrakurikuler_id" component={(props) => <EkstrakurikulerDetailForm {...props} />} />
                      <Route key={86} exact path="/ekstrakurikuler-pramuka/pramuka" component={(props) => <Pramuka {...props} />} />
                      <Route key={87} exact path="/ekstrakurikuler-pramuka/pramuka/detail/:siswa_id" component={(props) => <PramukaDetail {...props} />} />

                      <Route key={88} exact path="/buku-tamu" component={(props) => <BukuTamu {...props} />} />
                      
                      {/* KEUANGAN */}
                      {/* PEMASUKAN */}
                      <Route key={14.1} exact path="/keuangan/pemasukan" component={(props) => <Pemasukan {...props} />} />
                      <Route key={14.2} exact path="/keuangan/pemasukan/detail/:pemasukan_id" component={(props) => <PemasukanDetail {...props} />} />
                      <Route key={14.3} exact path="/keuangan/biaya-opsional" component={(props) => <BiayaOpsional {...props} />} />
                      <Route key={14.4} exact path="/keuangan/pembayaran-siswa" component={(props) => <PembayaranSiswa {...props} />} />
                      <Route key={14.2} exact path="/keuangan/pembayaran-siswa/tagihan/:siswa_id" component={(props) => <PembayaranSiswaTagihan {...props} />} />

                      {/* PENGELUARAN */}
                      <Route key={15.1} exact path="/keuangan/pengeluaran" component={(props) => <Pengeluaran {...props} />} />
                      <Route key={15.2} exact path="/keuangan/pengeluaran/detail/:pengeluaran_id" component={(props) => <PengeluaranDetail {...props} />} />
                      
                      {/* TABUNGAN */}
                      <Route key={16.1} exact path="/keuangan/tabungan" component={(props) => <Tabungan {...props} />} />
                      <Route key={16.2} exact path="/keuangan/tabungan/detail/:siswa_id" component={(props) => <TabunganDetail {...props} />} />
                      
                      {/* GAJI */}
                      <Route key={17.1} exact path="/keuangan/gaji" component={(props) => <Gaji {...props} />} />
                      <Route key={17.2} exact path="/keuangan/gaji/tunjangan" component={(props) => <GajiConfig {...props} />} />
                      
                      {/* LAPORAN */}
                      <Route key={18.1} exact path="/keuangan/laporan" component={(props) => <LaporanKeuangan {...props} />} />
                      
                      {/* UBUDIYAH */}
                      <Route key={19.0} exact path="/ubudiyah/aspek-kategori" component={(props) => <AspekKategoriUbudiyah {...props} />} />
                      <Route key={19.1} exact path="/ubudiyah/aspek-penilaian" component={(props) => <AspekPenilaianUbudiyah {...props} />} />
                      <Route key={19.2} exact path="/ubudiyah/capaian" component={(props) => <CapaianUbudiyah {...props} />} />
                      <Route key={19.3} exact path="/ubudiyah/penilaian" component={(props) => <PenilaianUbudiyah {...props} />} />
                      <Route key={19.4} exact path="/ubudiyah/penilaian/:siswa_id" component={(props) => <PenilaianUbudiyahDetail {...props} />} />
                    
                      {/* SUPERVISI */}
                      <Route key={20.0} exact path="/supervisi/berkas-kategori" component={(props) => <SupervisiBerkasKategori {...props} />} />
                      <Route key={20.1} exact path="/supervisi/berkas-jenis" component={(props) => <SupervisiBerkasJenis {...props} />} />
                      <Route key={20.2} exact path="/supervisi/instrumen" component={(props) => <SupervisiInstrumen {...props} />} />
                      <Route key={20.3} exact path="/supervisi/rekapitulasi-pkg" component={(props) => <SupervisiRekapitulasiPKG {...props} />} />
                      <Route key={20.4} exact path="/supervisi/rekapitulasi-pkkm" component={(props) => <SupervisiRekapitulasiPKKM {...props} />} />
                      <Route key={20.5} exact path="/supervisi/pkg/:tk_id" component={(props) => <SupervisiPKGBerkas {...props} />} />
                      <Route key={20.6} exact path="/supervisi/pkg" component={(props) => <SupervisiPKG {...props} />} />

                      {/* LOG */}
                      <Route key={89} exact path="/log/import" component={(props) => <LogImport />} />
                      <Route key={90} exact path="/log/export" component={(props) => <LogExport />} />
                      
                      {/* Notification */}
                      <Route key={91.1} exact path="/notification/ppdb" component={(props) => <NotifPPDB {...props}/>} />
                      <Route key={91.2} exact path="/notification/presensi" component={(props) => <NotifPresensi {...props}/>} />

                      <Route key={92.1} exact path="/403" component={(props) => <Error403 />} />
                      <Route key={92.2} component={Error404} />
                    </Switch>
                  </Content>
                  <Footer style={{ textAlign: 'center' }}>Madrasah Digital MAN 1 Gresik @{new Date().getFullYear()}</Footer>
                </Layout>
              </Layout>
              :
              <>
                <Switch>
                  <Route key={93} exact path="/sarana-prasarana/asset-tetap/unit/:reference_id" component={(props) => <AssetTetapUnitDetail {...props} />} />
                  <Route key={94} exact path="/" component={(props) => <Redirect to="/login" />} />
                  <Route key={95} exact path="/login" component={(props) => <Login />} />
                  <Route key={96} component={Error404} />
                </Switch>
              </>
          }
        </Router>
      </Provider>
    );
  }
}