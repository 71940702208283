import React from 'react';
import { Modal, Button, Form, Row, Col, Select, Divider, DatePicker, Input } from 'antd';

import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import { jenisPenegakOptions } from '../../../../data/options';
import { validate } from 'validate.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const schema = {
    tipe: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tgl: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tempat: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pelantik_nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pelantik_jabatan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pelantik_no_shb: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};
class Sertifikat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
            errors: {},
        };
    }

    componentDidMount(){
        this.setState({
            values: this.props.data
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }


    handleSubmit = () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            "siswa_id": this.props.data.siswa_id,
            "tgl": values.tgl ? values.tgl : null,
            "tempat": values.tempat ? values.tempat : null,
            "pelantik_nama": values.pelantik_nama ? values.pelantik_nama : null,
            "pelantik_jabatan": values.pelantik_jabatan ? values.pelantik_jabatan : null,
            "pelantik_no_shb": values.pelantik_no_shb ? values.pelantik_no_shb : null,
            "tipe": values.tipe ? values.tipe : null
        }

        let endpoint = "kaldik-kegiatan/pramuka/sertifikat"
        let method = "POST"
        if (this.props.data.action !== "insert") {
            endpoint = "kaldik-kegiatan/pramuka/sertifikat/" + this.props.data.siswa_id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {
        console.log("insert", this.props.data)

        return (
            <Modal
                maskClosable={false}
                title={"Form Sertifikat Penegak"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            ><Form
                name="basic"
                autoComplete="off"
                layout="vertical"
                onFinish={this.handleSubmit}
            >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tipe Sertifikat *">
                                <Select
                                    allowClear
                                    name="tipe"
                                    placeholder="Tipe sertifikat"
                                    defaultValue={this.props.data.tipe}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tipe", val.value)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenisPenegakOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Tanggal Lulus *">
                                <DatePicker defaultValue={this.props.data.tgl ? dayjs(this.props.data.tgl, dateFormat) : null} format={dateFormat} style={{ width: "100%" }}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl", val)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Tempat *">
                                <Input
                                    name="tempat"
                                    placeholder="Nama"
                                    defaultValue={this.props.data.tempat}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("tempat", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Divider orientation="left" orientationMargin="0">
                                Informasi Pelantik:
                            </Divider>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Nama *">
                                <Input
                                    name="pelantik_nama"
                                    placeholder="Nama"
                                    defaultValue={this.props.data.pelantik_nama}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("pelantik_nama", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Jabatan *">
                                <Input
                                    name="pelantik_jabatan"
                                    placeholder="Jabatan"
                                    defaultValue={this.props.data.pelantik_jabatan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("pelantik_jabatan", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="No.SHB *">
                                <Input
                                    name="pelantik_no_shb"
                                    placeholder="No. SHB"
                                    defaultValue={this.props.data.pelantik_no_shb}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("pelantik_no_shb", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default Sertifikat;
