import React from 'react';

import { Select } from 'antd';
import { tingkatanKelasOptions } from '../../data/options';

const SelectTingkatanKelas = ({ ...props }) => {
  return (
    <Select
      {...props}
      placeholder="Pilih tingkatan kelas"
      style={{
        width: '100%',
      }}
      options={tingkatanKelasOptions}
    />
  );
};
export default SelectTingkatanKelas;
