import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class Desktop extends React.Component {

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'NISN',
                  dataIndex: 'siswa_nisn',
                }, {
                  title: 'NIS',
                  dataIndex: 'siswa_nis',
                }, {
                  title: 'Peserta Didik',
                  dataIndex: 'siswa_nama',
                }, {
                  title: 'Rombel',
                  dataIndex: 'rombel_nama',
                }, {
                  title: 'Tahun Ajaran',
                  render: (a, row) => <>{row.tahun_ajaran_awal} - {row.tahun_ajaran_akhir}</>,
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.detail && !action.download ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail ? (<Menu.Item key={0}><Link to={"/ubudiyah/penilaian/" + row.siswa_id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                    {action.download ? (<Menu.Item key={1} onClick={this.props.downloadCert.bind(this, true, row)}><DownloadOutlined /> Download Sertifikat</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
