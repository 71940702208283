import React from 'react';
import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty, Tag } from 'antd';
import {  DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.unit_nama ? row.unit_nama : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Kategori: {row.kategori_nama ? row.kategori_nama : "-"} <br />
                              Keterangan: {row.keterangan ? row.keterangan : "-"} <br />
                            </small>
                          </Col>
                          <Col xs={24}>
                            <Tag color="blue">Baik ({row.jml_baik})</Tag> <br/>
                            <Tag color="blue">Rusak Ringan ({row.jml_rusak_ringan})</Tag> <br/>
                            <Tag color="blue">Rusak Berat ({row.jml_rusak_berat})</Tag>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail === true ? (<Menu.Item key={0}><Link to={`/sarana-prasarana/asset-tetap/${row.kategori_id}/${row.unit_id}?kategori=${row.kategori_nama}&unit=${row.unit_nama}`}><InfoCircleOutlined /> Detail Unit</Link></Menu.Item>) : ""}
                      </Menu>}>
                        <div className="ant-dropdown-link tb-action">
                          <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                        </div>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
