import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownCircleOutlined, DownloadOutlined, EditOutlined, FileDoneOutlined, FileSyncOutlined, SendOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

const statusLabels = {
  "Draft": "yellow",
  "Diajukan": "",
  "Disetujui": "blue",
  "Ditolak": "red",
  "Selesai": "green",
}

const statusIcons = {
  "Draft": <FileSyncOutlined />,
  "Diajukan": <SendOutlined style={{ transform: "rotate(-30deg)" }} />,
  "Disetujui": <CheckCircleOutlined />,
  "Ditolak": <CloseCircleOutlined />,
  "Selesai": <FileDoneOutlined />,
}

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={(row.gelar_depan ? row.gelar_depa + " " : "") + (row.nama_lengkap ? row.nama_lengkap : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Tanggal: {row.tgl_awal} to {row.tgl_akhir} <br />
                              NUPTK: {row.nuptk ? row.nuptk : "-"} <br />
                              Status Kepegawaian: {row.status_kepegawaian ? row.status_kepegawaian : "-"} <br />
                              Status: <Tag color={statusLabels[row.status]} icon={statusIcons[row.status]}>{row.status}</Tag> <br />
                              Keterangan: {row.keterangan ? row.keterangan : "-"} <br />
                              File: {row.file ? <a target="_blank" rel="noopener noreferrer" download={true} href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-"}
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(this.props.privilege.profile.roles === "Guru" && row.status === "Diajukan" ? "-" : row.status === "Disetujui" || row.status === "Ditolak" || row.status === "Selesai" ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.update && row.status === "Draft" ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                        {action.request_approval && !action.approval && row.status === "Draft" ? (<Menu.Item key={1} onClick={this.props.modalRequestApproval.bind(this, true, row)}><SendOutlined style={{ transform: "rotate(-30deg)" }} /> Minta Persetujuan</Menu.Item>) : ""}
                        {action.approval && row.status === "Diajukan" ? (<Menu.Item key={2} onClick={this.props.modalApprove.bind(this, true, row)}><CheckCircleOutlined /> Setujui</Menu.Item>) : ""}
                        {action.approval && row.status === "Diajukan" ? (<Menu.Item key={3} onClick={this.props.modalReject.bind(this, true, row)}><CloseCircleOutlined /> Tolak</Menu.Item>) : ""}
                        {action.delete && row.status === "Draft" ? (<Menu.Item key={6} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
