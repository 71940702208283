import React from 'react';
import { connect } from 'react-redux'

import { _setAxios, _success, _tableLogActivity, getParamTable, _validationFormMsg, _getBase64Upload, _getBase64 } from '../../lib/Helper';
import { schemaStatusRiwayatKepegawaian } from './schema';
import validate from 'validate.js';
import { golRuangKepangkatanOptions, jenisPerubahanOptions, penugasanOptions, statusKeaktifanOptions, statusPenugasanOptions, jenisSKOptions } from '../../data/options';

import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal, Upload, message } from 'antd';
import { DownloadOutlined, SaveOutlined, InfoCircleOutlined, SearchOutlined, EllipsisOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';

const { Dragger } = Upload;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class StatusRiwayatKepegawaian extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            form: {
                visible: false,
                loading: false,
            },
            fileList: null,
            validateFile: false
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tk/riwayat-kepegawaian/table/" + this.props.dataID, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        const errors = validate(values, schemaStatusRiwayatKepegawaian);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaStatusRiwayatKepegawaian);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        const isJpgOrPngPdf = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPngPdf) {
            message.error('Anda bisa upload JPG/PNG/PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isJpgOrPngPdf && isLt2M

        if (flag === false) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    // CREATE START ------------------------------------------

    hideModalForm = (row) => {
        this.setState({
            form: {
                ...this.state.form,
                visible: false
            },
            errors: {},
        });
    };

    showModalForm = (row) => {
        if (row.id) {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },

                values: {
                    id: row.id ? row.id : null,
                    jenis_perubahan: row.jenis_perubahan ? row.jenis_perubahan : null,
                    instansi_induk: row.instansi_induk ? row.instansi_induk : null,
                    tgl_efektif: row.tgl_efektif ? row.tgl_efektif : null,
                    status_keaktifan: row.status_keaktifan ? row.status_keaktifan : null,
                    jabatan: row.jabatan ? row.jabatan : null,
                    status_penugasan: row.status_penugasan ? row.status_penugasan : null,
                    pangkat: row.pangkat ? row.pangkat : null,
                    no_sk: row.no_sk ? row.no_sk : null,
                    tgl_sk: row.tgl_sk ? row.tgl_sk : null,
                    jenis_sk: row.jenis_sk ? row.jenis_sk : null,
                },
            });
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    visible: true,
                },
                values: {},
            });
        }
    };

    onSubmitForm = async (e) => {
        e.preventDefault();
        const { values } = this.state

        //Validation 
        let validation = this.setValidate(values, schemaStatusRiwayatKepegawaian)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Tanggal 
        let tglEfektif = values.tgl_efektif
        if (values.tgl_efektif && values.tgl_efektif instanceof Date) {
            let tgl = values.tgl_efektif.getDate() <= 9 ? "0" + values.tgl_efektif.getDate() : values.tgl_efektif.getDate()
            let bln = values.tgl_efektif.getMonth() <= 9 ? "0" + values.tgl_efektif.getMonth() : values.tgl_efektif.getMonth()
            let thn = values.tgl_efektif.getFullYear()

            tglEfektif = tgl + "-" + bln + "-" + thn
        }

        let tglSk = values.tgl_sk
        if (values.tgl_sk && values.tgl_sk instanceof Date) {
            let tgl = values.tgl_sk.getDate() <= 9 ? "0" + values.tgl_sk.getDate() : values.tgl_sk.getDate()
            let bln = values.tgl_sk.getMonth() <= 9 ? "0" + values.tgl_sk.getMonth() : values.tgl_sk.getMonth()
            let thn = values.tgl_sk.getFullYear()

            tglSk = tgl + "-" + bln + "-" + thn
        }

        // File base64
        let fileList = this.state.fileList
        let file = null
        if (fileList && fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }
        }

        // Payload
        const param = {
            jenis_perubahan: values.jenis_perubahan ? values.jenis_perubahan : null,
            instansi_induk: values.instansi_induk ? values.instansi_induk : null,
            tgl_efektif: tglEfektif ? tglEfektif : null,
            jabatan: values.jabatan ? values.jabatan : null,
            status_penugasan: values.status_penugasan ? values.status_penugasan : null,
            pangkat: values.pangkat ? values.pangkat : null,
            status_keaktifan: values.status_keaktifan ? values.status_keaktifan : null,
            no_sk: values.no_sk ? values.no_sk : null,
            tgl_sk: tglSk ? tglSk : null,
            jenis_sk: values.jenis_sk ? values.jenis_sk : null,
            file: file
        }

        // Define Network
        let method = "POST"
        let payload = [param]
        let id = this.props.dataID
        if (values.id) {
            method = "PUT"
            payload = param
            id = values.id
        }

        this.setState({
            form: {
                ...this.state.form,
                loading: true
            }
        });
        _setAxios("tk/riwayat-kepegawaian/" + id, method, payload).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.hideModalForm()
                this.fetchTable()
            }

            this.setState({
                form: {
                    ...this.state.form,
                    loading: false
                }
            });
        })
    };

    // CREATE END ------------------------------------------


    onDelete = (row) => {
        Modal.confirm({
            title: 'Hapus Data',
            width: 470,
            icon: <ExclamationCircleOutlined />,
            content: <>Data akan dihapus dari list, Apakah Anda Yakin ?</>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tk/riwayat-kepegawaian/" + row.id, "DELETE").then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const { table, values, errors } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalForm = <Modal
            title="Form Riwayat Kepegawaian"
            visible={this.state.form.visible}
            width={700}
            onCancel={this.hideModalForm}
            footer={<>
                <Button onClick={this.hideModalForm}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.onSubmitForm} loading={this.state.form.loading} icon={<SaveOutlined />}>Simpan</Button>
            </>}
        >
            <Row gutter={[16, 16]}>
                <Col xs={12}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Autocomplete
                                options={jenisPerubahanOptions}
                                value={values.jenis_perubahan || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jenis_perubahan", newValue ? newValue.value : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Jenis Perubahan *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jenis_perubahan"
                                    type="text"
                                    error={this.hasError('jenis_perubahan')}
                                    helperText={
                                        this.hasError('jenis_perubahan') ? errors.jenis_perubahan[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="Instansi Induk *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="instansi_induk"
                                type="text"
                                onChange={this.handleChange}
                                value={values.instansi_induk || ''}
                                error={this.hasError('instansi_induk')}
                                helperText={
                                    this.hasError('instansi_induk') ? errors.instansi_induk[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        label="Tanggal Efektif *"
                                        value={values.tgl_efektif || null}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tgl_efektif", newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.hasError('tgl_efektif')}
                                            helperText={
                                                this.hasError('tgl_efektif') ? errors.tgl_efektif[0] : null
                                            }
                                            {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Autocomplete
                                options={statusKeaktifanOptions}
                                value={values.status_keaktifan || ''}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("status_keaktifan", newValue ? newValue.value : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Status Keaktifan *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="status_keaktifan"
                                    type="text"
                                    error={this.hasError('status_keaktifan')}
                                    helperText={
                                        this.hasError('status_keaktifan') ? errors.status_keaktifan[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={penugasanOptions}
                                value={values.jabatan || ''}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jabatan", newValue ? newValue.value : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Fungsi/Jabatan *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jabatan"
                                    type="text"
                                    error={this.hasError('jabatan')}
                                    helperText={
                                        this.hasError('jabatan') ? errors.jabatan[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={statusPenugasanOptions}
                                value={values.status_penugasan || ''}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("status_penugasan", newValue ? newValue.value : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Status Penugasan"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="status_penugasan *"
                                    type="text"
                                    error={this.hasError('status_penugasan')}
                                    helperText={
                                        this.hasError('status_penugasan') ? errors.status_penugasan[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={golRuangKepangkatanOptions}
                                value={values.pangkat || ''}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("pangkat", newValue ? newValue.value : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Gol.Ruang/Kepangkatan *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="pangkat"
                                    type="text"
                                    error={this.hasError('pangkat')}
                                    helperText={
                                        this.hasError('pangkat') ? errors.pangkat[0] : null
                                    }
                                />}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="No. SK"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="no_sk"
                                type="text"
                                onChange={this.handleChange}
                                value={values.no_sk || ''}
                                error={this.hasError('no_sk')}
                                helperText={
                                    this.hasError('no_sk') ? errors.no_sk[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                    <MobileDatePicker
                                        label="Tanggal SK"
                                        value={values.tgl_sk || null}
                                        onChange={(newValue) => {
                                            this.handleChangeSetValue("tgl_sk", newValue)
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.hasError('tgl_sk')}
                                            helperText={
                                                this.hasError('tgl_sk') ? errors.tgl_sk[0] : null
                                            }
                                            {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Autocomplete
                                options={jenisSKOptions}
                                value={values.jenis_sk || ''}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jenis_sk", newValue ? newValue.value : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Jenis SK"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="jenis_sk"
                                    type="text"
                                    error={this.hasError('jenis_sk')}
                                    helperText={
                                        this.hasError('jenis_sk') ? errors.jenis_sk[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24}>
                            <Dragger
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                fileList={this.state.fileList}
                                onChange={this.handleChangeFile}
                                beforeUpload={this.beforeUploadFile}
                                maxCount={1}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                                <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                                <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                            </Dragger>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Status dan Riwayat Kepegawaian"
                    extra={
                        <>
                            {/* <Button type="text"><Link to={`/tenaga-kependidikan/guru`}>Kembali</Link></Button> */}
                            <Tooltip title="Tambah">
                                <Button type='primary' block icon={<PlusOutlined />} onClick={this.showModalForm}> Tambah</Button>
                            </Tooltip>
                        </>
                    }

                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={21}>
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => {
                                const params = getParamTable("search", table, e.target.value)
                                this.fetch(params)
                            }}
                            value={table.search || ""}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={3}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>

                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Tanggal Efektif',
                                    dataIndex: 'tgl_efektif',
                                },
                                {
                                    title: 'Status Penugasan',
                                    dataIndex: 'status_penugasan',
                                },
                                {
                                    title: 'Lembaga Induk',
                                    dataIndex: 'instansi_induk',
                                },
                                {
                                    title: 'Fungsi/Jabatan',
                                    dataIndex: 'jabatan',
                                },
                                {
                                    title: 'Dokumen SK',
                                    dataIndex: 'file',
                                    render: (a, row) => <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>,
                                },
                                {
                                    title: 'Gol.Ruang/Pangkat',
                                    dataIndex: 'pangkat',
                                },
                                {
                                    title: 'Status Keaktifan',
                                    dataIndex: 'status_keaktifan',
                                },
                                {
                                    title: <EllipsisOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                        <Menu.Item key={0} onClick={this.showModalForm.bind(this, row)}><EditOutlined /> Update</Menu.Item>
                                        <Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>
                                    </Menu>}>
                                        <div className="ant-dropdown-link tb-action">
                                            <Button type='primary'>Opsi</Button>
                                        </div>
                                    </Dropdown>,
                                },
                                {
                                    title: 'LogActivity',
                                    key: 'operation',
                                    width: '10%',
                                    render: (a, row) => _tableLogActivity(row),
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={table.pagination}
                            loading={table.loading}
                            onChange={(pagination) => {
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                            size="small"
                        />
                    </Col>
                </Row>

                {modalForm}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(StatusRiwayatKepegawaian);
