import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, Typography } from 'antd';
import { _success, _setAxios, _validationFormMsg, getValueDate } from '../../../../lib/Helper';

import { Date } from '../../../../components/datetime';
const { Title, Text } = Typography;

const schema = {
    tgl_sertifikat: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class DownloadCertificate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSiswa: {},
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                tgl_sertifikat: getValueDate("").toString(),
            }
        })

        this.getSiswaByID(this.props.data.siswa_id)
    }

    getSiswaByID = (siswaId) => {
        if (!siswaId) {
            return []
        }
        _setAxios("siswa/tahun-ajaran-aktif/" + siswaId, "GET", null).then(resp => {
            this.setState({
                dataSiswa: resp.data.data
            })
        })
    }


    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values, dataSiswa } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            siswa_id: this.props.data.siswa_id,
            tahun_ajaran_id: this.props.privilege.tahun_ajaran ? this.props.privilege.tahun_ajaran.id_tahun_ajaran : null,
            tgl_sertifikat: values.tgl_sertifikat ? values.tgl_sertifikat : null,
            tingkatan_kelas: dataSiswa.tingkatan_kelas ? dataSiswa.tingkatan_kelas : null,
        }

        let endpoint = "ubudiyah/penilaian/sertifikat";
        let method = "POST"

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.open(resp.data.data.link, "_blank");
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    render() {

        const { dataSiswa } = this.state
        const { data } = this.props

        return (
            <Modal
                maskClosable={false}
                title={"Download Sertifikat Ubudiyah"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <center>
                                <Title level={4}>{dataSiswa.nama}</Title>
                                <Text type="secondary">NISN: {dataSiswa.nisn}</Text><br />
                                <Text type="secondary">NIS: {dataSiswa.nis}</Text><br />
                                <Text type="secondary">Tingkatan Kelas: {dataSiswa.tingkatan_kelas}</Text>
                            </center>

                            <Form.Item label="Tanggal Sertifikat *">
                                <Date
                                    defaultValue={data.tgl_sertifikat}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_sertifikat", val)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(DownloadCertificate);
