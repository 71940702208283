import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, Typography } from 'antd';
import { _success, _setAxios, _validationFormMsg, getValueDate } from '../../../../lib/Helper';
import { SelectTenagaPendidik } from '../../../../components/select';
import { Date } from '../../../../components/datetime';

const { Title, Text } = Typography;
const schema = {
    tgl_evaluasi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    penilai_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class JadwalEvaluasi extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
                tgl_evaluasi: getValueDate(this.props.data.tgl_evaluasi),
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            gtk_id: values.id ? values.id : null,
            tahun_ajaran: values.tahun_ajaran ? values.tahun_ajaran : null,
            penilai_id: values.penilai_id ? values.penilai_id : null,
            tgl_evaluasi: values.tgl_evaluasi ? values.tgl_evaluasi : null,
        }

        let endpoint = "supervisi/penilaian-kinerja-guru/penilaian/jadwal"
        let method = "POST"

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false,
                    values: {}
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state
        const { data } = this.props

        return (
            <Modal
                maskClosable={false}
                title={"Jadwal & Penilai PKG"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <center>
                                <Title level={4}>{(data.gtk_gelar_depan ? data.gtk_gelar_depan + " " : "") + (data.gtk_nama_lengkap ? data.gtk_nama_lengkap : "-") + (data.gtk_gelar_belakang ? " " + data.gtk_gelar_belakang : "")}</Title>
                                <Text type="secondary">NUPTK: {data.nuptk ? data.nuptk : "-"}</Text><br />
                            </center>
                            <Form.Item label="Tanggal Evaluasi *">
                                <Date
                                    defaultValue={data.tgl_evaluasi}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_evaluasi", val)
                                    }} />
                            </Form.Item>

                            <Form.Item label="Nama Penilai *">
                                <SelectTenagaPendidik
                                    allowClear
                                    value={values.penilai_id ? values.penilai_id : null}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("penilai_id", val ? val : null)
                                    }}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default JadwalEvaluasi;
