import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Divider, Form, message } from 'antd';
import { _success, _setAxios, _validationFormMsg, getValueDate } from '../../../../../lib/Helper';

import { SelectAspekKategoriUbudiyah, SelectAspekPenilaianUbudiyah, SelectPredikatPenilaian } from '../../../../../components/select';
import { Date } from '../../../../../components/datetime';

const schema = {
    siswa_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_hafalan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    ubudiyah_aspek_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    ubudiyah_kategori_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    predikat_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSiswa: {},
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        const { data } = this.props

        this.getSiswaByID(data.siswa_id).then(resp => {
            let dataSiswa = resp.data.data

            this.setState({
                dataSiswa: dataSiswa,
            })
        })
        this.setState({
            values: {
                ...data,
                tgl_hafalan: getValueDate(data.tgl_hafalan).toString(),
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values, dataSiswa } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        if (!values.penilai_id){
            return message.warning("Role Anda tidak diizinkan untuk mengisi penilaian");
        }
        this.setState({
            loading: true
        });
        const params = {
            penilai_id: values.penilai_id ? values.penilai_id : null,
            siswa_id: values.siswa_id ? values.siswa_id : null,
            tingkatan_kelas: dataSiswa.tingkatan_kelas ? dataSiswa.tingkatan_kelas : null,
            tahun_ajaran_id: this.props.privilege.tahun_ajaran ? this.props.privilege.tahun_ajaran.id_tahun_ajaran : null,
            ubudiyah_aspek_id: values.ubudiyah_aspek_id ? values.ubudiyah_aspek_id : null,
            predikat_id: values.predikat_id ? values.predikat_id : null,
            tgl_hafalan: getValueDate(values.tgl_hafalan).toString(),
        }

        let endpoint = "ubudiyah/penilaian/detail"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false,
                    values: {}
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getSiswaByID = async (siswaId) => {
        if (!siswaId) {
            return []
        }
        return await _setAxios("siswa/tahun-ajaran-aktif/" + siswaId, "GET", null).then(resp => {
            return resp
        })
    }

    render() {

        const { values, dataSiswa } = this.state
        const { data } = this.props


        return (
            <Modal
                maskClosable={false}
                title={"Form Hafalan Siswa"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <div>
                                <div style={{ width: 130, fontWeight: 600, display: "inline-block" }}>NISN: </div>{dataSiswa.nisn ? dataSiswa.nisn : "-"}<br />
                                <div style={{ width: 130, fontWeight: 600, display: "inline-block" }}>NIS: </div>{dataSiswa.nis ? dataSiswa.nis : "-"}<br />
                                <div style={{ width: 130, fontWeight: 600, display: "inline-block" }}>Nama Siswa: </div>{dataSiswa.nama ? dataSiswa.nama : "-"}<br />
                                <div style={{ width: 130, fontWeight: 600, display: "inline-block" }}>Tingkatan Kelas: </div>{dataSiswa.tingkatan_kelas ? dataSiswa.tingkatan_kelas : "-"}<br />
                                <div style={{ width: 130, fontWeight: 600, display: "inline-block" }}>Penilai: </div>{data.penilai_nama ? data.penilai_nama : "-"}<br />
                                <Divider />
                            </div>

                            <Form.Item label="Tanggal Hafalan *">
                                <Date
                                    defaultValue={data.tgl_hafalan}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_hafalan", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Aspek Kategori *">
                                {dataSiswa.tingkatan_kelas ? <SelectAspekKategoriUbudiyah
                                    modul="penilaian"
                                    defaultValue={data.ubudiyah_kategori_id}
                                    value={values.ubudiyah_kategori_id}
                                    tingkatanKelas={dataSiswa.tingkatan_kelas}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("ubudiyah_kategori_id", val ? val : null)
                                        this.handleChangeSetValue("ubudiyah_aspek_id", null)
                                    }}
                                    style={{ width: '100%' }}
                                /> : "Loading..."}
                            </Form.Item>
                            <Form.Item label="Aspek Penilaian *">
                                <SelectAspekPenilaianUbudiyah
                                    modul="penilaian"
                                    tingkatanKelas={dataSiswa.tingkatan_kelas}
                                    ubudiyahKategoriID={values.ubudiyah_kategori_id}
                                    defaultValue={data.ubudiyah_kategori_id}
                                    value={values.ubudiyah_aspek_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("ubudiyah_aspek_id", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                            <Form.Item label="Predikat *">
                                <SelectPredikatPenilaian
                                    defaultValue={data.predikat_id}
                                    value={values.predikat_id}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("predikat_id", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
