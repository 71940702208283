import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

const fetch = (keyword, callback) => {
  _setAxios("rombel/dropdown", "POST", {
    "pagination": {
      "current": 1,
      "pageSize": 20,
    },
    "search": keyword ? keyword : "",
    "sorting": []
  })
    .then(body => {
      let results = []
      body.data.list.forEach(row => {

        results.push({
          value: row.id,
          label: row.nama,
        })
      });

      callback(results);
    })
};
const SearchInput = (props) => {
  return (
    <Select
      {...props}
      showSearch
      allowClear
      placeholder={
        props.placeholder ? props.placeholder : 'Pilih rombel'
      }
      style={props.style ? props.style : { width: '100%' }}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
    />
  );
};
const SelectRombel = ({ ...props }) => {
  const [data, setData] = useState([]);
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };

  useEffect(() => {
    fetch(null, setData);
  }, []);

  return <SearchInput
    {...props}
    onSearch={handleSearch}
    options={data}
  />
}

export default SelectRombel;