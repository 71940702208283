import React from 'react';
import { connect } from 'react-redux'

import { Table, Row, Col, Button, Menu, Dropdown, Pagination } from 'antd';
import { SettingOutlined, DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { getParamTable } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Nama',
                  dataIndex: 'nama_lengkap',
                  sorter: false,
                  render: (a, row) => (row.gelar_depan ? row.gelar_depan + " " : "") + (row.nama_lengkap ? row.nama_lengkap : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")
                }, {
                  title: 'Tanggal',
                  dataIndex: 'tgl',
                  sorter: false,
                }, {
                  title: 'Jam Masuk',
                  dataIndex: 'waktu_masuk',
                  sorter: false,
                  render: (a, row) => row.waktu_masuk ? row.waktu_masuk : "-",
                }, {
                  title: 'Jam Pulang',
                  dataIndex: 'waktu_pulang',
                  sorter: false,
                  render: (a, row) => row.waktu_pulang ? row.waktu_pulang : "-",
                }, {
                  title: 'Durasi Telat',
                  dataIndex: 'durasi_telat',
                  sorter: false,
                  render: (a, row) => row.durasi_telat ? row.durasi_telat : "-",
                }, {
                  title: 'Durasi Pulang Cepat',
                  dataIndex: 'durasi_pulang_cepat',
                  sorter: false,
                  render: (a, row) => row.durasi_pulang_cepat ? row.durasi_pulang_cepat : "-",
                }, {
                  title: 'Jarak Masuk',
                  dataIndex: 'radius_masuk',
                  sorter: false,
                  render: (a, row) => row.radius_masuk ? parseFloat(row.radius_masuk).toFixed(2) + " Meter" : "-",
                }, {
                  title: 'Jarak Pulang',
                  dataIndex: 'radius_pulang',
                  sorter: false,
                  render: (a, row) => row.radius_pulang ? parseFloat(row.radius_pulang).toFixed(2) + " Meter" : "-",
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => ( !action.detail ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.detail ? (<Menu.Item key={0} onClick={this.props.modalDetail.bind(this, true, row)}><InfoCircleOutlined /> Detail</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Desktop);
