import React from 'react';
import { Row, Col, Divider, List, Tag, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownCircleOutlined, DownloadOutlined, EditOutlined, InfoCircleOutlined, MessageOutlined, FormOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { _formatPhoneID } from '../../../../lib/Helper';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const statusSiswa = this.props.statusSiswa
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => {
                  row.wa_akun_status_api_wa = row.wa_akun_status_api_wa && row.wa_akun_status_api_wa.toLowerCase()
                  row.wa_lolos_status_api_wa = row.wa_lolos_status_api_wa && row.wa_lolos_status_api_wa.toLowerCase()
                  row.status = row.status && row.status.toLowerCase()
                  row.berkas_data_status = row.berkas_data_status && row.berkas_data_status.toLowerCase()
                  row.penilaian_status = row.penilaian_status && row.penilaian_status.toLowerCase()
                  row.berkas_file_status = row.berkas_file_status && row.berkas_file_status.toLowerCase()

                  return <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={row.main_path + row.foto} />}
                      title={
                        action.detail === true ?
                          (<Link target={"_blank"} to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}>{row.nama}</Link>) : row.nama
                      }
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              NISN: {row.nisn ? row.nisn : "-"} <br />
                              TTL: {row.tempat_lahir ? row.tempat_lahir + " (" + row.tgl_lahir + ")" : "-"} <br />
                              Jenis Kelamin: {row.jenis_kelamin ? row.jenis_kelamin : "-"} <br />
                              Jalur: {row.ppdb_jalur_nama ? (row.ppdb_jalur_kategori + " -" + row.ppdb_jalur_nama) : "-"} <br />
                              Tahun Ajaran: {row.ppdb_jalur_tahun_ajaran ? row.ppdb_jalur_tahun_ajaran : "-"} <br />
                              Nilai: {row.penilaian ? row.penilaian : "-"} <br />
                              Keterangan: {row.berkas_data_keterangan_verified ? row.berkas_data_keterangan_verified : "-"} <br />
                              Status Data: {row.berkas_data_status ? (row.berkas_data_status === "sudah lengkap" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"} <br />
                              Status File: {row.berkas_file_status ? (row.berkas_file_status === "sudah lengkap" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"} <br />
                              Notif Akun: {row.wa_akun_status_api_wa ? (row.wa_akun_status_api_wa === "done" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"} <br />
                              Notif Lolos: {row.wa_lolos_status_api_wa && row.penilaian_status ? (row.wa_lolos_status_api_wa === "done" && row.penilaian_status === "lolos" ? <CheckCircleOutlined style={{ fontSize: 18, color: "blue" }} /> : <CloseCircleOutlined style={{ fontSize: 18, color: "red" }} />) : "-"} <br />
                              Status: {row.status ? row.status : "-"} <br />
                            </small>
                          </Col>
                          <Col xs={24}>
                            {row.status.toLowerCase() === "lolos" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Lolos</Tag> : <Tag color="yellow" icon={<InfoCircleOutlined />}>{row.status}</Tag>}
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {<Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0}><Link target={"_blank"} to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                        <Menu.Item key={1} onClick={() => {
                          window.open('https://api.whatsapp.com/send/?phone=%2B' + _formatPhoneID(row.no_telp) + '&text=Assalamualaikum...&type=phone_number&app_absent=0')
                        }}><MessageOutlined /> Whatsapp</Menu.Item>
                        {action.update && row.wa_akun_status_api_wa === "failed" ? (<Menu.Item key={2} onClick={this.props.modalNotifAccount.bind(this, true, row)}><MessageOutlined /> Kirim Notifikasi Akun</Menu.Item>) : ""}
                        {action.update && row.wa_lolos_status_api_wa === "failed" ? (<Menu.Item key={3} onClick={this.props.modalNotifLolos.bind(this, true, row)}><MessageOutlined /> Kirim Notifikasi Lolos</Menu.Item>) : ""}
                        {action.verification && (row.status === "belum verifikasi" || row.status === "disetujui" || row.status === "ditolak") ? (<Menu.Item key={4} onClick={this.props.modalVerification.bind(this, true, row)}><CheckCircleOutlined /> Verifikasi Data & Berkas</Menu.Item>) : ""}
                        {action.update && (row.status === "disetujui" || row.status === "lolos" || row.status === "tidak lolos") ? (<Menu.Item key={5} onClick={this.props.modalInputValue.bind(this, true, row)}><EditOutlined /> Masukkan Nilai</Menu.Item>) : ""}
                        {action.update && (row.status === "lolos" || row.status === "terdaftar" || row.status === "mengundurkan diri") ? (<Menu.Item key={6} onClick={this.props.modalDaftarUlang.bind(this, true, row)}><FormOutlined /> Ubah Status Daftar Ulang</Menu.Item>) : ""}
                        {action.download && row.berkas_data_status === "sudah lengkap" ? (<Menu.Item key={7} onClick={this.props.onDownloadFormulirPendaftaran.bind(this, row)}><DownloadOutlined /> Download Formulir Pendaftaran</Menu.Item>) : ""}
                        {action.download && row.berkas_data_status === "sudah lengkap" ? (<Menu.Item key={8} onClick={this.props.onDownloadKartuUjian.bind(this, row)}><DownloadOutlined /> Download Kartu Ujian</Menu.Item>) : ""}
                        {action.delete ? (<Menu.Item key={9} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>}
                    </div>
                  </List.Item>
                }}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
