import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Upload } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InboxOutlined } from '@ant-design/icons';

import { _success, _setAxios, _getFile, _validationFormMsg, _beforeUploadImage } from '../../../../lib/Helper';
import {  satuanTransaksiOptions } from '../../../../data/options';

import "./style.css"

const { Dragger } = Upload;

const schema = {
    kategori_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    satuan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    }
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            ddl: {
                kategoriAssetLancar: []
            }
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })

        this.getKategoriAssetLancarDDL()
    }

    getKategoriAssetLancarDDL = () => {
        _setAxios("sarpras/kategori", "POST", {
            "jenis": "Asset Lancar"
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kategoriAssetLancar: resp.data.data
                    }
                });
            }
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }
    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadImage(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            loading: true
        });
        const params = {
            kategori_id: values.kategori_id ? values.kategori_id.id : null,
            nama: values.nama ? values.nama : null,
            satuan: values.satuan ? values.satuan : null,
            keterangan: values.keterangan ? values.keterangan : null,
            foto: await _getFile(this.state.fileList),
        }

        let endpoint = "sarpras/asset-lancar/master"
        let method = "POST"
        if (values.id) {
            endpoint = "sarpras/asset-lancar/master/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form asset lancar"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                // width={1024}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Autocomplete
                            options={this.state.ddl.kategoriAssetLancar}
                            value={values.kategori_id || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("kategori_id", newValue)
                            }}
                            getOptionLabel={(option) => option.nama}
                            name="kategori_id"
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Kategori *"
                                size="small"
                                error={this.hasError('kategori_id')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                helperText={
                                    this.hasError('kategori_id') ? errors.kategori_id[0] : null
                                }
                            />}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Nama Barang *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="nama"
                            onChange={(e) => {
                                this.handleChangeSetValue("nama", e.target.value)
                            }}
                            value={values.nama || null}
                            error={this.hasError('nama')}
                            helperText={
                                this.hasError('nama') ? errors.nama[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <Autocomplete
                            options={satuanTransaksiOptions}
                            value={values.satuan || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("satuan", newValue ? newValue.label : null)
                            }}

                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Satuan *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="satuan"
                                type="text"
                                error={this.hasError('satuan')}
                                helperText={
                                    this.hasError('satuan') ? errors.satuan[0] : null
                                }
                            />}
                        />
                    </Col>
                    
                    <Col xs={24}>
                        <TextField
                            multiline
                            fullWidth
                            label="Keterangan"
                            // size="small"
                            minRows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="keterangan"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("keterangan", e.target.value)
                            }}
                            value={values.keterangan || ''}
                            error={this.hasError('keterangan')}
                            helperText={
                                this.hasError('keterangan') ? errors.keterangan[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <Dragger
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            fileList={this.state.fileList}
                            onChange={this.handleChangeFile}
                            beforeUpload={this.beforeUploadFile}
                            maxCount={1}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                            <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, JPEG & PNG</p>
                            <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                        </Dragger>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
