import React from 'react';
import { Table, Row, Col, Pagination } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../lib/Helper';

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    // const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Ruangan',
                  dataIndex: 'ruangan_nama',
                  sorter: false,
                }, {
                  title: 'Lahan/Gedung',
                  render: (a, row) => <>{row.lahan_nama}<br/> {row.gedung_nama}</>,
                  sorter: false,
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  sorter: false,
                }, {
                  title: 'Baik',
                  dataIndex: 'jml_baik',
                  sorter: false,
                }, {
                  title: 'Rusak Ringan',
                  dataIndex: 'jml_rusak_ringan',
                  sorter: false,
                }, {
                  title: 'Rusak Berat',
                  dataIndex: 'jml_rusak_berat',
                  sorter: false,
                }, {
                  title: 'Foto',
                  dataIndex: 'foto',
                  sorter: false,
                  render: (a, row) => row.foto ? <a target="_blank" rel="noopener noreferrer" download={true} href={row.main_path + row.foto} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-",
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                },
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
