import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Upload } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { InboxOutlined } from '@ant-design/icons';

import { _success, _setAxios, _getFile, _validationFormMsg, _beforeUploadFile } from '../../../../lib/Helper';
import { kategoriSaranaPembelajaranOptions } from '../../../../data/options';

import "./style.css"

const { Dragger } = Upload;

const schema = {
    sarana_pembelajaran: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })

    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }
    // HANDLE FILE START --------------------------------------
    beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };
    // HANDLE FILE END --------------------------------------


    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            loading: true
        });
        const params = {
            sarana_pembelajaran: values.sarana_pembelajaran ? values.sarana_pembelajaran : null,
            deskripsi: values.deskripsi ? values.deskripsi : null,
            fungsi: values.fungsi ? values.fungsi : null,
            file: await _getFile(this.state.fileList),
        }

        let endpoint = "sarpras/sarana-pembelajaran"
        let method = "POST"
        if (values.id) {
            endpoint = "sarpras/sarana-pembelajaran/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form sarana pembelajaran"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Autocomplete
                            options={kategoriSaranaPembelajaranOptions}
                            value={values.sarana_pembelajaran || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("sarana_pembelajaran", newValue ? newValue.label : null)
                            }}

                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Sarana Pembelajaran *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="sarana_pembelajaran"
                                type="text"
                                error={this.hasError('sarana_pembelajaran')}
                                helperText={
                                    this.hasError('sarana_pembelajaran') ? errors.sarana_pembelajaran[0] : null
                                }
                            />}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            multiline
                            fullWidth
                            label="Deskripsi"
                            size="small"
                            minRows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="deskripsi"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("deskripsi", e.target.value)
                            }}
                            value={values.deskripsi || ''}
                            error={this.hasError('deskripsi')}
                            helperText={
                                this.hasError('deskripsi') ? errors.deskripsi[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Fungsi"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="fungsi"
                            onChange={(e) => {
                                this.handleChangeSetValue("fungsi", e.target.value)
                            }}
                            value={values.fungsi || null}
                            error={this.hasError('fungsi')}
                            helperText={
                                this.hasError('fungsi') ? errors.fungsi[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <Dragger
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            fileList={this.state.fileList}
                            onChange={this.handleChangeFile}
                            beforeUpload={this.beforeUploadFile}
                            maxCount={1}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                            <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, PNG & PDF</p>
                            <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                        </Dragger>
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
