import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList(kategoriId) {
  if (!kategoriId) return []

  return await _setAxios("supervisi/penilaian-kinerja-guru/berkas/jenis/dropdown", "POST", { supervisi_berkas_kategori_id: kategoriId })
    .then(body => {
      let results = []
      body.data.data.forEach(row => {
        results.push({
          label: row.nama,
          value: row.id,
        })
      });

      return results
    })
}

const SelectBerkasJenisSupervisi = ({ ...props }) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    fetchList(props.kategoriId).then((newOptions) => {
      setOptions(newOptions);
    });
  }, [props.kategoriId]);

  return (
    <Select
      {...props}
      fetchOptions={fetchList}
      placeholder="Pilih berkas jenis"
      style={{
        width: '100%',
      }}
      options={options}
    />
  );
};
export default SelectBerkasJenisSupervisi;
