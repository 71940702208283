import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button } from 'antd';
import TextField from '@mui/material/TextField';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

const schema = {
    attr2_val: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    attr3_val: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormCoordinate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {
                id: "12"
            },
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        this.getAbsensiRule()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        this.setState({
            loading: true
        });

        const params = {
            id: values.id,
            attr2_val: values.attr2_val,
            attr3_val: values.attr3_val,
        }

        let endpoint = "presensi/absensi/setting/rule"
        let method = "PUT"

        _setAxios(endpoint, method, [params]).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getAbsensiRule = () => {
        _setAxios("presensi/absensi/setting/rule", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10
            },
            "search": "",
            "sorting": []
        }).then(resp => {
            if (resp.status === true) {
                var values = {}
                for (let i = 0; i < resp.data.list.length; i++) {
                    const el = resp.data.list[i];
                    if (el.id === this.state.values.id) {
                        values = el
                    }
                }

                this.setState({
                    values: values
                });
            }
        })
    }

    render() {

        const { values, errors } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Ubah Konfigurasi Koordinat"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={12}>
                        <TextField
                            fullWidth
                            label="Latitude"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="attr2_val"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("attr2_val", e.target.value)
                            }}
                            value={values && values.attr2_val ? values.attr2_val : null}
                            error={this.hasError('attr2_val')}
                            helperText={
                                this.hasError('attr2_val') ? errors.attr2_val[0] : null
                            }
                        />
                    </Col>
                    <Col xs={12}>
                        <TextField
                            fullWidth
                            label="Longitude"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="attr3_val"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("attr3_val", e.target.value)
                            }}
                            value={values && values.attr3_val ? values.attr3_val : null}
                            error={this.hasError('attr3_val')}
                            helperText={
                                this.hasError('attr3_val') ? errors.attr3_val[0] : null
                            }
                        />
                    </Col>
                </Row>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormCoordinate);
