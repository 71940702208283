import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { tingkatRombelOptions } from '../../../data/options';

import { Link } from 'react-router-dom';
import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Input, Tooltip, Modal, Form, Select, Pagination } from 'antd';
import { InfoCircleOutlined, SearchOutlined, FilterOutlined, SortAscendingOutlined, SettingOutlined } from '@ant-design/icons';
const { Option } = Select;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama",
        label: "Nama",
    }]
}

class JadwalPelajaranKelas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
            filter: {
                visible: false,
                values: {},
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("jadwal/pelajaran", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    // FILTER START ------------------------------------------

    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------


    render() {

        const access = this.props.privilege.access["/kurikulum/jadwal"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { table } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalFilter = <Modal
            title="Filter Rombongan Belajar"
            // width={300}
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item label="Tingkatan Kelas">
                    <Select
                        showSearch
                        allowClear
                        name="tingkatan_kelas"
                        placeholder="Tingkatan Kelas"
                        onChange={(e, newValue) => {
                            this.setTempTableFilter("tingkatan_kelas", newValue ? newValue.value : null)
                        }}
                        style={{ width: '100%' }}
                    >
                        {tingkatRombelOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                    </Select>
                </Form.Item>

            </Form>
        </Modal>

        return (
            <>
                <PageHeader
                    className="site-page-header"
                    title="Jadwal Pelajaran Kelas"
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={18} md={19} >
                        <Input
                            name="search"
                            className='search-table'
                            placeholder="Search..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }
                            }}
                            suffix={
                                <Tooltip title="Cari berdasarkan nama">
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col xs={24} sm={6} md={5}>
                        <Button.Group className='ant-btn-group-fullwidth'>
                            <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Button.Group>
                    </Col>
                </Row>

                <Divider orientation="left"></Divider>

                <Row>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'No',
                                    dataIndex: 'rownum',
                                    width: '3%',
                                },
                                {
                                    title: 'Nama Rombel',
                                    dataIndex: 'rombel_nama',
                                },
                                {
                                    title: 'Tingkat',
                                    dataIndex: 'tingkatan_kelas',
                                },
                                {
                                    title: 'Wali Kelas',
                                    dataIndex: 'nama_lengkap',
                                },
                                {
                                    title: 'Nama Ruangan',
                                    dataIndex: 'ruangan_nama',
                                },
                                {
                                    title: <SettingOutlined />,
                                    align: "center",
                                    key: 'operation',
                                    width: '2%',
                                    render: (a, row) => <div className="ant-dropdown-link tb-action" style={{ textAlign: "center" }}>
                                        {action.detail === true ? (<Link to={"/kurikulum/jadwal/pelajaran/detail/" + row.id}><InfoCircleOutlined /></Link>) : ""}
                                    </div>,
                                },
                            ]}
                            rowKey={record => record.id}
                            dataSource={table.data}
                            pagination={false}
                            loading={table.loading}
                            size="small"
                        />
                    </Col>
                    <Col xs={24} className="table-pagination">
                        <Pagination
                            size="small"
                            current={table.pagination.current}
                            pageSize={table.pagination.pageSize}
                            total={table.pagination.total}
                            showTotal={() => `Total ${table.pagination.total} items`}
                            showSizeChanger
                            onChange={(current, pageSize) => {
                                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                const params = getParamTable("change", table, null, null, pagination)
                                this.fetch(params)
                            }}
                        />
                    </Col>
                </Row>

                {modalFilter}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(JadwalPelajaranKelas);
