import validate from 'validate.js';

export const schemaRombelForm = {
    
}

export const schemaAnggotaSiswa = {
    jenis_pendaftaran: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    }
}

export const schemaPembelajaran = {
    tk_id: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    jenis_tugas: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
}

export const schemaAssignGuru = {
    
}

export const schemaJadwal = {
    hari_ke: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    jam_ke: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jam ke- harus berupa agka");
            }
        },
    },
    jumlah_jam: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Jumlah jam harus berupa agka");
            }
        },
    }
}