import React from "react";
// import { CircularProgress } from "@material-ui/core";
import { Spin } from 'antd';

const Preload = () => {
    return (
        // <div
        //     style={{
        //         position: "fixed",
        //         top: 0,
        //         left: 0,
        //         width: "100vw",
        //         height: "100vh",
        //         zIndex: +9999,
        //         backgroundColor: "#FFF",
        //         display: "flex",
        //         alignItems: "center",
        //         flexDirection: "column",
        //         justifyContent: "center",
        //         marginTop: "auto",
        //         marginBottom: "auto",
        //     }}
        // >
        //     <CircularProgress/>
        // </div>
        <div className="spin-loading">
            <Spin />
        </div>
    );
};
export default Preload;