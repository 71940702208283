import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../../lib/Helper';

import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import SortingTableDesktopWithFilter from '../../../../../components/sorting-table/SortingTableDesktopWithFilter';
import SortingTableMobileWithFilter from '../../../../../components/sorting-table/SortingTableMobileWithFilter';
import SearchTable from '../../../../../components/search-table/SearchTable';
import Export from '../modal/Export';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "tgl_transaksi",
  label: "Tanggal Bayar",
}, {
  value: "tahun_ajaran",
  label: "Tahun Ajaran",
}, {
  value: "nominal_pembayaran",
  label: "Nominal Pembayaran",
}]

class LaporanPemasukan extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      filter: {
        values: {
          status: null,
        },
        visible: false,
      },
      export: {
        data: {},
        visible: false
      },
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("keuangan/laporan/pemasukan/table", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data
        let current = params.pagination.current

        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }


  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
      },
    })
  }
  // FILTER END ------------------------------------------

  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }
  render() {
    const { table } = this.state;
    const { access } = this.props;
    const { action } = access;

    return (
      <>
        <PageHeader
          className="site-page-header"
          title="Laporan Pemasukan"
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true, {})}><DownloadOutlined /> Export</Button> : null,
          ]}

        />
        <Row gutter={[16, 16]}>
          {/* SEARCH LIST/TABLE */}
          <Col xs={24} sm={18} md={19} >
            <SearchTable table={table} fetch={this.fetch} />
          </Col>

          {/* SORTING LIST/TABLE */}
          <Col xs={24} sm={6} md={5}>
            <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
          </Col>

          {/* DATA LIST/TABLE */}
          <Col xs={24}>
            <BrowserView>
              <Desktop
                access={access}
                state={this.state}
                fetch={this.fetch}
                fetchTable={this.fetchTable}
                modalForm={this.modalForm}
                modalDelete={this.modalDelete}
              />
            </BrowserView>
            <MobileView>
              <Mobile
                access={access}
                state={this.state}
                fetch={this.fetch}
                fetchTable={this.fetchTable}
                modalForm={this.modalForm}
                modalDelete={this.modalDelete}
              />
            </MobileView>
          </Col>
        </Row>
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
              values={this.state.filter.values}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(LaporanPemasukan);
