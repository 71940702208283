import React from 'react';
import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined, EditOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.uraian_kecakapan ? row.uraian_kecakapan : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Jenis Penegak: {row.jenis_penegak ? row.jenis_penegak : "-"} <br />
                              Tanggal Lulus: {row.tgl_lulus ? row.tgl_lulus : "-"} <br />
                              Pembina: {row.pembina ? row.pembina : "-"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {!action.delete && !action.update ? "-" :
                        <Dropdown trigger={['click']} overlay={<Menu>
                          {action.update ? (<Menu.Item key={1} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                          {action.delete ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                        </Menu>}>
                          <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                        </Dropdown>
                      }
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
