import React from 'react';
import { Alert, Breadcrumb, Divider, PageHeader } from 'antd';
import { isAuth, _setAxios } from '../../lib/Helper';
import { Button, Row, Col, Skeleton } from 'antd';
import { connect } from 'react-redux'
import AuthRedirect from '../../components/AuthRedirect'
import { DoubleRightOutlined } from '@ant-design/icons';

import "./Home.css"
import AbsensiCheckLog from './AbsensiCheckLog';
import Absensi from './Absensi';
class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      auth: true,
      statistik: {},
      lembaga: {},
      riwayat: {},
      loading: true,
      absensi: {
        data: {},
        visible: false
      },
    };

  }

  getDashboard = () => {
    _setAxios("dashboard/utama", "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data
        this.setState({
          statistik: data.statistik,
          lembaga: data.lembaga,
          riwayat: data.riwayat,
          loading: false
        });
      }
    })
  }

  modalAbsensi = (visible = false, data = {}) => {
    this.setState({
      absensi: {
        data: data,
        visible: visible
      },
    })
  }

  componentDidMount() {
    this.getDashboard()
  }

  render() {
    const { profile, absensi, profile_ex } = this.props.privilege
    const accessKehadiran = this.props.privilege.access["/presensi/kehadiran"]
    const actionKehadiran = accessKehadiran === undefined ? null : accessKehadiran.action

    const { statistik, lembaga, riwayat, loading } = this.state

    if (isAuth(this.props.privilege)) {
      return (
        <>
          {
            profile_ex.allow_absensi && actionKehadiran && actionKehadiran.check_log && (!absensi.data) ? <Alert
              message="Kamu belum absen loh, abasen dulu yuk.."
              type="info"
              style={{
                marginTop: 16
              }}
              showIcon
              closable
            /> : ""
          }
          <PageHeader
            style={{
              padding: 0
            }}
            onBack={() => window.history.back()}
            subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>}
          ></PageHeader>
          <div className="" style={{ minHeight: 360 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                {loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> :
                  <div className="dashboard-column dashboard-column-1">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={!profile_ex.allow_absensi ? 24 : 8} >
                        <div className='dashboard-column-1-ucapan'>Ahlan Wa Sahlan,</div>
                        <div className='dashboard-column-1-nama'>{profile.name}</div>
                        <div className='dashboard-column-1-role'>{profile.roles} - {lembaga ? lembaga.nama : "-"}</div>
                      </Col>
                      {!profile_ex.allow_absensi ? "" : <Col xs={24} sm={16} >
                        <Absensi loading={loading} data={this.props.privilege} />
                      </Col>}

                    </Row>
                  </div>
                }
              </Col>

              <Col sm={24}>
                Statistik
              </Col>

              {
                loading ? <>
                  <Col xs={24} sm={6}>
                    <Skeleton.Input active={true} className="skeleton-statistik" />
                  </Col>
                  <Col xs={24} sm={6}>
                    <Skeleton.Input active={true} className="skeleton-statistik" />
                  </Col>
                  <Col xs={24} sm={6}>
                    <Skeleton.Input active={true} className="skeleton-statistik" />
                  </Col>
                  <Col xs={24} sm={6}>
                    <Skeleton.Input active={true} className="skeleton-statistik" />
                  </Col>
                </> :
                  <>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                      <div className="dashboard-column">
                        <Row gutter={[16, 16]}>
                          <Col xs={6} sm={24} md={6}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <Button
                                shape="circle" icon={<span className="icon-kesiswaan"></span>} size="large" className="color-jumlah-siswa dashboard-column-icon" />
                            </div>
                          </Col>
                          <Col xs={18} sm={24} md={18}>
                            <Row>
                              <Col xs={24} className="dashboard-column-count">
                                {statistik.Jml_Siswa ? statistik.Jml_Siswa : "-"}
                              </Col>
                              <Col xs={24} className="dashboard-column-title">
                                Total Siswa Aktif
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                      <div className="dashboard-column">
                        <Row gutter={[16, 16]}>
                          <Col xs={6} sm={24} md={6}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <Button shape="circle" icon={<span className="icon-PTK"></span>} size="large" className="color-success dashboard-column-icon" />
                            </div>
                          </Col>
                          <Col xs={18} sm={24} md={18}>
                            <Row>
                              <Col xs={24} className="dashboard-column-count">
                                {statistik.Jml_Guru ? statistik.Jml_Guru : "-"}
                              </Col>
                              <Col xs={24} className="dashboard-column-title">
                                Total Guru Aktif
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                      <div className="dashboard-column">
                        <Row gutter={[16, 16]}>
                          <Col xs={6} sm={24} md={6}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <Button shape="circle" icon={<span className="icon-kesiswaan"></span>} size="large" className="color-success dashboard-column-icon" />
                            </div>
                          </Col>
                          <Col xs={18} sm={24} md={18}>
                            <Row>
                              <Col xs={24} className="dashboard-column-count">
                                {statistik.Jml_Tendik ? statistik.Jml_Tendik : "-"}
                              </Col>
                              <Col xs={24} className="dashboard-column-title">
                                Total Tendik Aktif
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                      <div className="dashboard-column">
                        <Row gutter={[16, 16]}>
                          <Col xs={6} sm={24} md={6}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <Button shape="circle" icon={<span className="icon-lembaga"></span>} size="large" className="color-success dashboard-column-icon" />
                            </div>
                          </Col>
                          <Col xs={18} sm={24} md={18}>
                            <Row>
                              <Col xs={24} className="dashboard-column-count">
                                {statistik.Jml_Rombel ? statistik.Jml_Rombel : "-"}
                              </Col>
                              <Col xs={24} className="dashboard-column-title">
                                Total Rombel
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                      <div className="dashboard-column">
                        <Row gutter={[16, 16]}>
                          <Col xs={6} sm={24} md={6}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <Button shape="circle" icon={<span className="icon-lembaga"></span>} size="large" className="color-success dashboard-column-icon" />
                            </div>
                          </Col>
                          <Col xs={18} sm={24} md={18}>
                            <Row>
                              <Col xs={24} className="dashboard-column-count">
                                {statistik.Jml_Ruangan ? statistik.Jml_Ruangan : "-"}
                              </Col>
                              <Col xs={24} className="dashboard-column-title">
                                Total Ruangan
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                      <div className="dashboard-column">
                        <Row gutter={[16, 16]}>
                          <Col xs={6} sm={24} md={6}>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <Button shape="circle" icon={<span className="icon-rombel"></span>} size="large" className="color-success dashboard-column-icon" />
                            </div>
                          </Col>
                          <Col xs={18} sm={24} md={18}>
                            <Row>
                              <Col xs={24} className="dashboard-column-count">
                                {statistik.Jml_Alumni ? statistik.Jml_Alumni : "-"}
                              </Col>
                              <Col xs={24} className="dashboard-column-title">
                                Total Alumni
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </>
              }

              <Col sm={24}>
                Informasi Lembaga
              </Col>
              <Col xs={24}>
                {
                  loading ? <Skeleton.Input active={true} className="skeleton-lembaga" /> :
                    <div className="dashboard-column">
                      <Row gutter={[16, 16]}>
                        <Col xs={24}>
                          <Row gutter={[16, 16]}>
                            <Col xs={24} sm={16}>
                              <Row gutter={[16, 16]}>
                                <Col xs={24} sm={4} xl={3}>
                                  <div className="man-logo"></div>
                                </Col>
                                <Col xs={24} sm={20}>
                                  <div className="informasi-lembaga-title">{lembaga ? lembaga.nama : "-"}</div>
                                  <div className="informasi-lembaga-subtitle">Jl. Raya Bungah No. 46 Kec. Bungah, Kel. Bungah, Gresik Jawa timur 61152</div>
                                  <div className="informasi-lembaga-description">{lembaga ? lembaga.website : "-"}  |  {lembaga ? lembaga.no_telp : "-"}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xs={24} sm={8}>
                              <div className="informasi-lembaga-box">
                                <div className="informasi-lembaga-box-title">Nilai</div>
                                <div className="informasi-lembaga-box-value">{riwayat.Nilai}</div>
                              </div>
                              <div className="informasi-lembaga-box">
                                <div className="informasi-lembaga-box-title">Akreditasi</div>
                                <div className="informasi-lembaga-box-value">{riwayat.Akreditasi}</div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={24}>
                          <Divider />
                        </Col>
                        <Col xs={24}>
                          <Row gutter={[16, 16]}>
                            <Col xs={12} sm={6}>
                              <div className="informasi-lembaga-identity-title">NSM</div>
                              <div>{lembaga ? lembaga.nsm : "-"}</div>
                            </Col>
                            <Col xs={12} sm={6}>
                              <div className="informasi-lembaga-identity-title">NPSN</div>
                              <div>{lembaga ? lembaga.npsn : "-"}</div>
                            </Col>
                            <Col xs={12} sm={6}>
                              <div className="informasi-lembaga-identity-title">Status Madrasah</div>
                              <div>{lembaga ? lembaga.status : "-"}</div>
                            </Col>
                            <Col xs={12} sm={6}>
                              <div className="informasi-lembaga-identity-title">Kategori Madrasah</div>
                              <div>{lembaga ? lembaga.jenis : "-"}</div>
                            </Col>
                            <Col xs={24}>
                              <Divider />
                            </Col>
                            <Col xs={12} sm={6}>
                              <div style={{
                                fontFamily: 'Work Sans',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: 16,
                                letterSpacing: 0.1,
                                color: '#000000',
                                lineHeight: "3em"
                              }}>SK Pendirian</div>
                              <Row gutter={[16, 16]}>
                                <Col xs={16}>
                                  <div style={{
                                    fontFamily: 'Work Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    letterSpacing: 0.15,
                                    color: '#9C9C9C',
                                    lineHeight: "2em"
                                  }}>SK Pendirian/SK Penegerian</div>
                                  <div>{lembaga ? lembaga.no_sk : "-"}</div>
                                </Col>
                                <Col xs={8}>
                                  <div style={{
                                    fontFamily: 'Work Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: 12,
                                    letterSpacing: 0.15,
                                    color: '#9C9C9C',
                                    lineHeight: "2em"
                                  }}>Tanggal</div>
                                  <div>31 Mei 1980</div>
                                </Col>
                                <Col xs={24}>
                                  <a href={lembaga ? lembaga.main_path + lembaga.file_sk : "#"} target="_blank" rel="noopener noreferrer" >sk_pendirian</a>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                }
              </Col>
            </Row >
          </div >

          {
            this.state.absensi.visible ?
              <AbsensiCheckLog
                data={this.state.absensi.data}
                visible={this.state.absensi.visible}
                hideModal={this.modalAbsensi.bind(this, false)}
              /> : ""
          }
        </>
      )
    } else {
      return <AuthRedirect />
    }
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setPrivilege: (data) => {
//       dispatch({ type: "AUTH", privilege: data })
//     }
//   }
// }

export default connect(mapStateToProps)(Home)
