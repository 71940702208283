import React from 'react';
import { connect } from 'react-redux'

import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined, EditOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import LabelPredikat from '../../../../../components/labels/LabelIsActive';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={`Tgl Hafalan: ${row.tgl_hafalan}`}
                      description={
                        <small>
                          Tingkat: {row.tingkatan_kelas ? row.tingkatan_kelas : "-"}<br />
                          Surat: {row.ubudiyah_aspek_nama ? row.ubudiyah_aspek_nama : "-"}<br />
                          Kategori: {row.ubudiyah_kategori_nama ? row.ubudiyah_kategori_nama : "-"}<br />
                          Predikat: <LabelPredikat predikat={row.predikat_penilaian} /><br />
                          Penilai: {row.penilai_nama ? row.penilai_nama : "-"}
                        </small>
                      }
                    />
                    <div>
                      {(!action.detail && !action.delete ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.update ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                        {action.delete ? (<Menu.Item key={1} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
