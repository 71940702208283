import React from 'react';
import { connect } from 'react-redux'

import { isAuth, _setAxios, _success, getParamTable } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'

import { Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Input, Tooltip, Breadcrumb, List, } from 'antd';
import { DownloadOutlined, InfoCircleOutlined, SearchOutlined, SortAscendingOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "siswa_nama",
        label: "Nama Siswa",
    }]
}

class LaporanDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},

            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [{
                    field: "siswa_nama",
                    sort: "ASC",
                    urutan: 1
                }],
                filtering: {
                    tipe: null,
                    tgl_awal: null,
                    tgl_akhir: null,
                    rombel_id: this.props.match.params.rombel_id
                },
            },
            dataDetail: {
                data1: [
                    {
                        title: "Nama Rombel",
                        description: "-",
                    },
                ],
            },
        };
    }

    componentDidMount() {
        this.getDetail()
        this.fetchTable()
    }

    getDetail = () => {
        _setAxios("rombel/" + this.props.match.params.rombel_id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    dataDetail: {
                        data1: [
                            {
                                title: "Nama Rombel",
                                description: data.nama ? data.nama : "-",
                            }
                        ],
                    }
                })
            }
        })
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        const query = new URLSearchParams(this.props.location.search);
        this.setState({
            table: {
                ...params,
                loading: true,
                filtering: {
                    ...this.state.table.filtering,
                    tipe: query.get('tipe') === "undefined" ? null : query.get('tipe'),
                    tgl_awal: query.get('tgl_awal') === "undefined" ? null : query.get('tgl_awal'),
                    tgl_akhir: query.get('tgl_akhir') === "undefined" ? null : query.get('tgl_akhir'),
                }
            }
        });

        params = {
            ...params,
            filtering: {
                ...params.filtering,
                tipe: query.get('tipe') === "undefined" ? null : query.get('tipe'),
                tgl_awal: query.get('tgl_awal') === "undefined" ? null : query.get('tgl_awal'),
                tgl_akhir: query.get('tgl_akhir') === "undefined" ? null : query.get('tgl_akhir'),
            }
        }

        _setAxios("konsultasi/table-laporan-by-rombel", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        this.setState({
            values: values,
        });
    }

    onExport = () => {
        const query = new URLSearchParams(this.props.location.search);
        _setAxios("konsultasi/export-laporan-by-rombel", "POST", {
            rombel_id: this.props.match.params.rombel_id,
            tipe: query.get('tipe') === "undefined" ? null : query.get('tipe'),
            tgl_awal: query.get('tgl_awal') === "undefined" ? null : query.get('tgl_awal'),
            tgl_akhir: query.get('tgl_akhir') === "undefined" ? null : query.get('tgl_akhir'),
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/export';
                }, 1000);
            }
        })
    }

    render() {
        const access = this.props.privilege.access["/bimbingan-konseling/laporan"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.detail === false) {
            return <AuthRedirect />
        }

        const { table, dataDetail } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Bimbingan Konseling</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/bimbingan-konseling/laporan`}>Laporan Konseling</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={[
                        action.export ? <Button onClick={this.onExport}><DownloadOutlined /> Export</Button> : null
                    ]}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Laporan Konseling Detail"
                        subTitle="Data laporan konseling detail"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={18}>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataDetail.data1}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                        <Col xs={24} sm={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={(e) => {
                                    const params = getParamTable("search", table, e.target.value)
                                    this.fetch(params)
                                }}
                                value={table.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={3}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Nama Siswa',
                                        dataIndex: 'siswa_nama',
                                    },
                                    {
                                        title: 'Pribadi',
                                        dataIndex: 'pribadi',
                                    },
                                    {
                                        title: 'Sosial',
                                        dataIndex: 'sosial',
                                    },
                                    {
                                        title: 'Belajar',
                                        dataIndex: 'belajar',
                                    },
                                    {
                                        title: 'Karier',
                                        dataIndex: 'karier',
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={table.pagination}
                                loading={table.loading}
                                onChange={(pagination) => {
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LaporanDetail);
