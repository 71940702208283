import validate from 'validate.js';


export const schemaBerkas = {
  berkas_tipe: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  berkas_nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
};


export const schemaAyahMasihHidup = {
  ayah_nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    // format: {
    //   pattern: /^$|[a-zA-Z'-\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  ayah_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ayah_nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    // length: {
    //   minimum: 16,
    //   maximum: 16,
    //   message: "jumlah karakter harus 16 digit"
    // },
  },
  ayah_no_whatsapp: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  ayah_pendidikan: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ayah_pekerjaan: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ayah_penghasilan: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
}

export const schemaAyahTidakDiketahui = {
  // ayah_nama: {
  //   format: {
  //     pattern: /^$|[a-zA-Z'-\s]+/,
  //     message: function () {
  //       return validate.format("^Nama harus berupa huruf");
  //     }
  //   },
  // },
  ayah_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ayah_nik: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
  },
  ayah_no_whatsapp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
}

export const schemaAyahSudahMeninggal = {
  ayah_nama: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
    // format: {
    //   pattern: /^$|[a-zA-Z'-\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  ayah_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ayah_nik: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
  },
  ayah_no_whatsapp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
}


export const schemaIbuMasihHidup = {
  ibu_nama: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
    // format: {
    //   pattern: /^$|[a-zA-Z'-\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  ibu_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ibu_nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  ibu_no_whatsapp: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  ibu_pendidikan: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ibu_pekerjaan: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ibu_penghasilan: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
}

export const schemaIbuSudahMeninggal = {
  ibu_nama: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
    // format: {
    //   pattern: /^$|[a-zA-Z'-\s]+/,
    //   message: function () {
    //     return validate.format("^Nama harus berupa huruf");
    //   }
    // },
  },
  ibu_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ibu_nik: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
  },
  ibu_no_whatsapp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
}

export const schemaIbuTidakDiketahui = {
  // ibu_nama: {
  //   format: {
  //     pattern: /^$|[a-zA-Z'-\s]+/,
  //     message: function () {
  //       return validate.format("^Nama harus berupa huruf");
  //     }
  //   },
  // },
  ibu_status: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  ibu_nik: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
  },
  ibu_no_whatsapp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
}

export const schemaDataDiri = {
  nama: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    length: {
      maximum: 200
    },
    // format: {
    //   pattern: /^$|[a-zA-Z'-\s]+/,
    //   message: function () {
    //     return validate.format("^Nama peserta didik harus berupa huruf");
    //   }
    // },
  },
  nisn: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nisn harus berupa agka");
      }
    },
    length: {
      minimum: 10,
      maximum: 10,
      message: "jumlah karakter harus 10 digit"
    },
  },
  nis: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nis harus berupa agka");
      }
    },
    length: {
      minimum: 18,
      maximum: 18,
      message: "jumlah karakter harus 18 digit"
    },
  },
  nik: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Nik harus berupa agka");
      }
    },
    length: {
      minimum: 16,
      maximum: 16,
      message: "jumlah karakter harus 16 digit"
    },
  },
  jml_saudara: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Jumlah saudara harus berupa agka");
      }
    },
  },
  anak_ke: {
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Anak ke berapa, harus berupa agka");
      }
    },
  },
  tgl_lahir: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  no_telp: {
    length: {
      maximum: 14,
      message: "terlalu panjang (maksimal 14 karakter)"
    },
    format: {
      pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
      message: function (value) {
        return validate.format("^No telp %{phone} tidak valid", {
          phone: value
        });
      }
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'harus diisi' },
    format: {
      pattern: /^$|\S+@\S+\.\S+/,
      message: function (value) {
        return validate.format("^Email %{email} tidak valid", {
          email: value
        });
      }
    },
  },
  kode_pos: {
    length: {
      maximum: 6,
      message: "terlalu panjang (maksimal 6 karakter)"
    },
    format: {
      pattern: /^$|[0-9]+/,
      message: function () {
        return validate.format("^Kode pos harus berupa agka");
      }
    },
  },
  lat: {
    format: {
      pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
      message: function () {
        return validate.format("^Latitude belum sesuai");
      }
    },
  },
  long: {
    format: {
      pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
      message: function () {
        return validate.format("^Longitude belum sesuai");
      }
    },
  },
  ppdb_jalur_id: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  sekolah_asal: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  referensi_ppdb: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },

};

export const schemaActivitas = {
  jenis_pendaftaran: {
    presence: { allowEmpty: false, message: 'harus dipilih' },
  },
  tgl_masuk: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
  sekolah_asal: {
    presence: { allowEmpty: false, message: 'harus diisi' },
  },
};