import React from 'react';
import { isMobile } from 'react-device-detect';
import { Divider, Radio, Space } from 'antd';

import { getParamTable } from '../../lib/Helper';

const sorts = [{
  value: "ASC",
  label: "Ascending",
}, {
  value: "DESC",
  label: "Descending",
}]

class SortingTable extends React.Component {

  render() {
    const { table, sortFields } = this.props
    return (
      <>
        <Radio.Group onChange={(e) => {
          const params = getParamTable("sort_field", table, e.target.value)
          this.props.fetch(params, isMobile ? true : false)
        }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
          <Space direction="vertical">
            {sortFields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
          </Space>
        </Radio.Group>
        <Divider orientation="left"></Divider>
        <Radio.Group onChange={(e) => {
          const params = getParamTable("sort", table, e.target.value)
          this.props.fetch(params, isMobile ? true : false)
        }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
          <Space direction="vertical">
            {sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
          </Space>
        </Radio.Group>
      </>
    )
  }
}

export default SortingTable
