import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Input, DatePicker } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import { SelectPosPengeluaran } from '../../../../components/select';
import SelectTenagaPendidik from '../../../../components/select/SelectTenagaPendidik';
import { UploadFile } from '../../../../components/upload-files';
import "./style.css"

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const dateNow = dayjs().format(dateFormat);

const { TextArea } = Input;

const schema = {
    tgl_transaksi: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pos_pengeluaran_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tk_id: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {
            },
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
                tgl_transaksi: this.props.data.tgl_transaksi ? dayjs(this.props.data.tgl_transaksi, dateFormat) : dayjs(dateNow, dateFormat),
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });
        const params = {
            ...this.state.values,
            tgl_transaksi: dayjs(this.state.values.tgl_transaksi).format(dateFormat),
        }

        let endpoint = "keuangan/pengeluaran/master"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        const { values } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form Keuangan Pengeluaran"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Tanggal *">
                                <DatePicker
                                    defaultValue={this.props.data.tgl ? dayjs(this.props.data.tgl, dateFormat) : (dateNow ? dayjs(dateNow, dateFormat) : null)}
                                    format={dateFormat}
                                    style={{ width: "100%" }}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("tgl_transaksi", val)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Pos Pengeluaran *">
                                <SelectPosPengeluaran
                                    value={values?.pos_pengeluaran_id ? parseInt(values.pos_pengeluaran_id) : null}
                                    onChange={(val, row) => {
                                        this.handleChangeSetValue("pos_pengeluaran_id", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Penanggung Jawab *">
                                <SelectTenagaPendidik
                                    value={values?.tk_id ? values.tk_id : null}
                                    allowClear
                                    onChange={(val) => {
                                        this.handleChangeSetValue("tk_id", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Bukti Kwitansi">
                                <UploadFile
                                    isValidation={true}
                                    type="image"
                                    onChange={(val) => {
                                        this.handleChangeSetValue("bukti_pembayaran", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="Keterangan"
                                    defaultValue={this.props.data.keterangan}
                                    value={this.state.values.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
