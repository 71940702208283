import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Input, Divider, Form, Select } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import { agamaOptions, jenisPenegakOptions } from '../../../../data/options';

const { Option } = Select;
const { TextArea } = Input;

const schema = {
    jenis_penegak: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    agama: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    uraian_kecakapan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            jenis_penegak: values.jenis_penegak ? values.jenis_penegak : null,
            agama: values.agama ? values.agama : null,
            uraian_kecakapan: values.uraian_kecakapan ? values.uraian_kecakapan : null,
        }

        let endpoint = "kaldik-kegiatan/pramuka/master-sku"
        let method = "POST"
        if (values.id) {
            endpoint = "kaldik-kegiatan/pramuka/master-sku/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        return (
            <Modal
                maskClosable={false}
                title={"Form Kegiatan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Jenis Penegak *">
                                <Select
                                    allowClear
                                    name="jenis_penegak"
                                    placeholder="Jenis penegak"
                                    defaultValue={this.props.data.jenis_penegak}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("jenis_penegak", val.value)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenisPenegakOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Agama *">
                                <Select
                                    allowClear
                                    name="agama"
                                    placeholder="Agama"
                                    defaultValue={this.props.data.agama}
                                    onChange={(e, val) => {
                                        this.handleChangeSetValue("agama", val.value)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {agamaOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="Uraian Kecakapan">
                                <TextArea
                                    rows={4}
                                    placeholder="Uraian kecakapan"
                                    defaultValue={this.props.data.uraian_kecakapan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("uraian_kecakapan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
