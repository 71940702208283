import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Input, Divider, Form} from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

const schema = {
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            nama: values.nama ? values.nama : null,
            nomor: values.nomor ? values.nomor : null,
            jumlah_ayat: values.jumlah_ayat ? values.jumlah_ayat : null,
            tempat_turun: values.tempat_turun ? values.tempat_turun : null,
        }

        let endpoint = "ubudiyah/aspek/kategori"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form Surat"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Nama Kategori *">
                                <Input
                                    style={{ width: '100%' }}
                                    name="nama"
                                    placeholder="Nama"
                                    defaultValue={values.nama}
                                    value={values.nama || null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nama", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
