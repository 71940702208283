import React from 'react';
import { Button, Row, Col, Divider, Upload } from 'antd';
import { SaveOutlined, InboxOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import { _beforeUploadFile, _getFile, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import { alumniCategoryOptions, alumniPendidikanOptions, pekerjaanOptions } from '../../../../data/options';
import PreloadContent from '../../../../components/preload/PreloadContent'
import { validate } from 'validate.js';

const { Dragger } = Upload;
const schema = {
    tahun_masuk: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tahun_lulus: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    no_telp: {
        length: {
            maximum: 14,
            message: "terlalu panjang (maksimal 14 karakter)"
        },
        format: {
            pattern: /^$|(\+62 ((\d{3}([ -]\d{3,})([- ]\d{4,})?)|(\d+)))|(\(\d+\) \d+)|\d{3}( \d+)+|(\d+[ -]\d+)|\d+/,
            message: function (value) {
                return validate.format("^No telp %{phone} tidak valid", {
                    phone: value
                });
            }
        },
    },
    kategori: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pendidikan_terakhir: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    pekerjaan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    }
};

class TracingForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUpdate: false,
            loading: false,
            loadingBtn: false,
            values: {},
            errors: {},
            ddl: {
                regions: [],
                villages: [],
                regions_pekerjaan: [],
                villages_pekerjaan: [],
            },
            fileList: [],
            visible: false,
            previewImage: null,
        }
    }

    componentDidMount() {
        this.getDataTracingByID(this.props.match.params.id)
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    getDataTracingByID = (id) => {
        this.setState({
            loading: true,
        })
        _setAxios("alumni/tracing/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data

                this.setState({
                    loading: false,
                    values: data
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    getRegionDDL = (search = "", type = "") => {
        _setAxios("region", "POST", {
            pagination: {
                current: 1,
                pageSize: 10
            },
            search: search
        }).then(resp => {
            if (resp.status === true) {
                if (!type) {
                    this.setState({
                        ddl: {
                            ...this.state.ddl,
                            regions: resp.data.list
                        }
                    });
                } else {
                    this.setState({
                        ddl: {
                            ...this.state.ddl,
                            regions_pekerjaan: resp.data.list
                        }
                    });
                }
            }
        })
    }

    getVillageDDL = (districtID, type = "") => {
        _setAxios("region/" + districtID, "POST", {
            pagination: {
                current: 1,
                pageSize: 30
            },
        }).then(resp => {
            if (resp.status === true) {
                if (!type) {
                    this.setState({
                        ddl: {
                            ...this.state.ddl,
                            villages: resp.data.list
                        }
                    });
                } else {
                    this.setState({
                        ddl: {
                            ...this.state.ddl,
                            villages_pekerjaan: resp.data.list
                        }
                    });
                }
            }
        })
    }

    handleChangeFile = ({ fileList }) => {
        if (this.state.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                fileList: fileList
            })
        } else {
            this.setState({
                fileList: null
            })
        }
    };

    beforeUploadFile = (file) => {
        if (!_beforeUploadFile(file)) {
            this.setState({
                validateFile: false,
            })
            return false
        } else {
            this.setState({
                validateFile: true,
            })
        }
        return true
    };

    onSubmit = async () => {
        this.setState({
            loadingBtn: true
        })

        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        _setAxios("alumni/tracing/" + values.id, "PUT", {
            tahun_masuk: values.tahun_masuk && values.tahun_masuk instanceof Date ? values.tahun_masuk.getFullYear() : values.tahun_masuk,
            tahun_lulus: values.tahun_lulus && values.tahun_lulus instanceof Date ? values.tahun_lulus.getFullYear() : values.tahun_lulus,
            file_ijazah: await _getFile(this.state.fileList),
            kategori: values.kategori ? values.kategori : null,
            pendidikan_terakhir: values.pendidikan_terakhir ? values.pendidikan_terakhir : null,
            pendidikan_instansi: values.pendidikan_instansi ? values.pendidikan_instansi : null,
            pekerjaan: values.pekerjaan ? values.pekerjaan : null,
            pekerjaan_instansi: values.pekerjaan_instansi ? values.pekerjaan_instansi : null,
            alamat_instansi: values.alamat_instansi ? values.alamat_instansi : null,
            no_telp: values.no_telp ? values.no_telp : null,
            alamat_sekarang: values.alamat_sekarang ? values.alamat_sekarang : null,
        }).then(resp => {
            if (resp.status === true) {
                this.props.onCancel()
            } else {
                this.setState({
                    loadingBtn: false
                })
            }
        })
    }

    render() {
        const { values, errors, loading, loadingBtn } = this.state
        if (loading) {
            return <PreloadContent />
        }
        return (
            <Row gutter={[24, 24]}>
                <Col xs={24} sm={8}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Divider orientation="left">STATUS KELULUSAN</Divider>
                        </Col>
                        <Col xs={24} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    views={['year']}
                                    label="Tahun Masuk *"
                                    value={values.tahun_masuk || ""}
                                    onChange={(newValue) => {
                                        this.handleChangeSetValue("tahun_masuk", newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                        size="small"
                                        error={this.hasError('tahun_masuk')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText={
                                            this.hasError('tahun_masuk') ? errors.tahun_masuk[0] : null
                                        }
                                    />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    views={['year']}
                                    label="Tahun Lulus *"
                                    value={values.tahun_lulus || ""}
                                    onChange={(newValue) => {
                                        this.handleChangeSetValue("tahun_lulus", newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        fullWidth
                                        size="small"
                                        error={this.hasError('tahun_lulus')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText={
                                            this.hasError('tahun_lulus') ? errors.tahun_lulus[0] : null
                                        }
                                    />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={24}>
                            <Dragger
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                maxCount={1}
                                fileList={this.state.fileList}
                                onChange={this.handleChangeFile}
                                beforeUpload={this.beforeUploadFile}
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klik atau seret <b>file ijazah</b> ke area ini</p>
                                <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: JPG, JPEG, PNG & PDF</p>
                                <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                            </Dragger>
                            <br />
                            <br />
                        </Col>
                        <Col xs={24}>
                            <Divider orientation="left">KONTAK YANG DAPAT DIHUBUNGI</Divider>
                        </Col>
                        <Col xs={24}>
                            <TextField
                                fullWidth
                                label="No. Telepon *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="no_telp"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeSetValue("no_telp", e.target.value)
                                }}
                                value={values.no_telp || ''}
                                error={this.hasError('no_telp')}
                                helperText={
                                    this.hasError('no_telp') ? errors.no_telp[0] : null
                                }
                            />
                        </Col>
                        {/* <Col xs={24}>
                            <Autocomplete
                                fullWidth
                                options={ddl.regions}
                                value={values.region || null}
                                getOptionLabel={(option) =>
                                    option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                                }
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("region", newValue)
                                    if (newValue.kecamatan_id) {
                                        this.getVillageDDL(newValue.kecamatan_id)
                                    }

                                }}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Provinsi, Kota & Kecamatan"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="region"
                                    type="text"
                                    onChange={e => {
                                        const { value } = e.target;
                                        this.getRegionDDL(value)
                                    }}
                                    error={this.hasError('region')}
                                    helperText={
                                        this.hasError('region') ? errors.region[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                fullWidth
                                options={ddl.villages}
                                value={values.village || null}
                                getOptionLabel={(option) =>
                                    option.nama
                                }
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("village", newValue)
                                }}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Kelurahan / Desa"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="village"
                                    type="text"
                                    error={this.hasError('village')}
                                    helperText={
                                        this.hasError('village') ? errors.village[0] : null
                                    }
                                />}
                            />
                        </Col> */}
                        <Col xs={24} sm={24}>
                            <TextField
                                multiline
                                fullWidth
                                label="Alamat"
                                // size="small"
                                minRows={3}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="alamat_sekarang"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeSetValue("alamat_sekarang", e.target.value)
                                }}
                                value={values.alamat_sekarang || ''}
                                error={this.hasError('alamat_sekarang')}
                                helperText={
                                    this.hasError('alamat_sekarang') ? errors.alamat_sekarang[0] : null
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={16}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Divider orientation="left">TRACING</Divider>
                        </Col>
                        <Col xs={24}>
                            <Autocomplete
                                options={alumniCategoryOptions}
                                value={values.kategori || ""}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("kategori", newValue)
                                }}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Kategori *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="kategori"
                                    type="text"
                                    error={this.hasError('kategori')}
                                    helperText={
                                        this.hasError('kategori') ? errors.kategori[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Autocomplete
                                options={alumniPendidikanOptions}
                                value={values.pendidikan_terakhir || ""}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("pendidikan_terakhir", newValue)
                                }}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Pendidikan Terakhir"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="pendidikan_terakhir"
                                    type="text"
                                    error={this.hasError('pendidikan_terakhir')}
                                    helperText={
                                        this.hasError('pendidikan_terakhir') ? errors.pendidikan_terakhir[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <TextField
                                fullWidth
                                label="Nama Instansi Pendidikan"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="pendidikan_instansi"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeSetValue("pendidikan_instansi", e.target.value)
                                }}
                                value={values.pendidikan_instansi || ''}
                                error={this.hasError('pendidikan_instansi')}
                                helperText={
                                    this.hasError('pendidikan_instansi') ? errors.pendidikan_instansi[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <Autocomplete
                                options={pekerjaanOptions}
                                value={values.pekerjaan || ""}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("pekerjaan", newValue)
                                }}
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Pekerjaan *"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="pekerjaan"
                                    type="text"
                                    error={this.hasError('pekerjaan')}
                                    helperText={
                                        this.hasError('pekerjaan') ? errors.pekerjaan[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col xs={24} sm={12}>
                            <TextField
                                fullWidth
                                label="Nama Instansi/Perusahaan"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="pekerjaan_instansi"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeSetValue("pekerjaan_instansi", e.target.value)
                                }}
                                value={values.pekerjaan_instansi || ''}
                                error={this.hasError('pekerjaan_instansi')}
                                helperText={
                                    this.hasError('pekerjaan_instansi') ? errors.pekerjaan_instansi[0] : null
                                }
                            />
                        </Col>
                        <Col xs={24}>
                            <TextField
                                multiline
                                fullWidth
                                label="Alamat Instansi/Perusahaan"
                                minRows={3}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="alamat_instansi"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeSetValue("alamat_instansi", e.target.value)
                                }}
                                value={values.alamat_instansi || ''}
                                error={this.hasError('alamat_instansi')}
                                helperText={
                                    this.hasError('alamat_instansi') ? errors.alamat_instansi[0] : null
                                }
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} style={{ textAlign: "right" }}>
                    <Button onClick={this.props.onCancel} >Batal</Button> &nbsp;
                    <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmit} style={{ float: "right" }}>Simpan</Button>
                </Col>
            </Row>
        )
    }
}


export default TracingForm;