import React from 'react';
import { connect } from 'react-redux'
import { Tabs, message, Row, Col } from 'antd';
import DataPelanggaran from './DataPelanggaran';
import PengaturanPelanggaran from './PengaturanPelanggaran';

const { TabPane } = Tabs;

class Pelanggaran extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tabIdx: "0",
        };
    }

    componentDidMount() {

    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Data Rombel wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.setState({
            tabIdx: key
        })
    }

    render() {
        const steps = [
            {
                title: 'Data Pelanggaran',
                content: <DataPelanggaran action={this.props.action} {...this.props} />,
            },
            {
                title: 'Pengaturan Tindakan Pelanggaran',
                content: <PengaturanPelanggaran action={this.props.action} {...this.props} />,
            }
        ];

        return (
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                        {steps.map((item, i) => (
                            <TabPane key={i} tab={item.title}>
                                {steps[i].content}
                            </TabPane>
                        ))}
                    </Tabs>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Pelanggaran);
