import React from 'react';
import { connect } from 'react-redux'
import { Breadcrumb, Table, Row, Col, Divider, Popover, Modal, Button, Radio, PageHeader, Space, Menu, Dropdown, Image, Upload, message, Input, Tooltip, List } from 'antd';
import { InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, SortAscendingOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, SettingOutlined, DownCircleOutlined } from '@ant-design/icons';
import validate from 'validate.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ImgCrop from 'antd-img-crop';
import { Link } from 'react-router-dom';
import { noImage } from '../../../data/image';

import { isAuth, _success, _validationFormMsg, _setAxios, _tableLogActivity, _getBase64, _beforeUploadImage, _getBase64Upload } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import PreloadContent from '../../../components/preload/PreloadContent'

const schema = {
    kategori_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    unit_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jml_baik: {
        numericality: {
            message: "harus angka"
        }
    },
    jml_rusak_ringan: {
        numericality: {
            message: "harus angka"
        }
    },
    jml_rusak_berat: {
        numericality: {
            message: "harus angka"
        }
    }
};

class RuanganDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tableOption: {
                sortField: "id",
                sortValue: "DESC",
            },
            dataDetail: {
                loading: true,
                data1: [],
                data2: []
            },
            data: [],
            search: "",
            pagination: {
                current: 1,
                pageSize: 30,
            },
            loading: false,
            visibleModalCreate: false,
            id: 0,
            values: {},
            errors: {},
            create: {
                visible: false,
                loading: false,
            },
            update: {
                visible: false,
                loading: false,
            },
            delete: {
                visible: false,
                loading: false,
            },
            ddl: {
                unitRuangan: [],
                kategoriAssetTetap: [],
                sumber: [],
            },
            previewImage: '',
            previewTitle: '',
            fileList: [],
            visible: false,
            validateImage: false,
        };
    }

    componentDidMount() {
        this.getDetailRuangan(this.props.match.params.id)
        this.fetchTable()
        this.getKategoriAssetTetapDDL()
    }

    fetchTable = () => {
        var params = {
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);
    }


    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
            search: this.state.search,
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSearch = (e) => {
        const { value } = e.target;

        this.setState({
            search: value,
        });

        var params = {
            search: value,
            pagination: { ...this.state.pagination, current: 1 },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        }
        this.fetch(params);

    };

    handleTableFilter = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortField: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: e.target.value,
                sort: this.state.tableOption.sortValue,
                urutan: 1,
            }]
        });
    };

    handleTableSort = e => {
        this.setState({
            tableOption: {
                ...this.state.tableOption,
                sortValue: e.target.value
            },
        });

        this.fetch({
            search: this.state.search,
            pagination: { ...this.state.pagination },
            sorting: [{
                field: this.state.tableOption.sortField,
                sort: e.target.value,
                urutan: 1,
            }]
        });
    };

    fetch = async (params = {}) => {
        this.setState({ loading: true });
        _setAxios("ruangan-detail/table/" + this.props.match.params.id, "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    loading: false,
                    data: resp.data.list,
                    pagination: {
                        ...params.pagination,
                        total: resp.data.info.total,
                    },
                });
            } else {
                this.setState({ loading: false });
            }
        })
    };

    getKategoriAssetTetapDDL = () => {
        _setAxios("sarpras/kategori", "POST", {
            "jenis": "Asset Tetap"
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        kategoriAssetTetap: resp.data.data
                    }
                });
            }
        })
    }
    getUnitRuanganDDL = (kategori_id) => {
        _setAxios("sarpras/unit/" + kategori_id, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        unitRuangan: resp.data.data
                    }
                });
            }
        })
    }
    getSumberAssetTetapDDL = (unit_id) => {
        _setAxios("sarpras/sumber/" + unit_id, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        sumber: resp.data.data
                    }
                });
            }
        })
    }

    getDetailRuangan = (id) => {
        _setAxios("ruangan/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setState({
                    dataDetail: {
                        loading: false,
                        data1: [
                            {
                                title: "Nama Gedung",
                                description: data.gedung_nama ? data.gedung_nama : "-",
                            },
                            {
                                title: "Jenis Ruangan",
                                description: data.jenis_ruangan_nama ? data.jenis_ruangan_nama : "-",
                            },
                            {
                                title: "Nama Ruangan",
                                description: data.nama ? data.nama : "-",
                            },
                            {
                                title: "Tahun Dibangun",
                                description: data.thn_dibangun ? data.thn_dibangun : "-",
                            },
                            {
                                title: "Penggunaan",
                                description: data.penggunaan ? data.penggunaan : "-",
                            },
                        ],
                        data2: [
                            {
                                title: "Kondisi Ruangan",
                                description: data.kondisi ? data.kondisi : "-",
                            },
                            {
                                title: "Panjang (m2)",
                                description: data.ukuran_panjang ? data.ukuran_panjang : "-",
                            },
                            {
                                title: "Lebar (m2)",
                                description: data.ukuran_lebar ? data.ukuran_lebar : "-",
                            },
                            {
                                title: null,
                                description: !data.foto ? "-" :
                                    <Image.PreviewGroup>
                                        <Image style={{
                                            width: 200,
                                            // minWidth: 100,
                                            // maxWidth: "100%",
                                        }} src={data.main_path + data.foto} onError={noImage} />
                                    </Image.PreviewGroup>,
                            },
                        ],
                    }
                });
            } else {
                this.setState({
                    dataDetail: {
                        loading: false,
                    }
                })
            }
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    handleChangeSetValue = (name, value) => {
        this.setForm(name, value)
    };


    setForm = (name, value, row = {}) => {
        const { values } = this.state;
        values[name] = value;
        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    resetForm = () => {
        this.setState({
            values: {},
            errors: {},
        });
    };


    // CREATE START ------------------------------------------
    showModalCreate = () => {
        this.setState({
            create: {
                ...this.state.create,
                visible: true,
            },

        });
    };

    hideModalCreate = () => {
        this.setState({
            create: {
                visible: false,
                loading: false,
            },
        });
        this.resetForm()
    };

    createSubmit = async (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        var params = {}
        params.ruangan_id = this.props.match.params.id
        params.kategori_id = values.kategori_id ? values.kategori_id.id : null
        params.unit_id = values.unit_id ? values.unit_id.id : null
        params.sumber_id = values.sumber_id ? values.sumber_id.id : null
        params.keterangan = values.keterangan ? values.keterangan : null
        params.jml_baik = values.jml_baik ? values.jml_baik : 0
        params.jml_rusak_ringan = values.jml_rusak_ringan ? values.jml_rusak_ringan : 0
        params.jml_rusak_berat = values.jml_rusak_berat ? values.jml_rusak_berat : 0

        let fileList = this.state.fileList
        let foto = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                foto = await _getBase64(fileList[0].originFileObj)
                foto = _getBase64Upload(foto)
            }
        }
        params.foto = foto

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        this.setState({
            create: {
                ...this.state.create,
                loading: true
            }
        });
        _setAxios("ruangan-detail/" + this.props.match.params.id, "POST", params).then(resp => {
            if (resp.status === true) {
                this.hideModalCreate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    create: {
                        ...this.state.create,
                        loading: false
                    }
                });
            }
        })
    };

    // CREATE END ------------------------------------------

    // UPDATE START ------------------------------------------
    showModalUpdate = (row) => {
        this.getKategoriAssetTetapDDL()
        this.getUnitRuanganDDL(row.kategori_id)
        this.getSumberAssetTetapDDL(row.unit_id)


        this.setState({
            update: {
                visible: true,
                loading: false
            },

            id: row.id,
            values: {
                nama: row.nama,
                unit_id: {
                    id: row.unit_id,
                    nama: row.unit_nama
                },
                kategori_id: {
                    id: row.kategori_id,
                    nama: row.kategori_nama,
                    is_sumber: row.sumber_id ? "1" : "0"
                },
                sumber_id: {
                    id: row.sumber_id,
                    nama: row.sumber_nama
                },
                thn_dibangun: row.thn_dibangun,
                keterangan: row.keterangan,
                jml_baik: row.jml_baik,
                jml_rusak_ringan: row.jml_rusak_ringan,
                jml_rusak_berat: row.jml_rusak_berat,
            },
            fileList: row.foto ? [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: row.main_path + row.foto
                },
            ] : [],
        });
    };

    hideModalUpdate = () => {
        this.setState({
            update: {
                visible: false,
                loading: false
            }
        });
        this.resetForm()
    };

    updateSubmit = async (e) => {
        e.preventDefault();
        const { values } = this.state;
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        var params = {}
        params.kategori_id = values.kategori_id ? values.kategori_id.id : null
        params.unit_id = values.unit_id ? values.unit_id.id : null
        params.sumber_id = values.sumber_id ? values.sumber_id.id : null
        params.keterangan = values.keterangan ? values.keterangan : null
        params.jml_baik = values.jml_baik ? values.jml_baik : 0
        params.jml_rusak_ringan = values.jml_rusak_ringan ? values.jml_rusak_ringan : 0
        params.jml_rusak_berat = values.jml_rusak_berat ? values.jml_rusak_berat : 0

        let fileList = this.state.fileList
        let foto = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                foto = await _getBase64(fileList[0].originFileObj)
                foto = _getBase64Upload(foto)
            }
        }
        params.foto = foto

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        this.setState({
            update: {
                ...this.state.update,
                loading: true
            }
        });

        _setAxios("ruangan-detail/" + this.state.id, "PUT", params).then(resp => {
            if (resp.status === true) {
                this.hideModalUpdate()
                this.fetchTable()
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    update: {
                        ...this.state.update,
                        loading: false
                    }
                });
            }
        })
    };
    // UPDATE END ------------------------------------------

    // DELETE START ------------------------------------------

    hideModalDelete = () => {
        this.setState({
            delete: {
                ...this.state.delete,
                visible: false
            },
        });
    };

    showModalDelete = (row) => {
        this.setState({
            delete: {
                ...this.state.delete,
                visible: true
            },
            id: row.id,
        });

    };

    deleteSubmit = (e) => {
        e.preventDefault();

        this.setState({
            delete: {
                ...this.state.delete,
                loading: true
            }
        });

        _setAxios("ruangan-detail/" + this.state.id, "DELETE").then(resp => {
            if (resp.status === true) {
                this.setState({
                    delete: {
                        visible: false,
                        loading: false
                    }
                });
                _success('topRight', 'Success', resp.data.message)
                this.fetchTable()
            } else {
                this.setState({
                    delete: {
                        ...this.state.delete,
                        loading: false
                    }
                });
            }
        })
    };

    // DELETE END ------------------------------------------

    handleCancel = () => this.setState({ visible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await _getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            visible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChangeImage = ({ fileList }) => {
        if (this.state.validateImage === true) {
            fileList.forEach((row, i) => {
                fileList[i]["status"] = "done"
            });
        }
        this.setState({ fileList })
    };

    beforeUploadImage = (file) => {
        const validate = _beforeUploadImage(file)
        if (validate === false) {
            this.setState({ validateImage: false })
        } else {
            this.setState({ validateImage: true })
        }
        return true
    };


    render() {
        const access = this.props.privilege.access["/sarana-prasarana/ruangan"]
        if (!isAuth(this.props.privilege) || access === undefined) {
            return <AuthRedirect />
        }
        const action = access.action
        const { data, pagination, loading, values, errors, tableOption, previewImage, fileList, visible, dataDetail } = this.state;

        const uploadButton = (
            <div>
                <PlusOutlined />
                <div>Upload</div>
            </div>
        );

        const uploadComp = <>
            <ImgCrop rotate>
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    maxCount={1}
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChangeImage}
                    beforeUpload={this.beforeUploadImage}
                >
                    {fileList.length >= 1 ? null : uploadButton}
                </Upload>
            </ImgCrop>

            <Image
                width={200}
                style={{ display: 'none' }}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                preview={{
                    visible,
                    src: previewImage,
                    onVisibleChange: value => {
                        this.setState({
                            visible: value
                        })
                    },
                }}
            />
        </>


        const sortComponent = <>
            <Radio.Group onChange={this.handleTableFilter} value={tableOption.sortField} name="sort_field">
                <Space direction="vertical">
                    <Radio value="id">ID</Radio>
                    <Radio value="unit">Unit</Radio>
                </Space>
            </Radio.Group>

            <Divider orientation="left"></Divider>

            <Radio.Group onChange={this.handleTableSort} value={tableOption.sortValue} name="sort_value">
                <Space direction="vertical">
                    <Radio value="ASC"><ArrowUpOutlined /> Ascending</Radio>
                    <Radio value="DESC"><ArrowDownOutlined /> Descending</Radio>
                </Space>
            </Radio.Group>
        </>

        const formInput = <>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <Autocomplete
                        options={this.state.ddl.kategoriAssetTetap}
                        value={values.kategori_id || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("kategori_id", newValue)
                            this.handleChangeSetValue("unit_id", null)
                            this.handleChangeSetValue("sumber_id", null)

                            if (newValue && newValue.id) {
                                this.getUnitRuanganDDL(newValue.id)
                            } else {
                                this.setState({
                                    ddl: {
                                        ...this.state.ddl,
                                        unitRuangan: [],
                                        sumber: [],
                                    }
                                })
                            }
                        }}
                        getOptionLabel={(option) => option.nama}
                        name="kategori_id"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Kategori"
                            size="small"
                            error={this.hasError('kategori_id')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('kategori_id') ? errors.kategori_id[0] : null
                            }
                        />}
                    />
                </Col>
                <Col xs={24}>
                    <Autocomplete
                        options={this.state.ddl.unitRuangan}
                        value={values.unit_id || null}
                        onChange={(e, newValue) => {
                            this.handleChangeSetValue("unit_id", newValue)
                            this.handleChangeSetValue("sumber_id", null)

                            if (newValue && newValue.id) {
                                this.getSumberAssetTetapDDL(newValue.id)
                            } else {
                                this.setState({
                                    ddl: {
                                        ...this.state.ddl,
                                        sumber: [],
                                    }
                                })
                            }
                        }}
                        getOptionLabel={(option) => option.nama}
                        name="unit_id"
                        renderInput={(params) => <TextField {...params}
                            fullWidth
                            label="Unit"
                            size="small"
                            error={this.hasError('unit_id')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText={
                                this.hasError('unit_id') ? errors.unit_id[0] : null
                            }
                        />}
                    />
                </Col>
                {
                    values.kategori_id ? (values.kategori_id.is_sumber === "1" ?
                        <Col xs={24}>
                            <Autocomplete
                                options={this.state.ddl.sumber}
                                value={values.sumber_id || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("sumber_id", newValue)
                                }}
                                getOptionLabel={(option) => option.nama}
                                name="sumber_id"
                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Sumber"
                                    size="small"
                                    error={this.hasError('sumber_id')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('sumber_id') ? errors.sumber_id[0] : null
                                    }
                                />}
                            />
                        </Col> : "") : ""
                }


                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Keterangan"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="keterangan"
                        onChange={this.handleChange}
                        value={values.keterangan || ''}
                        error={this.hasError('keterangan')}
                        helperText={
                            this.hasError('keterangan') ? errors.keterangan[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Jumlah Baik"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="jml_baik"
                        onChange={this.handleChange}
                        value={values.jml_baik || ''}
                        error={this.hasError('jml_baik')}
                        helperText={
                            this.hasError('jml_baik') ? errors.jml_baik[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Jumlah Rusak Ringan"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="jml_rusak_ringan"
                        onChange={this.handleChange}
                        value={values.jml_rusak_ringan || ''}
                        error={this.hasError('jml_rusak_ringan')}
                        helperText={
                            this.hasError('jml_rusak_ringan') ? errors.jml_rusak_ringan[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    <TextField
                        fullWidth
                        label="Jumlah Rusak Berat"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="jml_rusak_berat"
                        onChange={this.handleChange}
                        value={values.jml_rusak_berat || ''}
                        error={this.hasError('jml_rusak_berat')}
                        helperText={
                            this.hasError('jml_rusak_berat') ? errors.jml_rusak_berat[0] : null
                        }
                    />
                </Col>
                <Col xs={24} sm={12}>
                    {uploadComp}
                </Col>
                <Col xs={24} sm={12} style={{ textAlign: "center" }}>
                    <div>Upload Foto Unit</div>
                    <div>maks. 2MB bertipe jpg/jpeg/png</div>
                </Col>

            </Row>
        </>

        const modalCreate = <Modal
            title="Tambah Detail Ruangan"
            visible={this.state.create.visible}
            onCancel={this.hideModalCreate}
            footer={<>
                <Button onClick={this.hideModalCreate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.createSubmit} loading={this.state.create.loading}>Simpan</Button>
            </>}
        >
            <form autoComplete="off" onSubmit={this.createSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalUpdate = <Modal
            title="Update Detail Ruangan"
            onCancel={this.hideModalUpdate}
            visible={this.state.update.visible}
            footer={<>
                <Button onClick={this.hideModalUpdate}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.updateSubmit} loading={this.state.update.loading}>Simpan</Button>
            </>}

        >
            <form autoComplete="off" onSubmit={this.updateSubmit}>
                {formInput}
            </form>
        </Modal>

        const modalDelete = <Modal
            title={<><ExclamationCircleOutlined /> Confirm</>}
            visible={this.state.delete.visible}
            onCancel={this.hideModalDelete}
            width={200}
            footer={<>
                <Button onClick={this.hideModalDelete}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.deleteSubmit} loading={this.state.delete.loading}>Simpan</Button>
            </>}
        >
            Apakah Anda Yakin?

        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/sarana-prasarana/ruangan`}>Ruangan</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create === true ? <><Tooltip title="Tambah">
                        <Button type='primary' block onClick={this.showModalCreate} icon={<PlusOutlined />}> Tambah</Button>
                    </Tooltip></> : ""} Ï
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Detail Ruangan"
                        subTitle="Data detail ruangan"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            {
                                dataDetail.loading ? <PreloadContent /> :
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDetail.data1}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> {item.title ? ":" : ""} <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            }
                        </Col>
                        <Col xs={24} sm={12}>
                            {
                                dataDetail.loading ? <PreloadContent /> :
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDetail.data2}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> {item.title ? ":" : ""} <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                            }
                        </Col>
                        <Col xs={24}>
                            <Divider />
                        </Col>

                        <Col xs={16} sm={18} md={20} lg={21}>
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onChange={this.handleTableSearch}
                                value={this.state.search || ""}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={8} sm={6} md={4} lg={3} className="text-align-right">
                            <Popover placement="bottom" content={sortComponent} trigger="click">
                                <Button block><SortAscendingOutlined /> Sort</Button>
                            </Popover>
                        </Col>
                        <Col xs={24}>
                            <Divider orientation="left"></Divider>
                        </Col>

                        <Col xs={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        sorter: false,
                                        width: '3%',
                                    },
                                    {
                                        title: 'Kategori',
                                        dataIndex: 'kategori_nama',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Unit',
                                        dataIndex: 'unit_nama',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Sumber',
                                        dataIndex: 'sumber_nama',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Keterangan',
                                        dataIndex: 'keterangan',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Jumlah Baik',
                                        dataIndex: 'jml_baik',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Jumlah Rusak Ringan',
                                        dataIndex: 'jml_rusak_ringan',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Jumlah Rusak berat',
                                        dataIndex: 'jml_rusak_berat',
                                        sorter: false,
                                    },
                                    {
                                        title: 'Foto',
                                        dataIndex: 'foto',
                                        sorter: false,
                                        render: (a, row) => !row.foto ? "-" : <Image.PreviewGroup>
                                            <Image style={{
                                                width: 70,
                                            }} src={row.main_path + row.foto} />
                                        </Image.PreviewGroup>
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        align: "center",
                                        key: 'operation',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.update === true ? (<Menu.Item key={0} onClick={this.showModalUpdate.bind(this, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                                            {action.delete === true ? (<Menu.Item key={1} onClick={this.showModalDelete.bind(this, row)}><DeleteOutlined /> Delete</Menu.Item>) : ""}
                                            {action.detail === true ? (<Menu.Item key={2}><Link to={`/sarana-prasarana/asset-tetap/unit/${row.ref_num}`}><InfoCircleOutlined /> Detail & Riwayat Peminjaman</Link></Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={data}
                                pagination={pagination}
                                loading={loading}
                                onChange={this.handleTableChange}
                                size="small"
                            />
                        </Col>
                    </Row>
                </div>

                {modalCreate}

                {modalUpdate}

                {modalDelete}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(RuanganDetail);
