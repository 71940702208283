import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Input, Tooltip, Breadcrumb, BackTop } from 'antd';
import { InfoCircleOutlined, SearchOutlined, FilterOutlined, SortAscendingOutlined, DoubleRightOutlined, UploadOutlined, DownloadOutlined, SettingOutlined } from '@ant-design/icons';

import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering, _scrollFloatBtn, capitalizeFirstLetter, _success, _warn } from '../../../../lib/Helper';

import AuthRedirect from '../../../../components/AuthRedirect'
import Delete from '../modal/Delete';
import DeleteMultiple from '../modal/DeleteMultiple';
import Verification from '../modal/Verification';
import InputValue from '../modal/InputValue';
import MassInputValue from '../modal/MassInputValue';
import NotifAccount from '../modal/NotifAccount';
import NotifLolos from '../modal/NotifLolos';
import DaftarUlang from '../modal/DaftarUlang';
import Filter from '../modal/Filter';
import Desktop from './Desktop';
import Mobile from './Mobile';
import Export from '../modal/Export';
import Config from '../modal/Config';


const tableOptions = {
  sorts: [{
    value: "ASC",
    label: "Ascending",
  }, {
    value: "DESC",
    label: "Descending",
  }],
  fields: [{
    value: "id",
    label: "Tanggal Pembuatan",
  }, {
    value: "nama",
    label: "Nama",
  }, {
    value: "nisn",
    label: "NISN",
  }, {
    value: "jenis_kelamin",
    label: "Jenis Kelamin",
  }]
}

class ListIndex extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      download: {
        url: null,
        filename: null,
      },
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      delete: {
        data: {},
        visible: false
      },
      deleteMultiple: {
        data: {},
        visible: false
      },
      verification: {
        data: {},
        visible: false
      },
      inputvalue: {
        data: {},
        visible: false
      },
      massInputValue: {
        data: {},
        visible: false
      },
      notifAccount: {
        data: {},
        visible: false
      },
      daftarUlang: {
        data: {},
        visible: false
      },
      notifLolos: {
        data: {},
        visible: false
      },
      filter: {
        values: {},
        visible: false,
      },
      export: {
        values: {},
        visible: false,
      },
      config: {
        data: {},
        visible: false
      },
    };
  }

  componentDidMount() {
    _scrollFloatBtn()
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("ppdb/siswa/table", "POST", params).then(resp => {
      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          },
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };
  // FILTER END ------------------------------------------


  modalDelete = (visible = false, data = {}) => {
    this.setState({
      delete: {
        data: data,
        visible: visible
      },
    })
  }
  modalDeleteMultiple = (visible = false, data = {}) => {
    this.setState({
      deleteMultiple: {
        data: data,
        visible: visible
      },
    })
  }
  modalNotifAccount = (visible = false, data = {}) => {
    this.setState({
      notifAccount: {
        data: data,
        visible: visible
      },
    })
  }
  modalNotifLolos = (visible = false, data = {}) => {
    this.setState({
      notifLolos: {
        data: data,
        visible: visible
      },
    })
  }

  modalVerification = (visible = false, data = {}) => {
    this.setState({
      verification: {
        data: data,
        visible: visible
      },
    })
  }
  modalInputValue = (visible = false, data = {}) => {
    this.setState({
      inputvalue: {
        data: data,
        visible: visible
      },
    })
  }
  modalMassInputValue = (visible = false, data = {}) => {
    this.setState({
      massInputValue: {
        data: data,
        visible: visible
      },
    })
  }
  modalDaftarUlang = (visible = false, data = {}) => {
    this.setState({
      daftarUlang: {
        data: data,
        visible: visible
      },
    })
  }

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
      },
    })
  }

  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  onDownloadFormulirPendaftaran = (data = {}) => {
    let param = {
      siswa_id: data.id
    }
    _setAxios("ppdb/siswa/formulir-pendaftaran", "POST", param).then(resp => {
      if (resp.status === true) {
        _success('topRight', 'Success', resp.data.message)
        window.open(resp.data.data.link)
      }
    })
  }

  onDownloadKartuUjian = (data = {}) => {
    let param = {
      siswa_id: data.id
    }
    _setAxios("ppdb/siswa/kartu-ujian", "POST", param).then(resp => {
      if (resp.status === true) {
        _success('topRight', 'Success', resp.data.message)
        window.open(resp.data.data.link)
      }
    })
  }

  modalConfig = (visible = false, data = {}) => {
    _setAxios("ppdb/isactive-app", "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          config: {
            data: resp.data.data,
            visible: visible
          },
        })
      } else {
        _warn('topRight', 'Failed', 'Data config PPDB gagal ditampilkan')

      }
    })
  }

  render() {

    const statusSiswa = window.location.pathname.split("/").pop()
    const access = this.props.privilege.access["/peserta-didik/" + statusSiswa]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action
    const { table } = this.state;

    const sortComponent = <>
      <Radio.Group onChange={(e) => {
        const params = getParamTable("sort_field", table, e.target.value)
        this.fetch(params, isMobile ? true : false)
      }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
        <Space direction="vertical">
          {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
        </Space>
      </Radio.Group>
      <Divider orientation="left"></Divider>
      <Radio.Group onChange={(e) => {
        const params = getParamTable("sort", table, e.target.value)
        this.fetch(params, isMobile ? true : false)
      }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
        <Space direction="vertical">
          {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
        </Space>
      </Radio.Group>
    </>

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Peserta Didik</Breadcrumb.Item>
            <Breadcrumb.Item>Peserta Didik {capitalizeFirstLetter(statusSiswa)}</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true)}><DownloadOutlined /> Export Calon PPDB</Button> : null,
            action.import ? <Button onClick={this.modalMassInputValue.bind(this, true)}><UploadOutlined /> Import Penilaian</Button> : null,
            action.access_all_data ? <Button type='primary' onClick={this.modalConfig.bind(this, true)}><SettingOutlined /></Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title={`Peserta Didik ${capitalizeFirstLetter(statusSiswa)}`}
            subTitle={`Data peserta didik ${statusSiswa}`}
          />
          <MobileView>
            <div className='float-btn-center' id='float-btn-center' align='center'>
              <div className="float-btn-div">
                <Popover placement="top" content={sortComponent} trigger="click">
                  <Button type='primary' className='float-btn' icon={<SortAscendingOutlined />}>Urutkan</Button>
                </Popover>
                <Divider type="vertical" className="divider-border-white" />
                <Button type='primary' className='float-btn' icon={<FilterOutlined />} onClick={this.modalFilter.bind(this, true)}>Filter</Button>
              </div>

            </div>
          </MobileView>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <Input
                name="search"
                className='search-table'
                placeholder="Search..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const params = getParamTable("search", table, e.target.value)
                    this.fetch(params, isMobile ? true : false)
                  }
                }}
                suffix={
                  <Tooltip title="Cari berdasarkan nama">
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Col>
            <Col xs={24} sm={6} md={5}>
              <BrowserView>
                <Button.Group className='ant-btn-group-fullwidth'>
                  <Button className='btn-border-radius-left' onClick={this.modalFilter.bind(this, true)}><FilterOutlined /></Button>
                  <Popover placement="bottom" content={sortComponent} trigger="click">
                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                  </Popover>
                </Button.Group>
              </BrowserView>
            </Col>

            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  statusSiswa={statusSiswa}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalPenagguhan={this.modalPenagguhan}
                  modalDelete={this.modalDelete}
                  modalDeleteMultiple={this.modalDeleteMultiple}
                  modalVerification={this.modalVerification}
                  modalInputValue={this.modalInputValue}
                  modalNotifAccount={this.modalNotifAccount}
                  modalNotifLolos={this.modalNotifLolos}
                  modalDaftarUlang={this.modalDaftarUlang}
                  modalConfig={this.modalConfig}
                  onDownloadFormulirPendaftaran={this.onDownloadFormulirPendaftaran}
                  onDownloadKartuUjian={this.onDownloadKartuUjian}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  statusSiswa={statusSiswa}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalPenagguhan={this.modalPenagguhan}
                  modalDelete={this.modalDelete}
                  modalVerification={this.modalVerification}
                  modalInputValue={this.modalInputValue}
                  modalNotifAccount={this.modalNotifAccount}
                  modalNotifLolos={this.modalNotifLolos}
                  modalDaftarUlang={this.modalDaftarUlang}
                  modalConfig={this.modalConfig}
                  onDownloadFormulirPendaftaran={this.onDownloadFormulirPendaftaran}
                  onDownloadKartuUjian={this.onDownloadKartuUjian}
                />
              </MobileView>
            </Col>

          </Row>
        </div>

        {
          this.state.delete.visible ?
            <Delete
              data={this.state.delete.data}
              visible={this.state.delete.visible}
              hideModal={this.modalDelete.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.deleteMultiple.visible ?
            <DeleteMultiple
              data={this.state.deleteMultiple.data}
              visible={this.state.deleteMultiple.visible}
              hideModal={this.modalDeleteMultiple.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.notifAccount.visible ?
            <NotifAccount
              data={this.state.notifAccount.data}
              visible={this.state.notifAccount.visible}
              hideModal={this.modalNotifAccount.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.notifLolos.visible ?
            <NotifLolos
              data={this.state.notifLolos.data}
              visible={this.state.notifLolos.visible}
              hideModal={this.modalNotifLolos.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.daftarUlang.visible ?
            <DaftarUlang
              data={this.state.daftarUlang.data}
              visible={this.state.daftarUlang.visible}
              hideModal={this.modalDaftarUlang.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.verification.visible ?
            <Verification
              data={this.state.verification.data}
              visible={this.state.verification.visible}
              hideModal={this.modalVerification.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.inputvalue.visible ?
            <InputValue
              data={this.state.inputvalue.data}
              visible={this.state.inputvalue.visible}
              hideModal={this.modalInputValue.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.massInputValue.visible ?
            <MassInputValue
              data={this.state.massInputValue.data}
              visible={this.state.massInputValue.visible}
              hideModal={this.modalMassInputValue.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        {
          this.state.config.visible ?
            <Config
              data={this.state.config.data}
              visible={this.state.config.visible}
              hideModal={this.modalConfig.bind(this, false)}
              fetchTable={this.fetchTable}
            /> : ""
        }
        <BackTop />

        <a href={this.state.download.url} className="download-file" download={this.state.download.filename} target="_blank" rel="noopener noreferrer" >download</a>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ListIndex);
