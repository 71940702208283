import validate from 'validate.js';

export const schemaTataTertib = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}
export const schemaPelanggaran = {
    kode: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    pelanggaran: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    poin: {
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Poin harus berupa agka");
            }
        },
    },
}
export const schemaPengaturanPelanggaran = {
    poin_min: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Poin harus berupa agka");
            }
        },
    },
    poin_maks: {
        presence: { allowEmpty: false, message: 'harus diisi' },
        format: {
            pattern: /^$|[0-9]+/,
            message: function () {
                return validate.format("^Poin harus berupa agka");
            }
        },
    },
    tindakan: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}

export const schemaPenghargaan = {
    kode: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
    bintang: {
        presence: { allowEmpty: false, message: 'harus dipilih' },
    },
    kriteria: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    },
}