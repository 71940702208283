import React from 'react';
import { connect } from 'react-redux'
import { isAuth } from '../../lib/Helper';
import AuthRedirect from '../../components/AuthRedirect'
import { Breadcrumb, Tabs, message, PageHeader } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import DataDiri from './create/DataDiri';
import OrangTua from './create/OrangTua';
import ActivitasBelajar from './create/AktivitasBelajar';
import Beasiswa from './detail/Beasiswa';
import Prestasi from './detail/Prestasi';
import Pendidikan from './detail/Pendidikan';
import Berkas from './detail/Berkas';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

class GtkCreate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddl: {
                statusPenempatan: []
            },
            values: {},
            errors: {},
            id: null,
            tabIdx: "0",
        };
    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.error('Data diri wajib disimpan terlebih dahulu.');
                return
            }
        }

        this.setState({
            tabIdx: key
        })
    }

    handleGetDataID = (id) => {
        this.setState({
            id: id,
            tabIdx: "1"
        })
    }


    render() {
        const access = this.props.privilege.access["/peserta-didik/aktif"]
        const action = access.action
        if (!isAuth(this.props.privilege) || access === undefined || action.create === false) {
            return <AuthRedirect />
        }
        const steps = [
            {
                title: 'Data Diri',
                content: <DataDiri action={action} onGetDataID={this.handleGetDataID} />,
            },
            {
                title: 'Orang Tua',
                content: <OrangTua action={action} dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Aktivitas Belajar',
                content: <ActivitasBelajar action={action} dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Beasiswa & Bantuan',
                content: <Beasiswa action={action} dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Prestasi',
                content: <Prestasi action={action} dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Pendidikan',
                content: <Pendidikan action={action} dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Berkas',
                content: <Berkas action={action} dataID={this.state.id} {...this.props} />,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Peserta Didik</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/peserta-didik/aktif`}>Peserta didik aktif</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Tambah</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                        {steps.map((item, i) => (
                            <TabPane key={i} tab={item.title}>
                                {steps[i].content}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(GtkCreate);
