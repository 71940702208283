import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { _setAxios } from '../../lib/Helper';

async function fetchList() {

  return await _setAxios("keuangan/pemasukan/jenis-pos/dropdown", "POST")
    .then(body => {
      let results = []
      body.data.data.forEach(row => {
        results.push({
          value: row.id,
          label: row.nama,
        })
      });

      return results
    })
}

const SelectPosPemasukan = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchList().then((newOptions) => {
      setOptions(newOptions);
    });
  }, []);

  return (
    <Select
      {...props}
      allowClear
      fetchOptions={fetchList}
      placeholder="Pilih pos pemasukan"
      style={{
        width: '100%',
      }}
      options={options}
      defaultActiveFirstOption={false}
      filterOption={false}
      notFoundContent={null}
    />
  );
};
export default SelectPosPemasukan;
