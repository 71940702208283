import React from 'react';

import { Modal, Button, Alert, Checkbox } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';
import conf from '../../../../Config'

class ModalContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isactive: false,
            errors: {},
            loading: false,
        };
    }

    handleSubmit = async () => {
        this.setState({
            loading: true
        });
        const params = {
            isactive: this.state.isactive ? "1" : "0",
        }

        let endpoint = "ppdb/isactive-app"
        let method = "POST"
        if (this.props.data.id) {
            endpoint = "ppdb/isactive-app/" + this.props.data.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    onChange = (e) => {
        this.setState({
            isactive: e.target.checked
        })
    };

    componentDidMount = () => {
        this.setState({
            isactive: this.props.data && this.props.data.attr1_val === "1" ? true : false
        })
    }


    render() {
        return (
            <Modal
                maskClosable={false}
                title={"Config PPDB"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <div><Alert message={<>PPDB bisa diaktifkan / dinon-aktifkan menggunakan fitur ini. <div><a  rel="noopener noreferrer" href={conf.app_ppdb} target='_blank'>PPDB MAN 1 Gresik</a></div></>} type="info" showIcon /></div>
                <br />
                <Checkbox defaultChecked={this.props.data && this.props.data.attr1_val === "1" ? true : false} onChange={this.onChange}>Aktifkan PPDB</Checkbox>

            </Modal>
        )
    }
}

export default ModalContent;
