import React from 'react';
import { Row, Col, Divider, List, Tag, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownCircleOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const statusSiswa = this.props.statusSiswa
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan"/>)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      avatar={<Avatar src={row.main_path + row.foto} />}
                      title={
                        action.detail === true ?
                          (<Link to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}>{row.nama}</Link>) : row.nama
                      }
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              NISN: {row.nisn ? row.nisn : "-"} <br />
                              TTL: {row.tempat_lahir ? row.tempat_lahir + " (" + row.tgl_lahir + ")" : "-"} <br />
                              Tingkat Rombel: {row.rombel_nama ? row.rombel_nama : "-"} <br />
                            </small>
                          </Col>
                          <Col xs={24}>
                            {row.status_siswa === "Aktif" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>Tidak Aktif</Tag>}
                            {row.akun_ditangguhkan === "0" ? "" : <Tag color="red">Ditangguhkan</Tag>}
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail === true ? (<Menu.Item key={0}><Link to={"/peserta-didik/" + statusSiswa + "/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                        {action.update === true ? (<Menu.Item key={1} onClick={this.props.modalPenagguhan.bind(this, true, row)}><EditOutlined /> Ubah Status Penangguhan</Menu.Item>) : ""}
                        {action.delete === true && statusSiswa === "aktif" ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Non Aktifkan</Menu.Item>) : ""}
                        {action.restore === true && statusSiswa === "non-aktif" ? (<Menu.Item key={3} onClick={this.props.modalRestore.bind(this, row)}><CheckCircleOutlined /> Aktifkan</Menu.Item>) : ""}
                      </Menu>}>
                        <div className="ant-dropdown-link tb-action">
                            <Button type="primary">Opsi <DownCircleOutlined style={{fontSize: 11}}/></Button>
                        </div>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
