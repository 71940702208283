import React from 'react';
import { Table, Row, Col, Pagination } from 'antd';

import { getParamTable, _fmtRupiah } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'Tanggal',
                  dataIndex: 'tgl_transaksi',
                }, {
                  title: 'Pemasukan',
                  render: (i, row) => "Rp. " + (row.total_pemasukan ? _fmtRupiah(row.total_pemasukan) : "0")
                }, {
                  title: 'Pengeluaran',
                  render: (i, row) => "Rp. " + (row.total_pengeluaran ? _fmtRupiah(row.total_pengeluaran) : "0")
                }, {
                  title: 'Saldo',
                  render: (i, row) => "Rp. " + (row.saldo ? _fmtRupiah(row.saldo) : "-")
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
