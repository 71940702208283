import React from 'react';

import { Table, Row, Col, Button, Menu, Dropdown, Pagination, Tag } from 'antd';
import { SettingOutlined, DownCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';

import { getParamTable, _tableLogActivity } from '../../../../../lib/Helper';

class Desktop extends React.Component {
  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  width: '2%',
                }, {
                  title: 'Nama POS',
                  dataIndex: 'nama',
                }, {
                  title: 'Jenis Pembayaran',
                  dataIndex: 'jenis_pembayaran',
                  align: 'center',
                }, {
                  title: 'Angsuran',
                  dataIndex: 'angsuran',
                  align: 'center',
                  render: (i, row) => row.angsuran === "1" ? <Tag color="success" style={{width: "100%"}}>Ya</Tag> : <Tag color="error" style={{width: "100%"}}>Tidak</Tag>
                }, {
                  title: 'Frekuensi Pembayaran',
                  dataIndex: 'frekuensi_pembayaran',
                  align: 'center',
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  render: (i, row) => row.keterangan ? row.keterangan : "-"
                }, {
                  title: <SettingOutlined />,
                  align: "center",
                  key: 'operation',
                  width: '2%',
                  render: (a, row) => (!action.update && !action.delete ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                    {action.update ? (<Menu.Item key={0} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                    {action.delete ? (<Menu.Item key={1} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                  </Menu>}>
                    <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                  </Dropdown>),
                }, {
                  title: 'LogActivity',
                  key: 'operation',
                  width: '10%',
                  render: (a, row) => _tableLogActivity(row),
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
