import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Input } from 'antd';

import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../../lib/Helper';

const schema = {
    pengeluaran_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    nama_barang: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jumlah: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    satuan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    harga_satuan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });


        const params = {
            pengeluaran_id: this.props.data.pengeluaran_id || "",
            nama_item: values.nama_barang || "",
            jml_item: values.jumlah || "",
            satuan_item: values.satuan || "",
            hrgsatuan_item: values.harga_satuan ? _isNumber(values.harga_satuan) : 0,
        }

        let endpoint = "keuangan/pengeluaran/detail"
        let method = "POST"
        if (values.id) {
            endpoint = endpoint + "/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        return (
            <Modal
                maskClosable={false}
                title={"Form Detail Pengeluaran"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Nama Barang *">
                                <Input
                                    style={{ width: '100%' }}
                                    name="nama_barang"
                                    placeholder="Nama barang"
                                    defaultValue={this.props.data.nama_barang}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("nama_barang", e.target.value)
                                    }} />
                            </Form.Item>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={14}>
                                    <Form.Item label="Jumlah *">
                                        <Input
                                            style={{ width: '100%' }}
                                            name="jumlah"
                                            placeholder="Jumlah"
                                            defaultValue={this.props.data.jumlah}
                                            value={this.state.values.jumlah ? _isNumber(this.state.values.jumlah) : null}
                                            onChange={(e) => {
                                                this.handleChangeSetValue("jumlah", e.target.value)
                                            }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10}>
                                    <Form.Item label="Satuan *" extra="Ex: box, dus, rim, unit, dll...">
                                        <Input
                                            style={{ width: '100%' }}
                                            name="satuan"
                                            placeholder="Satuan"
                                            defaultValue={this.props.data.satuan}
                                            value={this.state.values.satuan}
                                            onChange={(e) => {
                                                let val = e.target.value
                                                this.handleChangeSetValue("satuan", val)
                                            }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Harga Satuan *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="harga_satuan"
                                    placeholder="0"
                                    defaultValue={this.props.data.harga_satuan}
                                    value={this.state.values.harga_satuan ? _fmtRupiah(this.state.values.harga_satuan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("harga_satuan", e.target.value)
                                    }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
