import React from 'react';
import { connect } from 'react-redux'
import Config from "../../../Config";
import { isAuth, _setAxios, _success, _tableLogActivity, getParamTable, getParamTempTableFiltering, _getBase64, _getBase64Upload, _validationFormMsg } from '../../../lib/Helper';
import { alasanKeluarGuTendOptions, genderOptions, statusKepegawaianOptions } from '../../../data/options';
import { DatePicker, Breadcrumb, Table, Row, Col, Divider, Popover, Button, Radio, PageHeader, Space, Menu, Dropdown, Input, Tooltip, Modal, Select, message, Form, Upload, List, Tag, Pagination } from 'antd';
import { FilterOutlined, InboxOutlined, DownloadOutlined, UploadOutlined, InfoCircleOutlined, SearchOutlined, DoubleRightOutlined, PlusOutlined, SortAscendingOutlined, SettingOutlined, DownCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'
import { Link } from 'react-router-dom';
import { schemaGuruDelete } from '../schema';
import { validate } from 'validate.js';

const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

const tableOptions = {
    sorts: [{
        value: "ASC",
        label: "Ascending",
    }, {
        value: "DESC",
        label: "Descending",
    }],
    fields: [{
        value: "id",
        label: "Tanggal Pembuatan",
    }, {
        value: "nama_lengkap",
        label: "Nama",
    }, {
        value: "jenis_kelamin",
        label: "Jenis Kelamin",
    }]
}

class Gtk extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            table: {
                loading: true,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {
                    status_tk: "Aktif",
                    tipe: "guru"
                },
            },
            export: {
                visible: false,
                loading: false,
            },
            import: {
                visible: false,
                loading: false,
                validateFile: false,
                fileList: [],
            },
            filter: {
                visible: false,
                values: {},
            },
            ddl: {
                statusPenempatan: []
            },
            id: 0,
            values: {},
            errors: {},
        };
    }

    componentDidMount() {
        this.fetchTable()
    }

    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tk/table", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                        pagination: {
                            ...params.pagination,
                            total: resp.data.info.total
                        }
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    handleChangeSetValue = (name, value) => {
        this.setForm(name, value)
    };


    handleOnChange = (e) => {
        const { name, value } = e.target;
        this.setForm(name, value)
    };

    setForm = (name, value) => {
        const { values } = this.state;

        values[name] = value;
        this.setState({
            values: values,
        });
    }

    setValidate = (values) => {
        const errors = validate(values, schemaGuruDelete);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }


    onDelete = (row) => {
        Modal.confirm({
            title: 'Non Aktifkan Data',
            width: 470,
            icon: false,
            content: <>
                {/* <div>Data akan dihapus dari list, Apakah Anda Yakin ?</div> */}
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="NIP">
                        <Input
                            defaultValue={row.nip}
                            readOnly={true}
                        />
                    </Form.Item>
                    <Form.Item label="Nama">
                        <Input
                            defaultValue={row.nama_lengkap}
                            readOnly={true}
                        />
                    </Form.Item>
                    <Form.Item label="Tanggal Keluar">
                        <DatePicker
                            style={{ width: "100%" }}
                            onChange={(date, dateString) => {
                                this.handleChangeSetValue("tgl_keluar", dateString ? dateString : null)
                            }} />
                    </Form.Item>
                    <Form.Item label="Alasan Keluar">
                        <Select
                            name="alasan_keluar"
                            placeholder="Alasan Keluar"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("alasan_keluar", newValue ? newValue : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {alasanKeluarGuTendOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Keterangan keluar">
                        <TextArea rows={3}
                            placeholder="Keterangan keluar"
                            name="keterangan_keluar"
                            onChange={this.handleOnChange}
                        />
                    </Form.Item>
                </Form>
            </>,
            cancelText: 'Batal',
            okText: 'Simpan',
            onOk: () => {
                const { values } = this.state

                //Validation
                let validation = this.setValidate(values)

                return new Promise((resolve, reject) => {
                    if (validation !== undefined) {
                        _validationFormMsg(validation)
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                        return
                    }
                    _setAxios("tk/non-aktif/" + row.id, "PUT", {
                        tgl_keluar: values.tgl_keluar ? values.tgl_keluar : null,
                        alasan_keluar: values.alasan_keluar ? values.alasan_keluar.value : null,
                        keterangan_keluar: values.keterangan_keluar ? values.keterangan_keluar : null,
                    }).then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            this.setState({
                                values: {}
                            })
                            this.fetchTable()
                        }
                        setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    // EXPORT START ------------------------------------------

    hideModalExport = () => {
        this.setState({
            export: {
                ...this.state.export,
                visible: false
            },
        });
    };

    showModalExport = (row) => {
        this.setState({
            export: {
                ...this.state.export,
                visible: true
            },
        });
    };

    exportSubmit = (e) => {
        e.preventDefault();
        this.setState({
            export: {
                ...this.state.export,
                loading: true
            }
        });

        _setAxios("tk/export", "POST", {
            status_tk: "Aktif",
            tipe_tk: "guru",
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.open(resp.data.data.link, '_blank');
                }, 1000);
            }
            this.setState({
                export: {
                    visible: false,
                    loading: false
                }
            });
        })
    };
    // EXPORT END ------------------------------------------

    // IMPORT START ------------------------------------------

    hideModalImport = () => {
        this.setState({
            import: {
                ...this.state.import,
                visible: false
            },
        });
    };

    showModalImport = (row) => {
        this.setState({
            import: {
                ...this.state.import,
                visible: true
            },
        });
    };

    handleChangeImportFile = ({ fileList }) => {
        if (this.state.import.validateFile === true) {
            fileList.forEach((row, i) => {
                fileList[i].status = "done"
            });

            this.setState({
                import: {
                    ...this.state.import,
                    fileList: fileList
                }
            })
        } else {
            this.setState({
                import: {
                    ...this.state.import,
                    fileList: null
                }
            })
        }
    }

    beforeImportFile = (file) => {
        const isTypeFile = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isTypeFile) {
            message.error('Anda bisa upload .xlsx file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('File tidak boleh lebih besar dari 2MB!');
        }

        const flag = isTypeFile && isLt2M

        if (flag === false) {
            this.setState({
                import: {
                    ...this.state.import,
                    validateFile: false
                }
            })
            return false
        } else {
            this.setState({
                import: {
                    ...this.state.import,
                    validateFile: true
                }
            })
        }
        return true
    }

    importSubmit = async (e) => {
        e.preventDefault();

        this.setState({
            import: {
                ...this.state.import,
                loading: true
            }
        });

        let fileList = this.state.import.fileList
        let file = null
        if (fileList.length > 0) {
            if (fileList[0].url && (fileList[0].url.includes("https://") || fileList[0].url.includes("http://"))) {
            } else {
                file = await _getBase64(fileList[0].originFileObj)
                file = _getBase64Upload(file)
            }

            if (fileList[0].status === "error") {
                message.error('File yang Anda upload tidak sesuai!');
                return false
            }
        }

        _setAxios("tk/import", "POST", {
            file: file
        }).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = '/log/import';
                }, 1000);
            } else {
                this.setState({
                    import: {
                        ...this.state.import,
                        loading: false
                    }
                });
            }
        })
    };

    // IMPORT END ------------------------------------------

    // FILTER START ------------------------------------------
    hideModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: false
            },
        });
    };

    showModalFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                visible: true
            },
        });
    };

    setTempTableFilter = (name, value) => {
        let values = this.state.filter.values
        values[name] = value

        this.setState({
            filter: {
                ...this.state.filter,
                values: values
            }
        })
    };

    setTableFilterFromTemp = () => {
        const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
        this.fetch(params)
        this.hideModalFilter()
    };
    // FILTER END ------------------------------------------


    render() {
        const access = this.props.privilege.access["/tenaga-kependidikan/guru"]
        const action = access.action

        if (!isAuth(this.props.privilege) || access === undefined || action.view === false) {
            return <AuthRedirect />
        }
        const { table } = this.state;

        const sortComponent = <>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort_field", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].field : ""} name="sort_field">
                <Space direction="vertical">
                    {tableOptions.fields.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
            <Divider orientation="left"></Divider>
            <Radio.Group onChange={(e) => {
                const params = getParamTable("sort", table, e.target.value)
                this.fetch(params)
            }} value={table.sorting.length > 0 ? table.sorting[0].sort : ""} name="sort_value">
                <Space direction="vertical">
                    {tableOptions.sorts.map((row, i) => <Radio key={i} value={row.value}>{row.label}</Radio>)}
                </Space>
            </Radio.Group>
        </>

        const modalExport = <Modal
            title="Export Data Guru"
            visible={this.state.export.visible}
            onCancel={this.hideModalExport}
            footer={<>
                <Button onClick={this.hideModalExport}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.exportSubmit} loading={this.state.export.loading}>Export</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <div style={{ textAlign: 'right' }}>
                    Apakah Anda yakin? data akan di export<br />
                    Data yang di export akan langsung ke download, tanpa melalui log export
                </div>
            </Form>
        </Modal>

        const modalImport = <Modal
            title="Upload Data Guru"
            visible={this.state.import.visible}
            onCancel={this.hideModalImport}
            footer={<>
                <Button onClick={this.hideModalImport}>Batal</Button>
                <Button type="primary" htmlType="submit" onClick={this.importSubmit} loading={this.state.import.loading}>Import</Button>
            </>}
        >
            <div>Silahkan unduh template excel dibawah ini.</div>
            <div><a href={`${Config.host_api}/public/Template_Upload_GTK.xlsx`}><DownloadOutlined /> Template Excel (.xlsx)</a></div>
            <br />
            <div>Untuk menambahkan data Guru melalui import excel, pastikan file sudah terisi sesuai format yang telah disediakan.</div>
            <br />
            <div>
                <Dragger
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    maxCount={1}
                    fileList={this.state.import.fileList}
                    onChange={this.handleChangeImportFile}
                    beforeUpload={this.beforeImportFile}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Klik atau seret file ke area ini</p>
                    <p className="ant-upload-hint">File yang diperbolehkan untuk diunggah: .XLSX</p>
                    <p className="ant-upload-hint">File yang diperbolehkan maksimal 2MB</p>
                </Dragger>
            </div>
            <div style={{ textAlign: "left" }}>Log import <Link to={`/log/import`}>klik disini</Link></div>

        </Modal>

        const modalFilter = <Modal
            title="Filter Guru"
            // width={300}
            visible={this.state.filter.visible}
            onCancel={this.hideModalFilter}
            footer={<>
                <Button onClick={this.hideModalFilter}>Tutup</Button>
                <Button type='primary' onClick={this.setTableFilterFromTemp}>Terapkan</Button>
            </>}
        >
            <Form
                name="basic"
                autoComplete="off"
                layout="vertical"
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Form.Item label="Jenis Kelamin">
                            <Select
                                showSearch
                                allowClear
                                name="jenis_kelamin"
                                placeholder="Jenis Kelamin"
                                onChange={(e, newValue) => {
                                    this.setTempTableFilter("jenis_kelamin", newValue ? newValue.value : null)
                                }}
                                style={{ width: '100%' }}
                            >
                                {genderOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label="Status Kepegawaian">
                            <Select
                                showSearch
                                allowClear
                                name="status_kepegawaian"
                                placeholder="Jenjang Sekolah Asal"
                                onChange={(e, newValue) => {
                                    this.setTempTableFilter("status_kepegawaian", newValue ? newValue.value : null)
                                }}
                                style={{ width: '100%' }}
                            >
                                {statusKepegawaianOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Tenaga Kependidikan</Breadcrumb.Item>
                        <Breadcrumb.Item>Guru</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={action.create ? <Tooltip title="Tambah">
                        <Button type='primary' block className='nav-btn-page'><Link to={"/tenaga-kependidikan/guru/create"}><PlusOutlined /> Tambah</Link></Button>
                    </Tooltip> : ""}
                />
                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <PageHeader
                        className="site-page-header"
                        title="Guru"
                        subTitle="Data guru"
                    />
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={10} md={8} className="text-align-right">
                            <Button.Group className='ant-btn-group-fullwidth'>
                                {action.export ? <Button block className='btn-border-radius-left' onClick={this.showModalExport}><DownloadOutlined /> Export</Button> : null}
                                {action.import ? <Button block className='btn-border-radius-right' onClick={this.showModalImport}><UploadOutlined /> Import</Button> : null}
                            </Button.Group>
                        </Col>
                        <Col xs={24} sm={10} md={11} >
                            <Input
                                name="search"
                                className='search-table'
                                placeholder="Search..."
                                prefix={<SearchOutlined className="site-form-item-icon" />}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        const params = getParamTable("search", table, e.target.value)
                                        this.fetch(params)
                                    }
                                }}
                                suffix={
                                    <Tooltip title="Cari berdasarkan nama">
                                        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                    </Tooltip>
                                }
                            />
                        </Col>
                        <Col xs={24} sm={6} md={5}>
                            <Button.Group className='ant-btn-group-fullwidth'>
                                <Button className='btn-border-radius-left' onClick={this.showModalFilter}><FilterOutlined /></Button>
                                <Popover placement="bottom" content={sortComponent} trigger="click">
                                    <Button block className='btn-border-radius-right'><SortAscendingOutlined /> Sort</Button>
                                </Popover>
                            </Button.Group>
                        </Col>
                    </Row>

                    <Divider orientation="left"></Divider>

                    <Row>
                        <Col span={24}>
                            <Table
                                columns={[
                                    {
                                        title: 'No',
                                        dataIndex: 'rownum',
                                        width: '3%',
                                    },
                                    {
                                        title: 'Nama Guru',
                                        dataIndex: 'nama_lengkap',
                                        width: '20%',
                                        render: (a, row) => <List.Item style={{ padding: 0, minWidth: 200 }}>
                                            <List.Item.Meta
                                                title={row.nama_lengkap}
                                                description={row.status_kepegawaian === "PNS" ? <Tag color="blue"> {row.status_kepegawaian}</Tag> : <Tag color="orange"> {row.status_kepegawaian}</Tag>}
                                            />
                                        </List.Item>,
                                    },
                                    {
                                        title: 'Jenis Kelamin',
                                        dataIndex: 'jenis_kelamin',
                                    },
                                    {
                                        title: 'Tempat/Tgl Lahir',
                                        dataIndex: 'ttl',
                                        sorter: false,
                                        render: (a, row) => <List.Item style={{ padding: 0, minWidth: 100 }}>
                                            <List.Item.Meta
                                                title={row.tempat_lahir}
                                                description={row.tgl_lahir}
                                            />
                                        </List.Item>,
                                    },
                                    {
                                        title: 'NUPTK',
                                        dataIndex: 'nuptk',
                                    },
                                    {
                                        title: 'NPK',
                                        dataIndex: 'npk',
                                    },
                                    {
                                        title: 'Total JTM',
                                        dataIndex: 'total_jtm',
                                    },
                                    {
                                        title: <SettingOutlined />,
                                        key: 'operation',
                                        align: 'center',
                                        width: '2%',
                                        render: (a, row) => <Dropdown trigger={['click']} overlay={<Menu>
                                            {action.detail ? (<Menu.Item key={0}><Link to={"/tenaga-kependidikan/guru/detail/" + row.id}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                                            {action.delete ? (<Menu.Item key={1} onClick={this.onDelete.bind(this, row)}><CloseCircleOutlined /> Non Aktifkan</Menu.Item>) : ""}
                                        </Menu>}>
                                            <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                                        </Dropdown>,
                                    },
                                    {
                                        title: 'LogActivity',
                                        key: 'operation',
                                        width: '10%',
                                        render: (a, row) => _tableLogActivity(row),
                                    },
                                ]}
                                rowKey={record => record.id}
                                dataSource={table.data}
                                pagination={false}
                                loading={table.loading}
                                size="small"
                            />
                        </Col>
                        <Col xs={24} className="table-pagination">
                            <Pagination
                                size="small"
                                current={table.pagination.current}
                                pageSize={table.pagination.pageSize}
                                total={table.pagination.total}
                                showTotal={() => `Total ${table.pagination.total} items`}
                                showSizeChanger
                                onChange={(current, pageSize) => {
                                    const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                                    const params = getParamTable("change", table, null, null, pagination)
                                    this.fetch(params)
                                }}
                            />
                        </Col>
                    </Row>
                </div>

                {modalExport}

                {modalImport}

                {modalFilter}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Gtk);
