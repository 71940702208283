import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Radio } from 'antd';
import TextField from '@mui/material/TextField';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

const schema = {
    daftar_ulang_status: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};
const schemamMandatory = {
    daftar_ulang_status: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    daftar_ulang_keterangan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class DaftarUlang extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        let schemaValidation = schema
        if (values.daftar_ulang_status === "Mengundurkan Diri") {
            schemaValidation = schemamMandatory
        }
        const errors = validate(values, schemaValidation);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        let schemaValidation = schema
        if (values.daftar_ulang_status === "Mengundurkan Diri") {
            schemaValidation = schemamMandatory
        }
        const errors = validate(values, schemaValidation);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            daftar_ulang_status: values.daftar_ulang_status ? values.daftar_ulang_status : null,
            daftar_ulang_keterangan: values.daftar_ulang_keterangan ? values.daftar_ulang_keterangan : null,
        }

        let endpoint = "ppdb/siswa/daftar-ulang"
        let method = "POST"
        if (values.id) {
            endpoint = "ppdb/siswa/daftar-ulang/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Status Daftar Ulang"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <label className='input-label' style={{ marginLeft: 0, left: 0 }}>Status Daftar Ulang *</label>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group
                                name="daftar_ulang_status"
                                value={values.daftar_ulang_status ? values.daftar_ulang_status : "0"}
                                onChange={(e) => {
                                    this.handleChangeSetValue("daftar_ulang_status", e.target.value)
                                }}
                            >
                                <Radio value={"Terdaftar"}>Terdaftar</Radio>
                                <Radio value={"Mengundurkan Diri"}>Mengundurkan Diri</Radio>
                            </Radio.Group>
                        </div>
                        <span className='input-error'>{errors.daftar_ulang_status && errors.daftar_ulang_status.length > 0 ? errors.daftar_ulang_status[0] : ""}</span>
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            label={<>Keterangan{values.daftar_ulang_status === "Mengundurkan Diri" ? " *" : ""}</>}
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="daftar_ulang_keterangan"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("daftar_ulang_keterangan", e.target.value)
                            }}
                            value={values.daftar_ulang_keterangan || ''}
                            error={this.hasError('daftar_ulang_keterangan')}
                            helperText={
                                this.hasError('daftar_ulang_keterangan') ? errors.daftar_ulang_keterangan[0] : null
                            }
                        />
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default DaftarUlang;
