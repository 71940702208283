import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _setAxios, _validationFormMsg, _success } from '../../../lib/Helper';
import { kategoriGeografisOptions, wilayahOptions, potensiWilayahOptions, jarakKeLokasiOptions } from '../../../data/options';

import { Breadcrumb, Table, Row, Col, Divider, Button, PageHeader, Badge, Checkbox, message } from 'antd';
import { SaveOutlined, DoubleRightOutlined } from '@ant-design/icons';
import AuthRedirect from '../../../components/AuthRedirect'
import validate from 'validate.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Link } from 'react-router-dom';

const schema = {
    nama: {
        presence: { allowEmpty: false, message: 'harus diisi' },
    }
}

class LahanCrete extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loadingBtn: false,

            ddl: {
                regions: [],
                villages: [],
                lahanKepemilikan: [],
                lahanPenggunaan: [],
            },
        };
    }

    componentDidMount() {
        console.disableYellowBox = true;

        this.getLahanStatusDDL()
    }

    // DDL START ------------------------------------------------------------------------------------------------------------------------

    getRegionDDL = (search = "") => {
        _setAxios("region", "POST", {
            "pagination": {
                "current": 1,
                "pageSize": 10
            },
            "search": search
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        regions: resp.data.list
                    }
                });
            }
        })
    }

    getVillageDDL = (districtID) => {
        _setAxios("region/" + districtID, "POST", {
            pagination: {
                current: 1,
                pageSize: 30
            },
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        villages: resp.data.data
                    }
                });
            }
        })
    }

    getLahanStatusDDL = () => {
        _setAxios("lahan-status/tipe/Lahan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        lahanKepemilikan: resp.data.data
                    }
                });
            }
        })
        _setAxios("lahan-status/tipe/Penggunaan", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        lahanPenggunaan: resp.data.data
                    }
                });
            }
        })
    }


    // DDL END ---------------------------------------------------------------------------------------------------------------------------



    // HANDLE FORM START ---------------------------------------------------------------------------------------------------------------------------
    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;
        if (name.includes(".")) {
            const result = this.setTotal(values, name, value)
            if (!result) {
                return
            }
        }

        this.setForm(values, name, value)
    };

    setTotal = (values, name, value) => {
        const nameAr = name.split(".");
        if (nameAr.length >= 3) {
            const id = nameAr[0]
            const typeExists = nameAr[1]
            const nameExists = nameAr[2]
            const nameTotal = id + "." + typeExists + ".total"
            let valueSubTotal = 0
            value = value === "" ? 0 : value

            if (nameExists === "bersertifikat") {
                valueSubTotal = parseInt(value) + parseInt(values[id + "." + typeExists + ".tidak_bersertifikat"] ? values[id + "." + typeExists + ".tidak_bersertifikat"] : 0)
            } else if (nameExists === "tidak_bersertifikat") {
                valueSubTotal = parseInt(value) + parseInt(values[id + "." + typeExists + ".bersertifikat"] ? values[id + "." + typeExists + ".bersertifikat"] : 0)
            }

            this.setForm(values, nameTotal, valueSubTotal)

            let kepemilikan_total = 0
            let penggunaan_total = 0
            for (var key in values) {
                if (!key.includes(".total")) { continue };

                const keyAr = key.split(".");
                if (keyAr.length >= 3) {
                    const typeExists = keyAr[1]

                    if (typeExists === "kepemilikan") kepemilikan_total += values[key]
                    if (typeExists === "penggunaan") penggunaan_total += values[key]
                }
            }

            if(kepemilikan_total < penggunaan_total){
                message.error('Total luas penggunaan lahan tidak boleh melebihi total luas lahan.');
                return false
            }

            this.setForm(values, "kepemilikan_total", kepemilikan_total)
            this.setForm(values, "penggunaan_total", penggunaan_total)

            return true
        }
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;

        if (name === "region") {
            values.village = null;

            if (value) {
                this.getVillageDDL(value.kecamatan_id)
            }
        }

        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setValidate(values)
    }

    setValidate = (values) => {

        const errors = validate(values, schema);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }
    // HANDLE FORM END ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


    onSubmit = () => {
        const { values } = this.state;

        //Validation Siswa
        let validation = this.setValidate(values)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }


        const kepemilikan = []
        const penggunaan = []
        const lahanStatusIDs = []

        for (var key in values) {
            if (!key.includes(".")) { continue };

            const keyAr = key.split(".");
            if (keyAr.length >= 3) {
                const id = keyAr[0]
                const typeExists = keyAr[1]
                const name = keyAr[2]


                if (typeExists === "kepemilikan") {
                    if (lahanStatusIDs.includes(id)) {
                        for (let i = 0; i < kepemilikan.length; i++) {
                            if (kepemilikan[i].lahan_status_id === id) {
                                if (name === "bersertifikat") {
                                    kepemilikan[i].bersertifikat = values[key] ? values[key] : 0
                                }
                                if (name === "tidak_bersertifikat") {
                                    kepemilikan[i].tidak_bersertifikat = values[key] ? values[key] : 0
                                }
                                if (name === "total") {
                                    kepemilikan[i].total = values[key] ? values[key] : 0
                                }
                            }
                        }
                    } else {
                        lahanStatusIDs.push(id)

                        kepemilikan.push({
                            lahan_status_id: id,
                            bersertifikat: name === "bersertifikat" ? (values[key] ? values[key] : 0) : 0,
                            tidak_bersertifikat: name === "tidak_bersertifikat" ? (values[key] ? values[key] : 0) : 0,
                            total: name === "total" ? (values[key] ? values[key] : 0) : 0,
                        })
                    }
                } else if (typeExists === "penggunaan") {
                    if (lahanStatusIDs.includes(id)) {
                        for (let i = 0; i < penggunaan.length; i++) {
                            if (penggunaan[i].lahan_status_id === id) {
                                if (name === "bersertifikat") {
                                    penggunaan[i].bersertifikat = values[key] ? values[key] : 0
                                }
                                if (name === "tidak_bersertifikat") {
                                    penggunaan[i].tidak_bersertifikat = values[key] ? values[key] : 0
                                }
                                if (name === "total") {
                                    penggunaan[i].total = values[key] ? values[key] : 0
                                }
                            }
                        }
                    } else {
                        lahanStatusIDs.push(id)

                        penggunaan.push({
                            lahan_status_id: id,
                            bersertifikat: name === "bersertifikat" ? (values[key] ? values[key] : 0) : 0,
                            tidak_bersertifikat: name === "tidak_bersertifikat" ? (values[key] ? values[key] : 0) : 0,
                            total: name === "total" ? (values[key] ? values[key] : 0) : 0,
                        })
                    }
                }
            }
        }

        const params = {
            master: {
                provinsi_id: values.region ? values.region.provinsi_id : null,
                kabkot_id: values.region ? values.region.kabkot_id : null,
                kecamatan_id: values.region ? values.region.kecamatan_id : null,
                kelurahan_id: values.village ? values.village.id : null,
                nama: values.nama ? values.nama : null,
                kode_pos: values.kode_pos ? values.kode_pos : null,
                alamat: values.alamat ? values.alamat : null,

                // luas_lahan: values.kepemilikan_total ? values.kepemilikan_total : null,
                // luas_digunakan: values.penggunaan_total ? values.penggunaan_total : null,
                // luas_sblm_digunakan: values.luas_sblm_digunakan ? values.luas_sblm_digunakan : null,

                status: values.status ? values.status : null,
                kategori_geografis: values.kategori_geografis ? values.kategori_geografis.value : null,
                wilayah: values.wilayah ? values.wilayah.value : null,
                potensi_wilayah: values.potensi_wilayah ? values.potensi_wilayah.value : null,
                lat: values.lat ? values.lat : null,
                long: values.long ? values.long : null,
                jarak_ke_ibukota: values.jarak_ke_ibukota ? values.jarak_ke_ibukota.value : null,
                jarak_ke_kota: values.jarak_ke_kota ? values.jarak_ke_kota.value : null,
                jarak_ke_kecamatan: values.jarak_ke_kecamatan ? values.jarak_ke_kecamatan.value : null,
                jarak_ke_kanwil_propinsi: values.jarak_ke_kanwil_propinsi ? values.jarak_ke_kanwil_propinsi.value : null,
                jarak_ke_kanwil_kota: values.jarak_ke_kanwil_kota ? values.jarak_ke_kanwil_kota.value : null,
                jarak_ke_ra: values.jarak_ke_ra ? values.jarak_ke_ra.value : null,
                jarak_ke_rd: values.jarak_ke_rd ? values.jarak_ke_rd.value : null,
                jarak_ke_mi: values.jarak_ke_mi ? values.jarak_ke_mi.value : null,
                jarak_ke_mts: values.jarak_ke_mts ? values.jarak_ke_mts.value : null,
                jarak_ke_ma: values.jarak_ke_ma ? values.jarak_ke_ma.value : null,
                jarak_ke_sd: values.jarak_ke_sd ? values.jarak_ke_sd.value : null,
                jarak_ke_smp: values.jarak_ke_smp ? values.jarak_ke_smp.value : null,
                jarak_ke_sma: values.jarak_ke_sma ? values.jarak_ke_sma.value : null,
                jarak_ke_pontren: values.jarak_ke_pontren ? values.jarak_ke_pontren.value : null,
                jarak_ke_ptki: values.jarak_ke_ptki ? values.jarak_ke_ptki.value : null,
            },
            detail: kepemilikan.concat(penggunaan)
        }

        this.setState({
            loadingBtn: true
        });

        _setAxios("lahan", "POST", params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', 'Data berhasil disimpan')
                setTimeout(() => {
                    window.location.href = "/sarana-prasarana/lahan"
                }, 1000);
            } else {
                this.setState({
                    loadingBtn: false
                });
            }
        })


    }

    render() {
        const access = this.props.privilege.access["/sarana-prasarana/lahan"]
        const action = access.action
        if (!isAuth(this.props.privilege) || access === undefined || !action.create) {
            return <AuthRedirect />
        }
        const { values, errors, ddl } = this.state;

        const columnsKepemilikan = [
            {
                title: 'Status Kepemilikan',
                dataIndex: 'nama',
                width: '30%',
            },
            {
                title: 'Bersertifikat (m2)',
                render: (a, row) => <TextField
                    fullWidth
                    label="(m2)"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={row.id + ".kepemilikan.bersertifikat"}
                    type="number"
                    onChange={this.handleChange}
                    value={values[row.id + ".kepemilikan.bersertifikat"] ? values[row.id + ".kepemilikan.bersertifikat"] : ""}
                />,
            },
            {
                title: 'Tidak Bersertifikat (m2)',
                render: (a, row) => <TextField
                    fullWidth
                    label="(m2)"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={row.id + ".kepemilikan.tidak_bersertifikat"}
                    type="number"
                    onChange={this.handleChange}
                    value={values[row.id + ".kepemilikan.tidak_bersertifikat"] ? values[row.id + ".kepemilikan.tidak_bersertifikat"] : ""}
                />,

            },
            {
                title: 'Total (m2)',
                render: (a, row) => <TextField
                    fullWidth
                    label="(m2)"
                    size="small"
                    name={row.id + ".kepemilikan.total"}
                    type="number"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={(values[row.id + ".kepemilikan.total"] ? values[row.id + ".kepemilikan.total"] : 0)}
                />,
            },
        ];

        const columnsPenggunaan = [
            {
                title: 'Status Kepemilikan',
                dataIndex: 'nama',
                width: '30%',
            },
            {
                title: 'Bersertifikat (m2)',
                render: (a, row) => <TextField
                    fullWidth
                    label="(m2)"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={row.id + ".penggunaan.bersertifikat"}
                    type="number"
                    onChange={this.handleChange}
                    value={values[row.id + ".penggunaan.bersertifikat"] ? values[row.id + ".penggunaan.bersertifikat"] : ""}
                />,
            },
            {
                title: 'Tidak Bersertifikat (m2)',
                render: (a, row) => <TextField
                    fullWidth
                    label="(m2)"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    name={row.id + ".penggunaan.tidak_bersertifikat"}
                    type="number"
                    onChange={this.handleChange}
                    value={values[row.id + ".penggunaan.tidak_bersertifikat"] ? values[row.id + ".penggunaan.tidak_bersertifikat"] : ""}
                />,

            },
            {
                title: 'Total (m2)',
                render: (a, row) => <TextField
                    fullWidth
                    label="(m2)"
                    size="small"
                    name={row.id + ".penggunaan.total"}
                    type="number"
                    InputProps={{
                        readOnly: true,
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={(values[row.id + ".penggunaan.total"] ? values[row.id + ".penggunaan.total"] : 0)}
                />,
            },
        ];

        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Sarana Prasarana</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/sarana-prasarana/lahan`}>Lahan</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Tambah</Breadcrumb.Item>
                    </Breadcrumb>}
                />

                <Divider className='breadcrumb-line' orientation="left"></Divider>
                <PageHeader
                    className="site-page-header"
                    title="Lahan"
                    subTitle="tambah"
                />
                <Badge.Ribbon style={{ margin: 8 }} text={<Checkbox onChange={(e) => {
                    let status = ""
                    if (e.target.checked) {
                        status = "LAHAN UTAMA"
                    }
                    this.handleChangeSetValue("status", status)
                }} name="status" value={values.status || ''} style={{ color: "#fff" }}>Jadikan Lahan Utama</Checkbox>}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <Divider orientation="left">Data Lahan</Divider>
                                    </Col>
                                    <Col xs={24}>
                                        <TextField
                                            fullWidth
                                            label="Nama Lahan *"
                                            size="small"
                                            name="nama"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.nama || ''}
                                            error={this.hasError('nama')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                this.hasError('nama') ? errors.nama[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <Autocomplete
                                            options={ddl.regions}
                                            value={values.region || null}
                                            getOptionLabel={(option) =>
                                                option.provinsi_nama + ", " + option.kabkot_nama + ", " + option.kecamatan_nama
                                            }
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("region", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Provinsi, Kota & Kecamatan *"
                                                size="small"
                                                name="region"
                                                type="text"
                                                error={this.hasError('region')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    const { value } = e.target;
                                                    this.getRegionDDL(value)
                                                }}
                                                helperText={
                                                    this.hasError('region') ? errors.region[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Autocomplete
                                            options={ddl.villages}
                                            value={values.village || null}
                                            getOptionLabel={(option) =>
                                                option.nama
                                            }
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("village", newValue)
                                            }}
                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kelurahan / Desa *"
                                                size="small"
                                                name="village"
                                                type="text"
                                                error={this.hasError('village')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                helperText={
                                                    this.hasError('village') ? errors.village[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Kode Pos"
                                            size="small"
                                            name="kode_pos"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.kode_pos || ''}
                                            error={this.hasError('kode_pos')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                this.hasError('kode_pos') ? errors.kode_pos[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <TextField
                                            multiline
                                            fullWidth
                                            label="Alamat"
                                            size="small"
                                            minRows={3}
                                            name="alamat"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.alamat || ''}
                                            error={this.hasError('alamat')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            helperText={
                                                this.hasError('alamat') ? errors.alamat[0] : null
                                            }
                                        />
                                    </Col>

                                    <Col xs={24}>
                                        <Divider orientation="left">Letak Lahan</Divider>
                                    </Col>

                                    <Col xs={24}>
                                        <Autocomplete
                                            options={kategoriGeografisOptions}
                                            value={values.kategori_geografis || null}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("kategori_geografis", newValue ? newValue : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Kategori Geografis"
                                                size="small"
                                                name="kategori_geografis"
                                                type="text"
                                                error={this.hasError('kategori_geografis')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                helperText={
                                                    this.hasError('kategori_geografis') ? errors.kategori_geografis[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <Autocomplete
                                            options={wilayahOptions}
                                            value={values.wilayah || null}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("wilayah", newValue ? newValue : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Wilayah"
                                                size="small"
                                                name="wilayah"
                                                type="text"
                                                error={this.hasError('wilayah')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                helperText={
                                                    this.hasError('wilayah') ? errors.wilayah[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <Autocomplete
                                            options={potensiWilayahOptions}
                                            value={values.potensi_wilayah || null}
                                            onChange={(e, newValue) => {
                                                this.handleChangeSetValue("potensi_wilayah", newValue ? newValue : null)
                                            }}

                                            renderInput={(params) => <TextField {...params}
                                                fullWidth
                                                label="Potensi potensi_wilayah"
                                                size="small"
                                                name="potensi_wilayah"
                                                type="text"
                                                error={this.hasError('potensi_wilayah')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                helperText={
                                                    this.hasError('potensi_wilayah') ? errors.potensi_wilayah[0] : null
                                                }
                                            />}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <Divider orientation="left">Koordinat</Divider>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Latitude"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="lat"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.lat || ''}
                                            error={this.hasError('lat')}
                                            helperText={
                                                this.hasError('lat') ? errors.lat[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <TextField
                                            fullWidth
                                            label="Longitude"
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            name="long"
                                            type="text"
                                            onChange={this.handleChange}
                                            value={values.long || ''}
                                            error={this.hasError('long')}
                                            helperText={
                                                this.hasError('long') ? errors.long[0] : null
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <iframe title={"unik"}
                                            src={"https://maps.google.com/maps?q=" + (values.lat ? values.lat : -7.052652) + "," + (values.long ? values.long : 112.5685274) + "&hl=id&z=14&output=embed"}
                                            style={{ width: "100%", border: 0, borderRadius: 4 }}
                                            height="440" loading="lazy">
                                        </iframe>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Badge.Ribbon>

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Divider orientation="left">Luas Lahan</Divider>
                                </Col>
                                <Col xs={24}>
                                    <Table
                                        columns={columnsKepemilikan}
                                        dataSource={ddl.lahanKepemilikan}
                                        footer={() => <Row gutter={[16, 16]} justify='end'>
                                            <Col xs={12} sm={20}>
                                                Total
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                {values.kepemilikan_total}
                                            </Col>
                                        </Row>}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Row gutter={[16, 16]}>
                                <Col key={0} xs={24}>
                                    <Divider orientation="left">Penggunaan Lahan</Divider>
                                </Col>
                                <Col key={1} xs={24}>
                                    <Table
                                        columns={columnsPenggunaan}
                                        dataSource={ddl.lahanPenggunaan}
                                        footer={() => <Row gutter={[16, 16]} justify='end'>
                                            <Col xs={12} sm={20}>
                                                Total
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                {values.penggunaan_total}
                                            </Col>
                                        </Row>}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Divider orientation="left">Jarak ke Lokasi Tertentu</Divider>
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_ibukota || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_ibukota", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke Pusat Ibu Kota Provinsi"
                                    size="small"
                                    name="jarak_ke_ibukota"
                                    type="text"
                                    error={this.hasError('jarak_ke_ibukota')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_ibukota') ? errors.jarak_ke_ibukota[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_ra || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_ra", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke RA Terdekat"
                                    size="small"
                                    name="jarak_ke_ra"
                                    type="text"
                                    error={this.hasError('jarak_ke_ra')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_ra') ? errors.jarak_ke_ra[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_sd || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_sd", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke SD Terdekat"
                                    size="small"
                                    name="jarak_ke_sd"
                                    type="text"
                                    error={this.hasError('jarak_ke_sd')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_sd') ? errors.jarak_ke_sd[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_kota || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_kota", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke Pusat Kab/Kota"
                                    size="small"
                                    name="jarak_ke_kota"
                                    type="text"
                                    error={this.hasError('jarak_ke_kota')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_kota') ? errors.jarak_ke_kota[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_rd || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_rd", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke RD Terdekat"
                                    size="small"
                                    name="jarak_ke_rd"
                                    type="text"
                                    error={this.hasError('jarak_ke_rd')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_rd') ? errors.jarak_ke_rd[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_smp || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_smp", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke SMP Terdekat"
                                    size="small"
                                    name="jarak_ke_smp"
                                    type="text"
                                    error={this.hasError('jarak_ke_smp')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_smp') ? errors.jarak_ke_smp[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_kecamatan || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_kecamatan", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke Pusat Kecamatan"
                                    size="small"
                                    name="jarak_ke_kecamatan"
                                    type="text"
                                    error={this.hasError('jarak_ke_kecamatan')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_kecamatan') ? errors.jarak_ke_kecamatan[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_mi || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_mi", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke MI Terdekat"
                                    size="small"
                                    name="jarak_ke_mi"
                                    type="text"
                                    error={this.hasError('jarak_ke_mi')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_mi') ? errors.jarak_ke_mi[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_sma || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_sma", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke SMA Terdekat"
                                    size="small"
                                    name="jarak_ke_sma"
                                    type="text"
                                    error={this.hasError('jarak_ke_sma')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_sma') ? errors.jarak_ke_sma[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_kanwil_propinsi || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_kanwil_propinsi", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke KanWil Kemenag Provinsi"
                                    size="small"
                                    name="jarak_ke_kanwil_propinsi"
                                    type="text"
                                    error={this.hasError('jarak_ke_kanwil_propinsi')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_kanwil_propinsi') ? errors.jarak_ke_kanwil_propinsi[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_mts || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_mts", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke MTS Terdekat"
                                    size="small"
                                    name="jarak_ke_mts"
                                    type="text"
                                    error={this.hasError('jarak_ke_mts')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_mts') ? errors.jarak_ke_mts[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_pontren || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_pontren", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke Pontren Terdekat"
                                    size="small"
                                    name="jarak_ke_pontren"
                                    type="text"
                                    error={this.hasError('jarak_ke_pontren')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_pontren') ? errors.jarak_ke_pontren[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.belum_ada || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("belum_ada", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke Kantor Kemenag Kab/Kota"
                                    size="small"
                                    name="belum_ada"
                                    type="text"
                                    error={this.hasError('belum_ada')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('belum_ada') ? errors.belum_ada[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_ma || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_ma", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke MA Terdekat"
                                    size="small"
                                    name="jarak_ke_ma"
                                    type="text"
                                    error={this.hasError('jarak_ke_ma')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_ma') ? errors.jarak_ke_ma[0] : null
                                    }
                                />}
                            />
                        </Col>
                        <Col span={8}>
                            <Autocomplete
                                options={jarakKeLokasiOptions}
                                value={values.jarak_ke_ptki || null}
                                onChange={(e, newValue) => {
                                    this.handleChangeSetValue("jarak_ke_ptki", newValue ? newValue : null)
                                }}

                                renderInput={(params) => <TextField {...params}
                                    fullWidth
                                    label="Ke PTKAI Terdekat"
                                    size="small"
                                    name="jarak_ke_ptki"
                                    type="text"
                                    error={this.hasError('jarak_ke_ptki')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    helperText={
                                        this.hasError('jarak_ke_ptki') ? errors.jarak_ke_ptki[0] : null
                                    }
                                />}
                            />
                        </Col>
                    </Row>
                </div>

                <div className="site-layout-background" style={{ padding: 24 }}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Button style={{ margin: 0 }} loading={this.state.loadingBtn} onClick={() => {
                                this.props.history.goBack();
                            }}>

                                Batal
                            </Button>
                            <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={this.state.loadingBtn} onClick={this.onSubmit}>
                                Simpan
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(LahanCrete);
