import React from 'react';
import { Row, Col, Divider, List, Tag, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DownCircleOutlined, DownloadOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from '@material-ui/core';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)}
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={row.nama_struktural ? row.nama_struktural : "-"}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Periode: {row.periode ? row.periode : "-"} <br />
                              File: {row.file ? <a target="_blank" rel="noopener noreferrer" download={true} href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a> : "-"} <br />
                            </small>
                          </Col>
                          <Col xs={24}>
                            {row.status === "Aktif" ? <Tag color="blue" icon={<CheckCircleOutlined />}>Aktif</Tag> : <Tag color="red" icon={<CloseCircleOutlined />}>{row.status}</Tag>}
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      <Dropdown trigger={['click']} overlay={<Menu>
                        {action.detail ? (<Menu.Item key={0}><Link to={`struktur-organisasi/detail/${row.id}`}><InfoCircleOutlined /> Detail</Link></Menu.Item>) : ""}
                        {action.update ? (<Menu.Item key={1} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                        {action.delete ? (<Menu.Item key={2} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

export default Mobile;
