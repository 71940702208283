import React from 'react';
import { Table, Row, Col, Pagination, Tag } from 'antd';
import { CloseCircleOutlined, SendOutlined, CheckCircleOutlined, FileDoneOutlined, FileSyncOutlined, IssuesCloseOutlined, DownloadOutlined } from '@ant-design/icons';
import { getParamTable } from '../../../../lib/Helper';

const statusLabels = {
  "Draft": "yellow",
  "Diajukan": "",
  "Disetujui": "blue",
  "Ditolak": "red",
  "Dibatalkan": "red",
  "Selesai": "green",
}

const statusIcons = {
  "Draft": <FileSyncOutlined />,
  "Diajukan": <SendOutlined style={{ transform: "rotate(-30deg)" }} />,
  "Disetujui": <CheckCircleOutlined />,
  "Ditolak": <CloseCircleOutlined />,
  "Dibatalkan": <IssuesCloseOutlined />,
  "Selesai": <FileDoneOutlined />,
}

class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {

    };
  }

  componentDidMount() {
    this.props.fetchTable()
  }

  render() {
    const { table } = this.props.state
    // const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: 'No',
                  dataIndex: 'rownum',
                  sorter: false,
                  width: '2%',
                }, {
                  title: 'Tanggal Pinjam',
                  dataIndex: '',
                  sorter: false,
                  render: (a, row) => <>
                    {row.tgl_awal} <br />
                    to <br />
                    {row.tgl_akhir} <br />
                  </>,
                }, {
                  title: 'Peminjam',
                  dataIndex: '',
                  sorter: false,
                  render: (a, row) => (row.gelar_depan ? row.gelar_depan + " " : "") + (row.tk_nama ? row.tk_nama : "-") + (row.gelar_belakang ? " " + row.gelar_belakang : "")
                }, {
                  title: 'Ruangan',
                  dataIndex: 'ruangan_nama',
                  sorter: false,
                }, {
                  title: 'Lahan/Gedung',
                  dataIndex: '',
                  sorter: false,
                  render: (a, row) => <>
                    {row.lahan_nama}, <br />
                    {row.gedung_nama} <br />
                  </>,
                }, {
                  title: 'Status',
                  dataIndex: 'status',
                  sorter: false,
                  render: (a, row) => <Tag style={{ width: "100%" }} color={statusLabels[row.status]} icon={statusIcons[row.status]}>{row.status}</Tag>
                }, {
                  title: 'Keterangan',
                  dataIndex: 'keterangan',
                  sorter: false,
                }, {
                  title: 'File',
                  dataIndex: 'file',
                  render: (a, row) => <a target="_blank" rel="noopener noreferrer" href={row.main_path + row.file} style={{ fontSize: 11 }}>Download <DownloadOutlined /></a>,
                }
              ]}
              rowKey={record => record.id}
              dataSource={table.data}
              pagination={false}
              loading={table.loading}
              size="small"
            />
          </Col>
          <Col xs={24} className="table-pagination">
            <Pagination
              size="small"
              current={table.pagination.current}
              pageSize={table.pagination.pageSize}
              total={table.pagination.total}
              showTotal={() => `Total ${table.pagination.total} items`}
              showSizeChanger
              onChange={(current, pageSize) => {
                const pagination = { current: current, pageSize: pageSize, total: table.pagination.total }
                const params = getParamTable("change", table, null, null, pagination)
                this.props.fetch(params)
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

export default Desktop;
