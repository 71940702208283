import React from 'react';
import { connect } from 'react-redux'
import { Row, Col, PageHeader, BackTop, Breadcrumb, Button, Divider } from 'antd';
import { isAuth } from '../../../../lib/Helper';
import AuthRedirect from '../../../../components/AuthRedirect'
import Waktu from '../modal/Waktu';
import Radius from '../modal/Radius';
import Coordinate from '../modal/Coordinate';
import { DoubleRightOutlined } from '@ant-design/icons';

class ConfAbsensi extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      waktu: {
        visible: false
      },
      radius: {
        visible: false
      },
      coordinate: {
        visible: false
      },
    };
  }

  modalWaktu = (visible = false) => {
    this.setState({
      waktu: {
        visible: visible
      },
    })
  }
  
  modalRadius = (visible = false) => {
    this.setState({
      radius: {
        visible: visible
      },
    })
  }
  
  modalCoordinate = (visible = false) => {
    this.setState({
      coordinate: {
        visible: visible
      },
    })
  }

  render() {

    const access = this.props.privilege.access["/configuration/absensi"]
    const action = access.action

    if (!isAuth(this.props.privilege) || access === undefined || !action.update) {
      return <AuthRedirect />
    }

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Konfigurasi</Breadcrumb.Item>
            <Breadcrumb.Item>Absensi</Breadcrumb.Item>
          </Breadcrumb>}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>

          <PageHeader
            className="site-page-header"
            title="Konfigurasi Absensi"
          />
          <Row gutter={[16, 16]}>

            <Col xs={24} md={8} style={{ textAlign: "center" }}>
              {/* <FieldTimeOutlined style={{fontSize: 70, color: "#ddd"}} /> */}
              <Divider />
              <Button type="primary" size="large" onClick={this.modalWaktu.bind(this, true)}>
                Setting Waktu
              </Button>
            </Col>
            <Col xs={24} md={8} style={{ textAlign: "center" }}>
              {/* <NodeIndexOutlined style={{fontSize: 70, color: "#ddd"}} /> */}
              <Divider />
              <Button type="primary" size="large" onClick={this.modalRadius.bind(this, true)}>
                Setting Radius
              </Button>
            </Col>
            <Col xs={24} md={8} style={{ textAlign: "center" }}>
              {/* <FieldTimeOutlined style={{fontSize: 70, color: "#ddd"}} /> */}
              <Divider />
              <Button type="primary" size="large" onClick={this.modalCoordinate.bind(this, true)}>
                Setting Koordinat
              </Button>
            </Col>
          </Row>

          {
            this.state.waktu.visible ?
              <Waktu
                visible={this.state.waktu.visible}
                hideModal={this.modalWaktu.bind(this, false)}
              /> : ""
          }
          {
            this.state.radius.visible ?
              <Radius
                visible={this.state.radius.visible}
                hideModal={this.modalRadius.bind(this, false)}
              /> : ""
          }
          {
            this.state.coordinate.visible ?
              <Coordinate
                visible={this.state.coordinate.visible}
                hideModal={this.modalCoordinate.bind(this, false)}
              /> : ""
          }
          <BackTop />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ConfAbsensi);
