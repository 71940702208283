import React from 'react';
import { connect } from 'react-redux'
import { _setAxios, _validationFormMsg, _success, _getDate } from '../../lib/Helper';
import { lembagaStatusOptions, lembagaJenisOptions, lembagaWaktuBelajarOptions, lembagaStatusKelompokOptions, lembagaKomiteOptions, lembagaKategoriOptions } from '../../data/options';
import { schemaIdentitas } from './schema';
import validate from 'validate.js';
import { Row, Col, Divider, Alert, message, Button, Checkbox, Tooltip, List, Empty } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Preload from '../../components/preload/PreloadContent'
import PreloadContent from '../../components/preload/PreloadContent';

const tahunAjaran = localStorage.getItem('persist:tahun_ajaran') ? JSON.parse(localStorage.getItem('persist:tahun_ajaran')) : null
const tahunAjaranIDDefault = tahunAjaran ? tahunAjaran.id : null

class Identitas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ddl: {
                regions: [],
                villages: [],
            },
            values: {},
            errors: {},
            id: null,

            previewImage: '',
            previewTitle: '',
            fileList: [],
            visible: false,
            validateImage: false,

            loadingBtn: false,
            loading: false,
            isUpdate: false,
            tipe: null,

            data1: [],
            data2: []
        };
    }

    componentDidMount() {
        this.getIdentitas(tahunAjaranIDDefault)
    }

    setIdentitas = (data) => {
        this.setState({
            values: data,
            loading: false,
            isUpdate: false,
            id: data.id,
            tipe: "Update",
            data1: [{
                title: 'NPSN',
                description: data.npsn ? data.npsn : "-"
            }, {
                title: 'NSM',
                description: data.nsm ? data.nsm : "-"
            }, {
                title: 'Nama Lembaga',
                description: data.nama ? data.nama : "-"
            }, {
                title: 'Nama Singkatan',
                description: data.singkatan ? data.singkatan : "-"
            }, {
                title: 'Status Lembaga',
                description: data.status ? data.status : "-"
            }, {
                title: 'Tahun Berdiri',
                description: data.tahun_berdiri ? data.tahun_berdiri : "-"
            }, {
                title: 'No. SK Pendirian/SK Kenegerian',
                description: data.no_sk ? data.no_sk : "-"
            }, {
                title: 'Tanggal SK Pendirian/SK Kenegerian',
                description: data.tgl_sk ? data.tgl_sk : "-"
            }, {
                title: 'Kode Satker Anggaran',
                description: data.kode_satker_anggaran ? data.kode_satker_anggaran : "-"
            }, {
                title: 'NO. DIPA',
                description: data.ni_dipa ? data.ni_dipa : "-"
            }, {
                title: 'Penempatan Dipa',
                description: data.penempatan_dipa ? data.penempatan_dipa : "-"
            }, {
                title: 'Nama Wajib Pajak',
                description: data.nama_wajib_pajak ? data.nama_wajib_pajak : "-"
            }, {
                title: 'NPWP',
                description: data.npwp ? data.npwp : "-"
            }],
            data2: [{
                title: 'Waktu Belajar',
                description: data.waktu_belajar ? data.waktu_belajar : "-"
            }, {
                title: 'Status Kelompok Kerja Madrasah (KKM)',
                description: data.status_kelompok ? data.status_kelompok : "-"
            }, {
                title: 'Total Anggota',
                description: data.total_anggota ? data.total_anggota : "-"
            }, {
                title: 'Komite Lembaga',
                description: data.komite ? data.komite : "-"
            }, {
                title: 'Kategori Madrasah',
                description: data.kategori ? data.kategori : "-"
            }, {
                title: 'Apakah madrasah mempunyai dokumen e-RKAM?',
                description: data.e_rkam ? data.e_rkam : "Tidak"
            }, {
                title: 'Apakah madrasah berada di lingkungan pesantren?',
                description: data.lingkungan_pesantren ? data.lingkungan_pesantren : "Tidak"
            }, {
                title: 'No. Telepon',
                description: data.no_telp ? data.no_telp : "-"
            }, {
                title: 'E-Mail',
                description: data.email ? data.email : "-"
            }, {
                title: 'Website',
                description: data.website ? data.website : "-"
            }, {
                title: 'Fax',
                description: data.fax ? data.fax : "-"
            }],
        })
    }

    getIdentitas = (id) => {
        this.setState({
            loading: true
        })
        _setAxios("lembaga/identitas/semester/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                if (data.tipe === "Update") {
                    this.setIdentitas(data)
                } else {
                    this.setState({
                        tipe: data.tipe
                    })
                }
            }
            this.setState({
                loading: false
            })
        })
    }

    getIdentitasByID = (id) => {
        this.setState({
            loading: true
        })
        _setAxios("lembaga/identitas_by_id/" + id, "GET").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                if (data.tipe === "Update") {
                    this.setIdentitas(data)
                } else {
                    this.setState({
                        tipe: data.tipe
                    })
                }
            }
            this.setState({
                loading: false
            })
        })
    }

    copyIdentitasLastData = (id) => {
        this.setState({
            loading: true
        })
        _setAxios("lembaga/identitas/copy-data/" + id, "POST").then(resp => {
            if (resp.status === true) {
                const data = resp.data.data
                this.setIdentitas(data)
                _success('topRight', 'Success', resp.data.message)
            } else {
                this.setState({
                    loading: false
                })
            }
        })
    }

    getRegionDDL = (search = "") => {
        _setAxios("region", "POST", {
            pagination: {
                current: 1,
                pageSize: 10
            },
            search: search
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        regions: resp.data.list
                    }
                });
            }
        })
    }

    getVillageDDL = (districtID) => {
        _setAxios("region/" + districtID, "POST", {
            pagination: {
                current: 1,
                pageSize: 30
            },
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        villages: resp.data.data
                    }
                });
            }
        })
    }

    handleChange = (e) => {
        const { values } = this.state;
        const { name, value } = e.target;

        this.setForm(values, name, value)
    };

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;

        if (name === "region") {
            values["village"] = null;
            if (value) {
                this.getVillageDDL(value.kecamatan_id)
            }
        }

        this.setForm(values, name, value)
    };

    setForm = (values, name, value, row = {}) => {
        values[name] = value;

        const errors = validate(values, schemaIdentitas);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return this.setValidate(values)
    }

    setValidate = (values) => {
        const errors = validate(values, schemaIdentitas);
        this.setState({
            values: values,
            errors: errors || {},
        });

        return errors
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    onSubmitForm = async () => {
        const { values } = this.state;

        //Validation Siswa
        let validation = this.setValidate(values, schemaIdentitas)
        if (validation !== undefined) {
            return _validationFormMsg(validation)
        }

        // Tahun
        let thnBerdiri = values.tahun_berdiri ? values.tahun_berdiri : null
        if (values.tahun_berdiri && values.tahun_berdiri instanceof Date) {
            thnBerdiri = values.tahun_berdiri.getFullYear()
        }

        const params = {
            tahun_ajaran_id: tahunAjaranIDDefault,
            npsn: values.npsn ? values.npsn : null,
            nsm: values.nsm ? values.nsm : null,
            nama: values.nama ? values.nama : null,
            singkatan: values.singkatan ? values.singkatan : null,
            status: values.status ? values.status : null,
            jenis: values.jenis ? values.jenis : null,
            tahun_berdiri: thnBerdiri,
            no_sk: values.no_sk ? values.no_sk : null,
            tgl_sk: _getDate(values.tgl_sk),
            kode_satker_anggaran: values.kode_satker_anggaran ? values.kode_satker_anggaran : null,
            ni_dipa: values.ni_dipa ? values.ni_dipa : null,
            penempatan_dipa: values.penempatan_dipa ? values.penempatan_dipa : null,
            nama_wajib_pajak: values.nama_wajib_pajak ? values.nama_wajib_pajak : null,
            npwp: values.npwp ? values.npwp : null,
            waktu_belajar: values.waktu_belajar ? values.waktu_belajar : null,
            status_kelompok: values.status_kelompok ? values.status_kelompok : null,
            total_anggota: values.total_anggota ? values.total_anggota : null,
            komite: values.komite ? values.komite : null,
            kategori: values.kategori ? values.kategori : null,
            e_rkam: values.e_rkam ? "Ya" : "Tidak",
            lingkungan_pesantren: values.lingkungan_pesantren ? "Ya" : "Tidak",
            no_telp: values.no_telp ? values.no_telp : null,
            email: values.email ? values.email : null,
            website: values.website ? values.website : null,
            fax: values.fax ? values.fax : null,
        }

        if (this.state.fileList.length > 0) {
            if (this.state.fileList[0].status === "error") {
                message.error('File image yang Anda upload tidak sesuai.!');
                return false
            }
        }

        let endpoint = "lembaga/identitas"
        let method = "POST"
        if (values.id) {
            endpoint = "lembaga/identitas/" + values.id
            method = "PUT"
        }

        this.setState({
            loadingBtn: true
        });
        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                const data = resp.data.data
                this.setIdentitas(data)
            }
            this.setState({
                loadingBtn: false
            });
        })


    };



    render() {

        const { errors, loadingBtn, loading, values } = this.state;

        if (loading) {
            return <Preload />
        }

        const form = <Row gutter={[16, 16]}>
            <Col xs={24} sm={14}>
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Alert message="Informasi identitas" type="info" showIcon />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="NPSN *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="npsn"
                            type="text"
                            onChange={this.handleChange}
                            value={values.npsn || ''}
                            helperText={
                                this.hasError('npsn') ? errors.npsn[0] : null
                            }
                            error={this.hasError('npsn')}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="NSM"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="nsm"
                            type="text"
                            onChange={this.handleChange}
                            value={values.nsm || ''}
                            helperText={
                                this.hasError('nsm') ? errors.nsm[0] : null
                            }
                            error={this.hasError('nsm')}
                        />
                    </Col>
                    <Col xs={24} sm={16}>
                        <TextField
                            fullWidth
                            label="Nama Lembaga *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="nama"
                            type="text"
                            onChange={this.handleChange}
                            value={values.nama || ''}
                            helperText={
                                this.hasError('nama') ? errors.nama[0] : null
                            }
                            error={this.hasError('nama')}
                        />
                    </Col>
                    <Col xs={24} sm={8}>
                        <TextField
                            fullWidth
                            label="Nama Singkatan"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="singkatan"
                            type="text"
                            onChange={this.handleChange}
                            value={values.singkatan || ''}
                            helperText={
                                this.hasError('singkatan') ? errors.singkatan[0] : null
                            }
                            error={this.hasError('singkatan')}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <Autocomplete
                            options={lembagaStatusOptions}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Status Lembaga"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="status"
                                type="text"
                                helperText={
                                    this.hasError('status') ? errors.status[0] : null
                                }
                                error={this.hasError('status')}
                            />}
                            value={values.status || ''}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("status", newValue ? newValue.label : null)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <Autocomplete
                            options={lembagaJenisOptions}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Jenis Lembaga"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="jenis"
                                type="text"
                                helperText={
                                    this.hasError('jenis') ? errors.jenis[0] : null
                                }
                                error={this.hasError('jenis')}
                            />}
                            value={values.jenis || ''}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("jenis", newValue ? newValue.label : null)
                            }}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="No. SK Pendirian/SK Kenegerian"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="no_sk"
                            type="text"
                            onChange={this.handleChange}
                            value={values.no_sk || ''}
                            helperText={
                                this.hasError('no_sk') ? errors.no_sk[0] : null
                            }
                            error={this.hasError('no_sk')}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    label="Tahun Berdiri"
                                    views={['year']}
                                    value={values.tahun_berdiri || null}
                                    onChange={(newValue) => {
                                        this.handleChangeSetValue("tahun_berdiri", newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={this.hasError('tahun_berdiri')}
                                        helperText={
                                            this.hasError('tahun_berdiri') ? errors.tahun_berdiri[0] : null
                                        }
                                        {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={24} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={3}>
                                <MobileDatePicker
                                    label="Tanggal SK Pendirian/SK Kenegerian"
                                    inputFormat="dd-MM-yyyy"
                                    value={values.tgl_sk || null}
                                    onChange={(newValue) => {
                                        this.handleChangeSetValue("tgl_sk", newValue)
                                    }}
                                    renderInput={(params) => <TextField
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText={
                                            this.hasError('tgl_sk') ? errors.tgl_sk[0] : null
                                        }
                                        error={this.hasError('tgl_sk')}
                                        {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Kode Satker Anggaran"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="kode_satker_anggaran"
                            type="text"
                            onChange={this.handleChange}
                            value={values.kode_satker_anggaran || ''}
                            helperText={
                                this.hasError('kode_satker_anggaran') ? errors.kode_satker_anggaran[0] : null
                            }
                            error={this.hasError('kode_satker_anggaran')}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="NO. DIPA"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="ni_dipa"
                            type="text"
                            onChange={this.handleChange}
                            value={values.ni_dipa || ''}
                            helperText={
                                this.hasError('ni_dipa') ? errors.ni_dipa[0] : null
                            }
                            error={this.hasError('ni_dipa')}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Penempatan DIPA"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="penempatan_dipa"
                            type="text"
                            onChange={this.handleChange}
                            value={values.penempatan_dipa || ''}
                            helperText={
                                this.hasError('penempatan_dipa') ? errors.penempatan_dipa[0] : null
                            }
                            error={this.hasError('penempatan_dipa')}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Nama Wajib Pajak"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="nama_wajib_pajak"
                            type="text"
                            onChange={this.handleChange}
                            value={values.nama_wajib_pajak || ''}
                            helperText={
                                this.hasError('nama_wajib_pajak') ? errors.nama_wajib_pajak[0] : null
                            }
                            error={this.hasError('nama_wajib_pajak')}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="NPWP"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="npwp"
                            type="text"
                            onChange={this.handleChange}
                            value={values.npwp || ''}
                            helperText={
                                this.hasError('npwp') ? errors.npwp[0] : null
                            }
                            error={this.hasError('npwp')}
                        />
                    </Col>
                    <Col xs={24}>
                        <Divider />
                    </Col>
                </Row>
            </Col>
            <Col xs={24} sm={10}>
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <Autocomplete
                            options={lembagaWaktuBelajarOptions}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Waktu Belajar"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="waktu_belajar"
                                type="text"
                                helperText={
                                    this.hasError('waktu_belajar') ? errors.waktu_belajar[0] : null
                                }
                                error={this.hasError('waktu_belajar')}
                            />}
                            value={values.waktu_belajar || ''}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("waktu_belajar", newValue ? newValue.label : null)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={18}>
                        <Autocomplete
                            options={lembagaStatusKelompokOptions}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Status Kelompok Kerja Madrasah (KKM)"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="status_kelompok"
                                type="text"
                                helperText={
                                    this.hasError('status_kelompok') ? errors.status_kelompok[0] : null
                                }
                                error={this.hasError('status_kelompok')}
                            />}
                            value={values.status_kelompok || ''}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("status_kelompok", newValue ? newValue.label : null)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={6}>
                        <TextField
                            fullWidth
                            label="Total Anggota"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="total_anggota"
                            type="text"
                            onChange={this.handleChange}
                            value={values.total_anggota || ''}
                            error={this.hasError('total_anggota')}
                            helperText={
                                this.hasError('total_anggota') ? errors.total_anggota[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <Autocomplete
                            options={lembagaKomiteOptions}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Komite Lembaga"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="komite"
                                type="text"
                                helperText={
                                    this.hasError('komite') ? errors.komite[0] : null
                                }
                                error={this.hasError('komite')}
                            />}
                            value={values.komite || ''}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("komite", newValue ? newValue.label : null)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <Autocomplete
                            options={lembagaKategoriOptions}
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Kategori Lembaga"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="kategori"
                                type="text"
                                helperText={
                                    this.hasError('kategori') ? errors.kategori[0] : null
                                }
                                error={this.hasError('kategori')}
                            />}
                            value={values.kategori || ''}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("kategori", newValue ? newValue.label : null)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="No. Telepon"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="no_telp"
                            type="text"
                            onChange={this.handleChange}
                            value={values.no_telp || ''}
                            error={this.hasError('no_telp')}
                            helperText={
                                this.hasError('no_telp') ? errors.no_telp[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="E-Mail"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="email"
                            type="text"
                            onChange={this.handleChange}
                            value={values.email || ''}
                            error={this.hasError('email')}
                            helperText={
                                this.hasError('email') ? errors.email[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Alamat Website"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="website"
                            type="text"
                            onChange={this.handleChange}
                            value={values.website || ''}
                            error={this.hasError('website')}
                            helperText={
                                this.hasError('website') ? errors.website[0] : null
                            }
                            InputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="No. Fax"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="fax"
                            type="text"
                            onChange={this.handleChange}
                            value={values.fax || ''}
                            error={this.hasError('fax')}
                            helperText={
                                this.hasError('fax') ? errors.fax[0] : null
                            }
                        />
                    </Col>

                    <Col xs={24}>
                        <Checkbox style={{ width: '100%' }}
                            name="e_rkam"
                            checked={values.e_rkam}
                            onChange={() => {
                                values.e_rkam = values.e_rkam === undefined ? true : !values.e_rkam
                                this.handleChangeSetValue("e_rkam", values.e_rkam)
                            }}>
                            Apakah madrasah mempunyai dokumen e-RKAM?
                        </Checkbox>
                    </Col>
                    <Col xs={24}>
                        <Checkbox style={{ width: '100%' }}
                            name="lingkungan_pesantren"
                            checked={values.lingkungan_pesantren}
                            onChange={() => {
                                values.lingkungan_pesantren = values.lingkungan_pesantren === undefined ? true : !values.lingkungan_pesantren
                                this.handleChangeSetValue("lingkungan_pesantren", values.lingkungan_pesantren)
                            }}>
                            Apakah madrasah berada di lingkungan pesantren?
                        </Checkbox>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} style={{ textAlign: "right" }}>
                {this.state.tipe === "Update" ?
                    <>
                        <Button type='text' onClick={() => {
                            this.setState({
                                isUpdate: false
                            })
                        }}>Batal</Button> &nbsp;
                    </> : ""}

                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} loading={loadingBtn} onClick={this.onSubmitForm} style={{ float: "right" }}>{this.state.id ? "Update" : "Simpan"}</Button>
            </Col>
        </Row>

        const detail = <Row gutter={[16, 16]}>
            <Col xs={24} style={{ textAlign: "right" }}>
                <Tooltip placement="top" title={"Ubah data diri"}>
                    <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                        this.setState({
                            isUpdate: true
                        })
                    }}></Button>
                </Tooltip>
            </Col>
            <Col xs={24} sm={12}>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.data1}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </Col>
            <Col xs={24} sm={12}>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.data2}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </Col>
        </Row>

        const dataEmpty = <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
                height: 60,
            }}
            description={
                <span>
                    <h3>Data Identitas</h3>
                    <p>Belum ada data identitas, silahkan tambahkan data identitas baru</p>
                </span>
            }
        >
            {
                this.state.tipe === "Tambah Dari Data Sebelumnya" ? <><Button onClick={() => {
                    this.copyIdentitasLastData(tahunAjaranIDDefault)
                }}>Tambahkan dari data sebelumnya</Button>&nbsp;</> : ""
            }
            <Button onClick={() => {
                this.setState({
                    tipe: "Update",
                    isUpdate: true,
                })
            }}>Tambahkan Baru</Button>
        </Empty>

        return (
            loading ? <PreloadContent /> : (this.state.tipe === "Update" ? (this.state.isUpdate ? form : detail) : dataEmpty)
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(Identitas);
