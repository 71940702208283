import React from 'react';
import { Modal, Button, Form, Select, Divider } from 'antd';
import { _success, _setAxios } from '../../../../lib/Helper';

const { Option } = Select;

class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            values: {},
            ddl: {
                tahunAjaran: [],
                ekstrakurikuler: []
            }
        };
    }

    componentDidMount() {
        this.getTahunAjaranDDL()
        this.getEkstrakurikulerDDL()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        });
        _setAxios("kaldik-kegiatan/ekstrakurikuler/master/ekspor", "POST", {
            tahun_ajaran: this.state.values.tahun_ajaran ? this.state.values.tahun_ajaran : null,
            ekstrakurikuler_id: this.state.values.ekstrakurikuler ? this.state.values.ekstrakurikuler: null
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    getTahunAjaranDDL = () => {
        _setAxios("tahun-ajaran/tahun", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        tahunAjaran: resp.data.data
                    },
                })
            }
        })
    }
    getEkstrakurikulerDDL = (tahunAjaran) => {
        _setAxios("kaldik-kegiatan/ekstrakurikuler/master/dropdown", "POST", {
            tahun_ajaran: tahunAjaran ? tahunAjaran : null
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        ekstrakurikuler: resp.data.data
                    },
                })
            }
        })
    }

    render() {
        const { ddl } = this.state

        return (
            <Modal
                title="Export data ekstrakurikuler"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Tahun Ajaran">
                        <Select
                            allowClear
                            name="tahun_ajaran"
                            placeholder="Tahun ajaran"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("tahun_ajaran", newValue ? newValue.value : null)
                                this.getEkstrakurikulerDDL(newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.tahunAjaran.map((row, i) => {
                                let value = row.thn_awal + "-" + row.thn_akhir
                                return <Option key={i} value={value}>{value}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Ekstrakurikuler">
                        <Select
                            allowClear
                            name="ekstrakurikuler"
                            placeholder="Ekstrakurikuler"
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("ekstrakurikuler", newValue ? newValue.value : null)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.ekstrakurikuler.map((row, i) => {
                                return <Option key={i} value={row.id}>{row.nama}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Divider />
                    <div style={{ textAlign: "left" }}>
                        Apakah Anda yakin? data ekstrakurikuler akan di export <br />
                        Data yang di export akan langsung ke download, tanpa melalui log export. </div>

                </Form>
            </Modal>
        )
    }
}

export default Export;
