import React from 'react';
import { connect } from 'react-redux'
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Row, Col, PageHeader, BackTop, Button, Breadcrumb } from 'antd';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { isAuth, _setAxios, getParamTable, getParamTempTableFiltering } from '../../../../lib/Helper';
import AuthRedirect from '../../../../components/AuthRedirect'
import SearchTable from '../../../../components/search-table/SearchTable';
import Filter from '../modal/Filter';
import Form from '../modal/Form';
import FormSertifikat from '../modal/FormSertifikat';
import DownloadSertifikat from '../modal/DownloadSertifikat';
import Desktop from './Desktop';
import Mobile from './Mobile';
import Export from '../modal/Export';
import SortingTableMobileWithFilter from '../../../../components/sorting-table/SortingTableMobileWithFilter';
import SortingTableDesktopWithFilter from '../../../../components/sorting-table/SortingTableDesktopWithFilter';

const sortFields = [{
  value: "id",
  label: "Tanggal Pembuatan",
}, {
  value: "nama",
  label: "Nama Siswa",
}, {
  value: "nisn",
  label: "NISN",
}, {
  value: "nis",
  label: "NIS",
}, {
  value: "rombel_nama",
  label: "Kelas",
}]

class Pramuka extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      values: {},
      table: {
        loading_first: false,
        loading: false,
        data: [],
        search: "",
        pagination: {
          current: 1,
          pageSize: 10,
          total: null,
        },
        sorting: [],
        filtering: {
        },
      },
      filter: {
        values: {
        },
        visible: false,
      },
      form: {
        data: {},
        visible: false
      },
      form_sertifikat: {
        data: {},
        visible: false
      },
      download_sertifikat: {
        data: {},
        visible: false
      },
      export: {
        data: {},
        visible: false
      },
      detail: {
        data: {},
        visible: false
      },
      dataSertifikat: {},
    };
  }

  fetchTable = (isNew = false) => {
    const { table } = this.state
    const params = getParamTable("default", table)
    this.fetch(params, isNew);
  }

  fetch = async (params = {}, isNew = false) => {
    this.setState({
      table: {
        ...params,
        loading_first: isMobile ? (isNew ? true : false) : false,
        loading: true
      }
    });

    params = {
      ...params,
      pagination: {
        ...params.pagination,
        current: isMobile ? (isNew ? 1 : params.pagination.current) : params.pagination.current
      }
    }
    _setAxios("kaldik-kegiatan/pramuka/penilaian/table-utama", "POST", params).then(resp => {

      if (resp.status === true) {
        let data = isNew ? [] : this.state.table.data

        let current = params.pagination.current
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
            data: isMobile ? [...data, ...resp.data.list] : resp.data.list,
            pagination: {
              ...params.pagination,
              current: isMobile ? current + 1 : current,
              total: resp.data.info.total
            }
          }
        });
      } else {
        this.setState({
          table: {
            ...params,
            loading_first: false,
            loading: false,
          }
        })
      }
    })
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value) => {
    values[name] = value;

    this.setState({
      values: values,
    });
  }

  modalForm = (visible = false, data = {}) => {
    this.setState({
      form: {
        data: data,
        visible: visible
      },
    })
  }
  modalFormSertifikat = (visible = false, data = {}) => {
    console.log("data", data)
    console.log("visible", visible)
    if (visible) {
      this.getSertifikatDDL(data.id)
    } else {
      this.setState({
        form_sertifikat: {
          data: data,
          visible: false
        },
      })
    }
  }
  modalDownloadSertifikat = (visible = false, data = {}) => {
    this.setState({
      download_sertifikat: {
        data: data,
        visible: visible
      },
    })
  }

  modalExport = (visible = false) => {
    this.setState({
      export: {
        ...this.state.export,
        visible: visible,
      },
    })
  }

  // FILTER START ------------------------------------------
  setTempTableFilter = (name, value) => {

    let values = this.state.filter.values
    values[name] = value

    this.setState({
      filter: {
        ...this.state.filter,
        values: values
      }
    })

  };

  setTableFilterFromTemp = () => {
    const params = getParamTempTableFiltering(this.state.table, this.state.filter.values)
    this.fetch(params, isMobile ? true : false)
    this.modalFilter(false)
  };

  modalFilter = (visible = false) => {
    this.setState({
      filter: {
        ...this.state.filter,
        visible: visible,
        values: {
          status: null,
        }
      },
    })
  }
  // FILTER END ------------------------------------------

  getSertifikatDDL = (siswa_id,) => {
    _setAxios("kaldik-kegiatan/pramuka/sertifikat/" + siswa_id, "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          form_sertifikat: {
            data: {
              ...resp.data.data,
              siswa_id: siswa_id
            },
            visible: true
          },
        })
      }
    })
  }
  render() {

    const access = this.props.privilege.access["/ekstrakurikuler-pramuka/pramuka"]
    if (!isAuth(this.props.privilege) || access === undefined) {
      return <AuthRedirect />
    }
    const action = access.action

    const { table } = this.state;

    return (
      <>
        <PageHeader
          style={{
            padding: 0
          }}
          onBack={() => window.history.back()}
          subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item>Ekstrakurikuler & Pramuka</Breadcrumb.Item>
            <Breadcrumb.Item>Pramuka</Breadcrumb.Item>
          </Breadcrumb>}
          extra={[
            action.export ? <Button onClick={this.modalExport.bind(this, true)}><DownloadOutlined /> Export</Button> : null,
            // action.create ? <Button onClick={this.modalForm.bind(this, true, {})} type='primary'><PlusOutlined /> Tambah</Button> : null,
          ]}
        />
        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
          <PageHeader
            className="site-page-header"
            title="Pramuka"
            subTitle={!isMobile ? `Data Pramuka` : ``}
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={18} md={19} >
              <SearchTable table={table} fetch={this.fetch} />
            </Col>

            {/* SORTING LIST/TABLE */}
            <Col xs={24} sm={6} md={5}>
              <SortingTableMobileWithFilter isFilter={true} table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
              <SortingTableDesktopWithFilter table={table} fetch={this.fetch} modalFilter={this.modalFilter} sortFields={sortFields} />
            </Col>

            {/* LIST/TABLE DATA */}
            <Col xs={24}>
              <BrowserView>
                <Desktop
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalFormSertifikat={this.modalFormSertifikat}
                  modalDownloadSertifikat={this.modalDownloadSertifikat}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDetail={this.modalDetail}
                />
              </BrowserView>
              <MobileView>
                <Mobile
                  access={access}
                  state={this.state}
                  fetch={this.fetch}
                  fetchTable={this.fetchTable}
                  modalFormSertifikat={this.modalFormSertifikat}
                  modalDownloadSertifikat={this.modalDownloadSertifikat}
                  modalForm={(visible, data) => {
                    this.modalForm(visible, data)
                  }}
                  modalDetail={this.modalDetail}
                />
              </MobileView>
            </Col>

          </Row>
        </div>

        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        {
          this.state.form.visible ?
            <Form
              data={this.state.form.data}
              visible={this.state.form.visible}
              hideModal={this.modalForm.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.form_sertifikat.visible ?
            <FormSertifikat
              data={this.state.form_sertifikat.data}
              visible={this.state.form_sertifikat.visible}
              hideModal={this.modalFormSertifikat.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.download_sertifikat.visible ?
            <DownloadSertifikat
              data={this.state.download_sertifikat.data}
              visible={this.state.download_sertifikat.visible}
              hideModal={this.modalDownloadSertifikat.bind(this, false, {})}
              fetchTable={this.fetchTable}
            /> : ""
        }
        {
          this.state.export.visible ?
            <Export
              visible={this.state.export.visible}
              hideModal={this.modalExport.bind(this, false)}
            /> : ""
        }
        {
          this.state.filter.visible ?
            <Filter
              visible={this.state.filter.visible}
              hideModal={this.modalFilter.bind(this, false)}
              setTempTableFilter={this.setTempTableFilter}
              setTableFilterFromTemp={this.setTableFilterFromTemp}
            /> : ""
        }
        <BackTop />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Pramuka);
