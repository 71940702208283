import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, message, DatePicker } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { _success, _setAxios, _validationFormMsg, _getDate } from '../../../../lib/Helper';

import "./style.css"

const { RangePicker } = DatePicker;

const schema = {
    tgl_awal: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_akhir: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    ruangan_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    ruangan_unit_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jml: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
        format: {
            pattern: /^$|^[0-9 ()+-]+(\.([0-9]{1,20}))?$/,
            message: function () {
                return validate.format("^Jumlah pinjam harus berupa agka");
            }
        },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            firstOpen: true,
            visible: false,
            fileList: [],
            ddl: {
                ruangan: [],
                unit: []
            }
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
        this.getRuanganDDL()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }

        let tglAwal = _getDate(values.tgl_awal)
        let tglAkhir = _getDate(values.tgl_akhir)

        let _tglAwal = tglAwal.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");
        let _tglAkhir = tglAkhir.replace(/-/g, "").replace(/ /g, "").replace(/:/g, "");

        if (_tglAwal > _tglAkhir) {
            return message.warning("Periode yang dimasukkan tidak valid.");
        }

        this.setState({
            loading: true
        });
        const params = {
            ruangan_id: values.ruangan_id ? values.ruangan_id.id : null,
            ruangan_unit_id: values.ruangan_unit_id ? values.ruangan_unit_id.id : null,
            tgl_awal: values.tgl_awal,
            tgl_akhir: values.tgl_akhir,
            keterangan: values.keterangan ? values.keterangan : null,
            jml: values.jml ? values.jml : null,
        }

        let endpoint = "sarpras/unit/peminjaman"
        let method = "POST"
        if (values.id) {
            endpoint = "sarpras/unit/peminjaman/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    getRuanganDDL = () => {
        _setAxios("ruangan/dropdown", "POST", {
            jenis_ruangan_id: "",
            is_booking: "0"
        }).then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        ruangan: resp.data.data
                    }
                });
            }
        })
    }
    getUnitRuanganDDL = (ruanganID) => {
        this.setState({
            ddl: {
                ...this.state.ddl,
                unit: []
            },
            values: {
                ...this.state.values,
                unit_id: null
            }
        });
        _setAxios("sarpras/unit/ruangan/" + ruanganID, "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        unit: resp.data.data
                    }
                });
            }
        })
    }

    render() {

        const { values, errors, ddl } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form peminjaman barang"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    {!values.id ?
                        <Col xs={24}>
                            <RangePicker
                                showTime
                                style={{ width: '100%' }}
                                onChange={(e, newValue) => {
                                    if (newValue.length === 2 && (newValue[0] && newValue[1])) {
                                        this.handleChangeSetValue("tgl_awal", newValue[0])
                                        this.handleChangeSetValue("tgl_akhir", newValue[1])
                                    }
                                }}
                            />
                        </Col> :
                        <>
                            <Col xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    size="small"
                                    label="Waktu Mulai"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    disabled={true}
                                    value={values.tgl_awal || ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <TextField
                                    multiline
                                    fullWidth
                                    size="small"
                                    label="Waktu Selesai"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type="text"
                                    disabled={true}
                                    value={values.tgl_akhir || ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Col>
                        </>
                    }
                    <Col xs={24}>
                        <Autocomplete
                            options={ddl.ruangan}
                            getOptionLabel={(option) =>
                                option.nama
                            }
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Ruangan *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="ruangan_id"
                                type="text"
                                error={this.hasError('ruangan_id')}
                                helperText={
                                    this.hasError('ruangan_id') ? errors.ruangan_id[0] : null
                                }
                            />}
                            value={values.ruangan_id || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("ruangan_id", newValue)
                                this.getUnitRuanganDDL(newValue.id)
                            }}
                        />
                    </Col>
                    <Col xs={24}>
                        <Autocomplete
                            options={ddl.unit}
                            getOptionLabel={(option) =>
                                option.unit_nama + " - " + option.keterangan + (option.jml ? " (" + option.jml + ")" : "")
                            }
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Unit Barang *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="ruangan_unit_id"
                                type="text"
                                error={this.hasError('ruangan_unit_id')}
                                helperText={
                                    this.hasError('ruangan_unit_id') ? errors.ruangan_unit_id[0] : null
                                }
                            />}
                            value={values.ruangan_unit_id || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("ruangan_unit_id", newValue)
                            }}
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            multiline
                            fullWidth
                            label="Jumlah Pinjam"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jml"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("jml", e.target.value)
                            }}
                            value={values.jml || ''}
                            error={this.hasError('jml')}
                            helperText={
                                this.hasError('jml') ? errors.jml[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            multiline
                            fullWidth
                            label="Keterangan"
                            size="small"
                            minRows={3}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="keterangan"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("keterangan", e.target.value)
                            }}
                            value={values.keterangan || ''}
                            error={this.hasError('keterangan')}
                            helperText={
                                this.hasError('keterangan') ? errors.keterangan[0] : null
                            }
                        />
                    </Col>
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
