import React from 'react';
import { _setAxios, getParamTable } from '../../../lib/Helper';
import { Table } from 'antd';

class PesertaDidikPointTerbanyak extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            table: {
                loading: false,
                data: [],
                search: "",
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                },
                sorting: [],
                filtering: {},
            },
        };
    }

    componentDidMount() {
        this.fetchTable()
    }


    fetchTable = () => {
        const { table } = this.state
        const params = getParamTable("default", table)
        this.fetch(params);
    }

    fetch = async (params = {}) => {
        this.setState({
            table: {
                ...params,
                loading: true
            }
        });
        _setAxios("tatibsi/dashboard/table-poin-siswa", "POST", params).then(resp => {
            if (resp.status === true) {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                        data: resp.data.list,
                    }
                });
            } else {
                this.setState({
                    table: {
                        ...params,
                        loading: false,
                    }
                })
            }
        })
    };

    render() {
        const { table } = this.state
        return (
            <Table
                columns={[
                    // {
                    //     title: 'No',
                    //     dataIndex: 'rownum',
                    //     width: '3%',
                    // },
                    {
                        title: 'Peserta Didik',
                        dataIndex: 'nama',
                    },
                    {
                        title: 'Rombel',
                        dataIndex: 'rombel_nama',
                    },
                    {
                        title: 'Poin',
                        dataIndex: 'total_poin',
                    },
                ]}
                rowKey={record => record.id}
                dataSource={table.data}
                pagination={false}
                loading={table.loading}
                size="small"
            />
        )
    }
}

export default PesertaDidikPointTerbanyak
