import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY-MM-DD';
const dateNow = dayjs().format(dateFormat);

const Date = ({ ...props }) => {
    return (
        <DatePicker
            {...props}
            placeholder={props.placeholder ? props.placeholder : "Pilih Tanggal"}
            defaultValue={props.defaultValue ? dayjs(props.defaultValue, dateFormat) : (dateNow ? dayjs(dateNow, dateFormat) : null)}
            format={dateFormat}
            style={{ width: "100%" }}
            // onChange={(e, val) => {
            //     this.handleChangeSetValue("tgl_transaksi", val)
            // }} 
            />
    );
};
export default Date;
