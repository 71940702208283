import React from 'react';
import { Modal, Button, Form, Select, Row, Col } from 'antd';

import { _success, _setAxios } from '../../../../lib/Helper';
import { dataStatusOptions, fileStatusOptions, genderOptions, statusPPDBOptions, waAccountStatusOptions, waLolosStatusOptions } from '../../../../data/options';

const { Option } = Select;
class Export extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            ddl: {
                jenisJalurPPDB: []
            },
        };
    }

    componentDidMount() {
        this.getJenisJalurPPDB()
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = () => {
        const { values } = this.state
        this.setState({
            loading: true
        });
        _setAxios("ppdb/siswa/calon-peserta-didik", "POST", {
            ppdb_jalur_id: values.ppdb_jalur_id ? values.ppdb_jalur_id : null,
            jenis_kelamin: values.jenis_kelamin ? values.jenis_kelamin : null,
            wa_akun_status_api_wa: values.wa_akun_status_api_wa ? values.wa_akun_status_api_wa : null,
            wa_lolos_status_api_wa: values.wa_lolos_status_api_wa ? values.wa_lolos_status_api_wa : null,
            berkas_file_status: values.berkas_file_status ? values.berkas_file_status : null,
            berkas_data_status: values.berkas_data_status ? values.berkas_data_status : null,
            status: values.status ? values.status : null,
        }).then(resp => {
            if (resp.status === true) {
                this.props.hideModal()
                _success('topRight', 'Success', resp.data.message)
                setTimeout(function () {
                    window.location.href = resp.data.data.link;
                }, 1000);
            }
            this.setState({
                loading: false
            });
        })
    };

    getJenisJalurPPDB = () => {
        _setAxios("ppdb/jalur/dropdown", "GET").then(resp => {
            if (resp.status === true) {
                this.setState({
                    ddl: {
                        ...this.state.ddl,
                        jenisJalurPPDB: resp.data.data
                    }
                });
            }
        })
    }


    render() {

        const { ddl } = this.state
        return (
            <Modal
                title="Export data calon peserta didik"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Export</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item label="Jenis Jalur">
                        <Select
                            showSearch
                            allowClear
                            name="ppdb_jalur_id"
                            placeholder="Jenis Jalur"
                            onChange={(e, newValue) => {
                                let value = newValue ? newValue.value : null
                                this.handleChangeSetValue("ppdb_jalur_id", value)
                            }}
                            style={{ width: '100%' }}
                        >
                            {ddl.jenisJalurPPDB.map((row, i) => <Option key={i} value={row.id}>{row.kategori} ({row.nama})</Option>)}
                        </Select>
                    </Form.Item>

                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Jenis Kelamin">
                                <Select
                                    showSearch
                                    allowClear
                                    name="jenis_kelamin"
                                    placeholder="Jenis Kelamin"
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("jenis_kelamin", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {genderOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status">
                                <Select
                                    showSearch
                                    allowClear
                                    name="status"
                                    placeholder="Status"
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {statusPPDBOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Berkas">
                                <Select
                                    showSearch
                                    allowClear
                                    name="berkas_file_status"
                                    placeholder="Status Berkas"
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("berkas_file_status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {fileStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Data">
                                <Select
                                    showSearch
                                    allowClear
                                    name="berkas_data_status"
                                    placeholder="Status Data"
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("berkas_data_status", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {dataStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Nofif Wa Account">
                                <Select
                                    showSearch
                                    allowClear
                                    name="wa_akun_status_api_wa"
                                    placeholder="Status Nofif Wa Account"
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("wa_akun_status_api_wa", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {waAccountStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Form.Item label="Status Nofif Wa Lolos">
                                <Select
                                    showSearch
                                    allowClear
                                    name="wa_lolos_status_api_wa"
                                    placeholder="Status Nofif Wa Lolos"
                                    onChange={(e, newValue) => {
                                        this.handleChangeSetValue("wa_lolos_status_api_wa", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {waLolosStatusOptions.map((row, i) => <Option key={i} value={row.value}>{row.value}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: "left" }}>Data yang di export akan langsung ke download <br/>tanpa melalui log export </div>

                </Form>
            </Modal>

        )
    }
}

export default Export;
