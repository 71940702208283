import React from 'react';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Row, Col, Divider, List, Spin, Dropdown, Menu, Button, Empty } from 'antd';
import { CloseCircleOutlined, DownCircleOutlined, EditOutlined, PrinterOutlined } from '@ant-design/icons';

import { _fmtRupiah, _warn } from '../../../../lib/Helper';

class Mobile extends React.Component {
  componentDidMount() {
    this.props.fetchTable(true)
  }

  render() {
    const { table } = this.props.state
    const action = this.props.access.action

    return (
      <>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <InfiniteScroll
              dataLength={table.data.length}
              next={this.props.fetchTable}
              hasMore={table.pagination.total != null && table.data.length < table.pagination.total}
              loader={<div style={{ textAlign: "center" }}><Spin size="small" /></div>}
              endMessage={
                table.loading_first ? "" : (table.data.length ? <Divider /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Data tidak ditemukan" />)
              }
            >
              <List
                loading={table.loading_first}
                locale={{ emptyText: " " }}
                dataSource={table.data}
                renderItem={(row, i) => (
                  <List.Item
                    key={i}
                  >
                    <List.Item.Meta
                      title={(row.tk_gelar_depan ? row.tk_gelar_depan : "") + (row.tk_nama ? row.tk_nama : "-") + (row.tk_gelar_belakang ? " " + row.tk_gelar_belakang : "")}
                      description={<>
                        <Row gutter={[8, 8]}>
                          <Col xs={24}>
                            <small>
                              Periode: {row.periode ? row.periode : "-"} <br />
                              Total Gaji: Rp. {row.gaji_kotor ? _fmtRupiah(row.gaji_kotor) : "0"} <br />
                              Total Potongan: Rp. {row.total_potongan ? _fmtRupiah(row.total_potongan) : "0"} <br />
                              Total Gaji: Rp. {_fmtRupiah(row.gaji_kotor)} <br />
                              Gaji yang Diterima: Rp. {row.gaji_bersih ? _fmtRupiah(row.gaji_bersih) : "0"} <br />
                            </small>
                          </Col>
                        </Row>
                      </>}

                    />
                    <div>
                      {(!action.invoice && !action.update && !action.delete ? "-" : <Dropdown trigger={['click']} overlay={<Menu>
                        {action.invoice ? (<Menu.Item key={0} onClick={() => {
                          _warn("topRight", "Gagal", "Maaf, fitur ini belum tersedia")
                        }}><PrinterOutlined /> Invoice</Menu.Item>) : ""}
                        {action.update ? (<Menu.Item key={2} onClick={this.props.modalForm.bind(this, true, row)}><EditOutlined /> Update</Menu.Item>) : ""}
                        {action.delete ? (<Menu.Item key={3} onClick={this.props.modalDelete.bind(this, true, row)}><CloseCircleOutlined /> Delete</Menu.Item>) : ""}
                      </Menu>}>
                        <Button type="primary">Opsi <DownCircleOutlined style={{ fontSize: 11 }} /></Button>
                      </Dropdown>)}
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Mobile);
