import React from 'react';
import { connect } from 'react-redux'
import { isAuth, _setAxios, _success } from '../../../lib/Helper';
import AuthRedirect from '../../../components/AuthRedirect'
import { Breadcrumb, Tabs, message, Row, Col, Image, List, Divider, Skeleton, Modal, PageHeader, Button } from 'antd';
import { DoubleRightOutlined, DownloadOutlined } from '@ant-design/icons';

import DataDiri from './DataDiriDetail';
import TugasUtama from '../TugasUtama';
import TugasTambahanSatminkal from '../TugasTambahanSatminkal';
import TugasTambahanNonSatminkal from '../TugasTambahanNonSatminkal';
import StatusRiwayatKepegawaian from '../StatusRiwayatKepegawaian';
import Pendidikan from '../Pendidikan';
import Pelatihan from '../Pelatihan';
import Penghargaan from '../Penghargaan';
import Anak from '../Anak';
import RiwayatPesantren from '../RiwayatPesantren';
import Sertifikasi from '../Sertifikasi';
import KaryaTulis from '../karya-tulis/list/index';

import { noImage } from '../../../data/image';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

class GuruDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            tabIdx: "0",
            dataDiri: {
                loading: true,
                imageProfile: null,
                data1: [],
            },
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        this.setState({
            tabIdx: query.get("tab") ? query.get("tab") : "0"
        })

        this.getDataDiriByID(this.props.match.params.id)
    }

    getDataDiriByID = (id) => {
        _setAxios("tk/data-diri/" + id, "GET").then(resp => {
            if (resp.data.status === true) {
                const data = resp.data.data

                let dataDiri = {
                    loading: false,
                    imageProfile: data.foto ? data.main_path + data.foto : null,
                    headerLeft: [{
                        title: 'NIP',
                        description: data.nip ? data.nip : "-",
                    },
                    {
                        title: 'NPK',
                        description: data.npk ? data.npk : "-",
                    },
                    {
                        title: 'NUPTK',
                        description: data.nuptk ? data.nuptk : "-",
                    },
                    {
                        title: 'NRG',
                        description: data.nrg_no ? data.nrg_no : "-",
                    }],
                    headerRight: [{
                        title: 'NIK',
                        description: data.nik ? data.nik : "-",
                    },
                    {
                        title: 'NO. KK',
                        description: data.kk_no ? data.kk_no : "-",
                    },
                    {
                        title: 'NO. NPWP',
                        description: data.npwp_no ? data.npwp_no : "-",
                    },
                    {
                        title: 'NO. BPJS',
                        description: data.bpjs_no ? data.bpjs_no : "-",
                    }]
                }


                this.setState({
                    id: data.id,
                    dataDiri: dataDiri,
                });
            }
        })
    }

    onChangeTab = (key) => {
        if (key !== "0") {
            if (this.state.id === null) {
                message.info('Loading...');
                return
            }
        }

        this.props.history.push({
            pathname: window.location.pathname,
            search: "?" + new URLSearchParams({ tab: key }).toString()
        })

        this.setState({
            tabIdx: key
        })
    }

    onExport = () => {
        Modal.confirm({
            title: 'Export Data Guru',
            width: 550,
            icon: false,
            content: <div style={{ textAlign: 'right' }}>Apakah Anda yakin? data akan di export<br />
                Data yang di export akan langsung ke download, tanpa melalui log export
            </div>,
            cancelText: 'Batal',
            okText: 'Export',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    _setAxios("tk/export-pdf", "POST", {
                        tk_id: this.props.match.params.id
                    }).then(resp => {
                        if (resp.status === true) {
                            _success('topRight', 'Success', resp.data.message)
                            setTimeout(function () {
                                window.open(resp.data.data.link, '_blank');
                                resolve();
                            }, 1000);
                        } else {
                            setTimeout(Math.random() > 0.5 ? resolve : reject, 100);
                        }
                    })
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel: () => {
                console.log("No action to process")
            },
        });
    }

    render() {
        const access = this.props.privilege.access["/tenaga-kependidikan/guru"]
        const action = access.action
        if (!isAuth(this.props.privilege) || access === undefined || action.detail === false) {
            return <AuthRedirect />
        }
        const { dataDiri } = this.state
        const steps = [
            {
                title: 'Data Diri',
                content: <DataDiri {...this.props} />,
            },
            {
                title: 'Tugas Utama',
                content: <TugasUtama dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Tugas Tambahan Satminkal',
                content: <TugasTambahanSatminkal dataID={this.state.id} {...this.props} />,
            },
            {
                title: 'Tugas Tambahan Non - Satminkal',
                content: <TugasTambahanNonSatminkal dataID={this.state.id} />,
            },
            {
                title: 'Status dan Riwayat Kepegawaian',
                content: <StatusRiwayatKepegawaian dataID={this.state.id} />,
            },
            {
                title: 'Pendidikan Formal',
                content: <Pendidikan dataID={this.state.id} />,
            },
            {
                title: 'Pelatihan',
                content: <Pelatihan dataID={this.state.id} />,
            },
            {
                title: 'Karya Tulis',
                content: <KaryaTulis dataID={this.state.id} />,
            },
            {
                title: 'Sertifikasi',
                content: <Sertifikasi dataID={this.state.id} />,
            },
            {
                title: 'Penghargaan',
                content: <Penghargaan dataID={this.state.id} />,
            },
            {
                title: 'Data Anak',
                content: <Anak dataID={this.state.id} />,
            },
            {
                title: 'Riwayat Pesantren',
                content: <RiwayatPesantren dataID={this.state.id} />,
            },
        ];

        console.log("dataDiri.loading", dataDiri.loading)
        return (
            <>
                <PageHeader
                    style={{
                        padding: 0
                    }}
                    onBack={() => window.history.back()}
                    subTitle={<Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
                        <Breadcrumb.Item>Tenaga Kependidikan</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/tenaga-kependidikan/guru`}>Guru</Link></Breadcrumb.Item>
                        <Breadcrumb.Item>Detail</Breadcrumb.Item>
                    </Breadcrumb>}
                    extra={[
                        action.export ? <Button style={{ marginTop: 10 }} onClick={this.onExport}><DownloadOutlined /> Export</Button> : null
                    ]}
                />

                <div className="site-layout-background" style={{ padding: 24 }}>
                    <Row gutter={[16, 16]} justify="center">
                        <Col xs={24} sm={6} style={{ textAlign: "center" }}>
                            <Image
                                width={200}
                                style={{ borderRadius: 100, border: "4px solid #ffffff" }}
                                src={dataDiri.imageProfile ? dataDiri.imageProfile : noImage}
                            />
                        </Col>
                        <Col xs={24} sm={6} >
                            {
                                !dataDiri.loading ?
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDiri.headerLeft}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                    :
                                    <Skeleton />
                            }
                        </Col>
                        <Col xs={24} sm={6} >
                            {
                                !dataDiri.loading ?
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={dataDiri.headerRight}
                                        renderItem={item => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={<><div style={{ float: "left", minWidth: 50 }}>{item.title}</div> : <small style={{ color: "rgb(119 105 105)" }}>{item.description}</small></>}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                    :
                                    <Skeleton />
                            }
                        </Col>
                    </Row>
                </div>

                <Divider />

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                    <Tabs defaultActiveKey="0" activeKey={this.state.tabIdx} onChange={this.onChangeTab}>
                        {steps.map((item, i) => (
                            <TabPane key={i} tab={item.title}>
                                {steps[i].content}
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(GuruDetail);
