import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Radio } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';

import "./style.css"
import { kategoriJalurPPDBOptions } from '../../../../data/options';

const schema = {
    kategori: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    inisial: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tahun_ajaran: {
        presence: { allowEmpty: false, message: 'wajib dipilih' },
    },
    tgl_pendaftaran: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_seleksi_administrasi: { 
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    tgl_pengumuman: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
            fileList: [],
            ddl: {
                kategori: kategoriJalurPPDBOptions,
            }
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.data,
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });
        const params = {
            kategori: values.kategori ? values.kategori.value : null,
            nama: values.nama ? values.nama : null,
            tahun_ajaran: values.tahun_ajaran ? values.tahun_ajaran : null,
            tgl_pendaftaran: values.tgl_pendaftaran ? values.tgl_pendaftaran : null,
            tgl_seleksi_administrasi: values.tgl_seleksi_administrasi ? values.tgl_seleksi_administrasi : null,
            tgl_seleksi_wawancara: values.tgl_seleksi_wawancara ? values.tgl_seleksi_wawancara : null,
            tgl_pengumuman: values.tgl_pengumuman ? values.tgl_pengumuman : null,
            is_open: values.is_open ? values.is_open : "0",
            tgl_seleksi_tulis: values.tgl_seleksi_tulis ? values.tgl_seleksi_tulis : null,
            tgl_daftar_ulang: values.tgl_daftar_ulang ? values.tgl_daftar_ulang : null,
            ruangan_daftar_ulang: values.ruangan_daftar_ulang ? values.ruangan_daftar_ulang : null,
            jam_daftar_ulang: values.jam_daftar_ulang ? values.jam_daftar_ulang : null,
            link_pengumuman: values.link_pengumuman ? values.link_pengumuman : null,
            inisial: values.inisial ? values.inisial : null,
            tgl_registrasi: null,
        }

        let endpoint = "ppdb/jalur"
        let method = "POST"
        if (values.id) {
            endpoint = "ppdb/jalur/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values, errors, ddl } = this.state

        return (
            <Modal
                maskClosable={false}
                title={"Form jenis jalur PPDB"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={<>
                    <Button onClick={this.props.hideModal}>Batal</Button>
                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit} loading={this.state.loading}>Simpan</Button>
                </>}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Nama *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="nama"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("nama", e.target.value)
                            }}
                            value={values.nama || ''}
                            error={this.hasError('nama')}
                            helperText={
                                this.hasError('nama') ? errors.nama[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <Autocomplete
                            options={ddl.kategori}
                            getOptionLabel={(option) =>
                                option.label
                            }
                            renderInput={(params) => <TextField {...params}
                                fullWidth
                                label="Kategori *"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="kategori"
                                type="text"
                                error={this.hasError('kategori')}
                                helperText={
                                    this.hasError('kategori') ? errors.kategori[0] : null
                                }
                            />}
                            value={values.kategori || null}
                            onChange={(e, newValue) => {
                                this.handleChangeSetValue("kategori", newValue)
                            }}
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Tahun Ajaran *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tahun_ajaran"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tahun_ajaran", e.target.value)
                            }}
                            value={values.tahun_ajaran || ''}
                            error={this.hasError('tahun_ajaran')}
                            helperText={
                                this.hasError('tahun_ajaran') ? errors.tahun_ajaran[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24} sm={12}>
                        <TextField
                            fullWidth
                            label="Inisial (Fungsi: Generate no kartu ujian) *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="inisial"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("inisial", e.target.value)
                            }}
                            value={values.inisial || ''}
                            error={this.hasError('inisial')}
                            helperText={
                                this.hasError('inisial') ? errors.inisial[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Tanggal Pendaftaran *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tgl_pendaftaran"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tgl_pendaftaran", e.target.value)
                            }}
                            value={values.tgl_pendaftaran || ''}
                            error={this.hasError('tgl_pendaftaran')}
                            helperText={
                                this.hasError('tgl_pendaftaran') ? errors.tgl_pendaftaran[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Tanggal Seleksi Administrasi *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tgl_seleksi_administrasi"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tgl_seleksi_administrasi", e.target.value)
                            }}
                            value={values.tgl_seleksi_administrasi || ''}
                            error={this.hasError('tgl_seleksi_administrasi')}
                            helperText={
                                this.hasError('tgl_seleksi_administrasi') ? errors.tgl_seleksi_administrasi[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Tanggal Seleksi Wawancara"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tgl_seleksi_wawancara"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tgl_seleksi_wawancara", e.target.value)
                            }}
                            value={values.tgl_seleksi_wawancara || ''}
                            error={this.hasError('tgl_seleksi_wawancara')}
                            helperText={
                                this.hasError('tgl_seleksi_wawancara') ? errors.tgl_seleksi_wawancara[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Tanggal Seleksi Tulis"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tgl_seleksi_tulis"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tgl_seleksi_tulis", e.target.value)
                            }}
                            value={values.tgl_seleksi_tulis || ''}
                            error={this.hasError('tgl_seleksi_tulis')}
                            helperText={
                                this.hasError('tgl_seleksi_tulis') ? errors.tgl_seleksi_tulis[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Tanggal Pengumuman *"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tgl_pengumuman"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tgl_pengumuman", e.target.value)
                            }}
                            value={values.tgl_pengumuman || ''}
                            error={this.hasError('tgl_pengumuman')}
                            helperText={
                                this.hasError('tgl_pengumuman') ? errors.tgl_pengumuman[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Tanggal Daftar Ulang"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="tgl_daftar_ulang"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("tgl_daftar_ulang", e.target.value)
                            }}
                            value={values.tgl_daftar_ulang || ''}
                            error={this.hasError('tgl_daftar_ulang')}
                            helperText={
                                this.hasError('tgl_daftar_ulang') ? errors.tgl_daftar_ulang[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Ruangan Daftar Ulang"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="ruangan_daftar_ulang"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("ruangan_daftar_ulang", e.target.value)
                            }}
                            value={values.ruangan_daftar_ulang || ''}
                            error={this.hasError('ruangan_daftar_ulang')}
                            helperText={
                                this.hasError('ruangan_daftar_ulang') ? errors.ruangan_daftar_ulang[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Jam Daftar Ulang"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="jam_daftar_ulang"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("jam_daftar_ulang", e.target.value)
                            }}
                            value={values.jam_daftar_ulang || ''}
                            error={this.hasError('jam_daftar_ulang')}
                            helperText={
                                this.hasError('jam_daftar_ulang') ? errors.jam_daftar_ulang[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <TextField
                            fullWidth
                            label="Link Pengumuman"
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="link_pengumuman"
                            type="text"
                            onChange={(e) => {
                                this.handleChangeSetValue("link_pengumuman", e.target.value)
                            }}
                            value={values.link_pengumuman || ''}
                            error={this.hasError('link_pengumuman')}
                            helperText={
                                this.hasError('link_pengumuman') ? errors.link_pengumuman[0] : null
                            }
                        />
                    </Col>
                    <Col xs={24}>
                        <label className='input-label' style={{ marginLeft: 0, left: 0 }}>Status Pendaftaran *</label>
                        <div style={{ marginTop: 16 }}>
                            <Radio.Group
                                name="is_open"
                                value={values.is_open ? values.is_open : "0"}
                                onChange={(e) => {
                                    this.handleChangeSetValue("is_open", e.target.value)
                                }}
                            >
                                <Radio value={"0"}>Tidak Aktif</Radio>
                                <Radio value={"1"}>Aktif</Radio>
                            </Radio.Group>
                        </div>
                        <span className='input-error'>{errors.jenis_kelamin && errors.jenis_kelamin.length > 0 ? errors.jenis_kelamin[0] : ""}</span>
                    </Col>
                    {/* <Col xs={24}>
                            <label className='input-label'>Tanggal Pendaftaran *</label>
                            <RangePicker
                                style={{ width: '100%' }}
                                onChange={(e, newValue) => {
                                    if (newValue.length === 2 && (newValue[0] && newValue[1])) {
                                        this.handleChangeSetValue("tgl_pendaftaran", newValue[0] + " - " + newValue[1])
                                    }else{
                                        this.handleChangeSetValue("tgl_pendaftaran", null)
                                    }
                                }}
                                onCancel
                            />
                            <span className='input-error'>{errors.tgl_pendaftaran && errors.tgl_pendaftaran.length > 0 ? errors.tgl_pendaftaran[0] : ""}</span>
                        </Col>  */}
                </Row>

            </Modal>
        )
    }
}

export default FormContent;
