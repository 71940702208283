import React from 'react';
import { connect } from 'react-redux'

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Form, Divider, Input } from 'antd';

import { _success, _setAxios, _validationFormMsg, _fmtRupiah, _isNumber } from '../../../../../lib/Helper';

const schema = {
    tk_id: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    gaji_pokok: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    masa_kerja: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jabatan_sekolah: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    jabatan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    uang_makan: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    }
};
const { TextArea } = Input;

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                ...this.props.data,
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            _validationFormMsg(errors)
            return
        }

        this.setState({
            loading: true
        });

        const params = {
            tk_id: values.tk_id,
            jabatan_sekolah: values.jabatan_sekolah || "",
            gaji_pokok: values.gaji_pokok ? _isNumber(values.gaji_pokok) : 0,
            masa_kerja: values.masa_kerja ? _isNumber(values.masa_kerja) : 0,
            jabatan: values.jabatan ? _isNumber(values.jabatan) : 0,
            uang_makan: values.uang_makan ? _isNumber(values.uang_makan) : 0,
            keterangan: values.keterangan || "",
        }

        _setAxios('keuangan/payroll/tunjangan/' + values.tk_id, 'GET').then(resp => {
            if (resp.status === true) {
                let endpoint = "keuangan/payroll/tunjangan"
                let method = "POST"
                if (resp.data.data.created_at) {
                    endpoint = endpoint + "/" + values.tk_id
                    method = "PUT"
                }

                _setAxios(endpoint, method, params).then(resp => {
                    if (resp.status === true) {
                        _success('topRight', 'Success', resp.data.message)
                        this.props.fetchTable(true)
                        this.props.hideModal()

                        this.setState({
                            loading: false
                        })
                    } else {
                        this.setState({
                            loading: false
                        });
                    }
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };


    render() {
        const { data } = this.props
        const { values } = this.state
        return (
            <Modal
                maskClosable={false}
                title={"Form Tunjangan"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                {(data.tk_gelar_depan ? data.tk_gelar_depan : "") + (data.tk_nama ? data.tk_nama : "-") + (data.tk_gelar_belakang ? " " + data.tk_gelar_belakang : "")}
                            </div>
                            <Divider />
                            <Form.Item label="Satuan Gaji Pokok *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="gaji_pokok"
                                    placeholder="0"
                                    defaultValue={data.gaji_pokok ? data.gaji_pokok : null}
                                    value={values.gaji_pokok ? _fmtRupiah(values.gaji_pokok) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("gaji_pokok", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Tunjangan Masa *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="masa_kerja"
                                    placeholder="0"
                                    defaultValue={data.masa_kerja ? data.masa_kerja: null}
                                    value={values.masa_kerja ? _fmtRupiah(values.masa_kerja) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("masa_kerja", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Jabatan *">
                                <Input
                                    style={{ width: '100%' }}
                                    name="jabatan_sekolah"
                                    placeholder="Jabatan_sekolah"
                                    defaultValue={data.jabatan_sekolah}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("jabatan_sekolah", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Tunjangan Jabatan *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="jabatan"
                                    placeholder="0"
                                    defaultValue={data.jabatan}
                                    value={values.jabatan ? _fmtRupiah(values.jabatan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("jabatan", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Uang Makan *">
                                <Input
                                    addonBefore={"Rp."}
                                    style={{ width: '100%' }}
                                    name="uang_makan"
                                    placeholder="0"
                                    defaultValue={data.uang_makan ? data.uang_makan : null}
                                    value={values.uang_makan ? _fmtRupiah(values.uang_makan) : null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("uang_makan", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="Keterangan"
                                    defaultValue={this.props.data.keterangan}
                                    value={this.state.values.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        privilege: state.privilege,
    }
}

export default connect(mapStateToProps)(FormContent);
