import React from 'react';
import { connect } from 'react-redux'
import { Button, Row, Col, Divider, Skeleton, List, Tooltip, Alert } from 'antd';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
import validate from 'validate.js';

import { _success, _validationFormMsg, _setAxios, _getDate } from '../../../lib/Helper';
import { jenisPendaftaranOptions, jenjangSekolahAsalOptions } from '../../../data/options';
import { schemaActivitas } from '../schema';

class ActivitasBelajar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      dataAktivitasBelajar: {
        data1: [],
        data2: [],
        data3: [],
      },
      isUpdate: false,
      values: {},
      errors: {},
      isValid: false,

      loadingBtn: false,
    };
  }

  componentDidMount() {
    this.getAktivitasBelajar()
  }

  getAktivitasBelajar = () => {
    this.setState({ loading: true })

    _setAxios("siswa/aktivitas-belajar/" + this.props.match.params.id, "GET").then(resp => {
      if (resp.status === true) {
        const data = resp.data.data

        this.setState({
          loading: false,
          dataAktivitasBelajar: {
            data1: [{
              title: 'Jenis Pendaftaran',
              description: data.jenis_pendaftaran ? data.jenis_pendaftaran : "-",
            },
            {
              title: 'Tanggal Masuk',
              description: data.tgl_masuk ? data.tgl_masuk : "-",
            },
            {
              title: 'Sekolah Asal',
              description: data.sekolah_asal ? data.sekolah_asal : "-",
            }, {
              title: 'Jenjang Sekolah Asal',
              description: data.jenjang_sekolah_asal ? data.jenjang_sekolah_asal : "-",
            }, {
              title: 'No. Ijazah',
              description: data.no_ijazah ? data.no_ijazah : "-",
            }, {
              title: 'Tgl Ijazah',
              description: data.tgl_ijazah ? data.tgl_ijazah : "-",
            }],
            data2: [{
              title: 'Alasan Keluar',
              description: data.alasan_keluar ? data.alasan_keluar : "-",
            },
            {
              title: 'Tanggal Keluar',
              description: data.tgl_keluar ? data.tgl_keluar : "-",
            },
            {
              title: 'Keterangan',
              description: data.keterangan ? data.keterangan : "-",
            }],
            data3: [{
              title: 'Tahun Ajaran',
              description: data.thn_awal ? (data.thn_awal + " / " + data.thn_akhir) : "-",
            },
            {
              title: 'Tingkat',
              description: data.tingkatan_kelas ? data.tingkatan_kelas : "-",
            },
            {
              title: 'Jurusan',
              description: data.jurusan_nama ? data.jurusan_nama : "-",
            },
            {
              title: 'Rombongan Belajar',
              description: data.rombel_nama ? data.rombel_nama : "-",
            },
            {
              title: 'Keaktifan',
              description: data.status_siswa ? data.status_siswa : "-",
            }],
          },
          values: {
            id: data.id,
            jenis_pendaftaran: data.jenis_pendaftaran ? data.jenis_pendaftaran : null,
            jenjang_sekolah_asal: data.jenjang_sekolah_asal ? data.jenjang_sekolah_asal : null,
            tgl_masuk: data.tgl_masuk ? data.tgl_masuk : null,
            sekolah_asal: data.sekolah_asal ? data.sekolah_asal : null,
            no_ijazah: data.no_ijazah ? data.no_ijazah : null,
            tgl_ijazah: data.tgl_ijazah ? data.tgl_ijazah : null,
          }
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    })
  }

  // HANDLE FORM START ---------------------------------------------------------------------------------------------------------------------------
  handleChange = (e) => {
    const { values } = this.state;
    const { name, value } = e.target;

    if (name === "ayah_domisili" && value === "Luar Negeri") {
      values.ayah_region = null
      values.ayah_village = null
      values.ayah_kode_pos = null
    } else if (name === "ibu_domisili" && value === "Luar Negeri") {
      values.ibu_region = null
      values.ibu_village = null
      values.ibu_kode_pos = null
    } else if (name === "wali_domisili" && value === "Luar Negeri") {
      values.wali_region = null
      values.wali_village = null
      values.wali_kode_pos = null
    }

    this.setForm(values, name, value)
  };

  handleChangeSetValue = (name, value) => {
    const { values } = this.state;
    this.setForm(values, name, value)
  };

  setForm = (values, name, value, row = {}) => {
    values[name] = value;

    this.setValidate(values)
  }

  setValidate = (values) => {
    const schema = this.getSchemaActivitas(values)
    const errors = validate(values, schema);
    this.setState({
      values: values,
      errors: errors || {},
    });

    return errors
  }

  hasError = field => {
    return this.state.errors[field] ? true : false;
  }
  // HANDLE FORM END ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  getSchemaActivitas = (values) => {
    let schema = {}

    if (values.jenis_pendaftaran || values.tgl_masuk || values.sekolah_asal || values.no_ijazah || values.tgl_ijazah) {
      schema = { ...schemaActivitas }
    }

    return schema
  }

  onSubmit = () => {
    const { values } = this.state;

    //Validation Orang Tua
    const schema = this.getSchemaActivitas(values)
    const validation = this.setValidate(values, schema)
    if (validation !== undefined) {
      return _validationFormMsg(validation)
    }

    const params = {
      jenis_pendaftaran: values.jenis_pendaftaran ? values.jenis_pendaftaran : null,
      tgl_masuk: _getDate(values.tgl_masuk),
      sekolah_asal: values.sekolah_asal ? values.sekolah_asal : null,
      no_ijazah: values.no_ijazah ? values.no_ijazah : null,
      tgl_ijazah: values.tgl_ijazah ? values.tgl_ijazah : null,
      jenjang_sekolah_asal: values.jenjang_sekolah_asal ? values.jenjang_sekolah_asal : null,
    }

    this.setState({
      loadingBtn: true,
    })
    if (values.id) {
      _setAxios("siswa/aktivitas-belajar/" + values.id, "PUT", params).then(resp => {
        if (resp.status === true) {
          _success('topRight', 'Success', 'Data berhasil diupdate')
          this.setState({
            isUpdate: false,
            loadingBtn: false
          });
          this.getAktivitasBelajar()
        } else {
          this.setState({
            loadingBtn: false
          });
        }
      })
    } else {
      if (params.jenis_pendaftaran) {
        _setAxios("siswa/aktivitas-belajar/" + this.props.match.params.id, "POST", params).then(resp => {
          if (resp.status === true) {
            _success('topRight', 'Success', 'Data berhasil disimpan')
            this.setState({
              isUpdate: false,
              loadingBtn: false
            });
            this.getAktivitasBelajar()
          } else {
            this.setState({
              loadingBtn: false
            });
          }
        })
      }
    }
  }

  render() {
    const { action } = this.props
    const { loading, dataAktivitasBelajar, errors, values } = this.state;

    const compDetail = <>
      <Row gutter={[16, 16]}>
        {
          !loading && (action.update || action.update_partial) ?
            <Col xs={24} style={{ textAlign: "right" }}>
              <Tooltip placement="top" title={"Ubah data aktivitas belajar"}>
                <Button shape="circle" icon={<EditOutlined />} onClick={() => {
                  this.setState({
                    isUpdate: true
                  })
                }}></Button>
              </Tooltip>
            </Col>
            : ""
        }
        <Col xs={24} sm={12}>
          <Divider orientation="left"><h6>PENDAFTARAN MASUK</h6></Divider>

          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataAktivitasBelajar.data1}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
          <Divider orientation="left"><h6>KETERANGAN KELUAR</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataAktivitasBelajar.data2}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>

        <Col xs={24} sm={12}>
          <Divider orientation="left"><h6>STATUS SEKARANG</h6></Divider>
          {
            !loading ?
              <List
                itemLayout="horizontal"
                dataSource={dataAktivitasBelajar.data3}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
              :
              <Skeleton />
          }
        </Col>
      </Row>
    </>

    const form = <Row gutter={[24, 24]}>
      <Col xs={24} sm={24}>
        <Card className='form-box'>
          <CardContent>
            <Row gutter={[24, 24]} >
              <Col xs={24}>
                <Alert message="Pendaftaran Masuk" type="info" showIcon />
              </Col>
              <Col xs={12}>
                <Autocomplete
                  options={jenisPendaftaranOptions}
                  value={values.jenis_pendaftaran || null}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("jenis_pendaftaran", newValue ? newValue.label : null)
                  }}

                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label="Jenis Pendaftaran"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="jenis_pendaftaran"
                    type="text"
                    error={this.hasError('jenis_pendaftaran')}
                    helperText={
                      this.hasError('jenis_pendaftaran') ? errors.jenis_pendaftaran[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      label="Tanggal Masuk"
                      inputFormat="dd-MM-yyyy"
                      value={values.tgl_masuk || null}
                      onChange={(newValue) => {
                        this.handleChangeSetValue("tgl_masuk", newValue)
                      }}
                      renderInput={(params) => <TextField
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          this.hasError('tgl_masuk') ? errors.tgl_masuk[0] : null
                        }
                        {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  fullWidth
                  label="Sekolah Asal"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="sekolah_asal"
                  type="text"
                  onChange={this.handleChange}
                  value={values.sekolah_asal || null}
                  error={this.hasError('sekolah_asal')}
                  helperText={
                    this.hasError('sekolah_asal') ? errors.sekolah_asal[0] : null
                  }
                />
              </Col>
              <Col xs={12}>
                <Autocomplete
                  options={jenjangSekolahAsalOptions}
                  value={values.jenjang_sekolah_asal || null}
                  onChange={(e, newValue) => {
                    this.handleChangeSetValue("jenjang_sekolah_asal", newValue ? newValue.label : null)
                  }}

                  renderInput={(params) => <TextField {...params}
                    fullWidth
                    label="Jenjang Sekolah Asal"
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="jenjang_sekolah_asal"
                    type="text"
                    error={this.hasError('jenjang_sekolah_asal')}
                    helperText={
                      this.hasError('jenjang_sekolah_asal') ? errors.jenjang_sekolah_asal[0] : null
                    }
                  />}
                />
              </Col>
              <Col xs={12}>
                <TextField
                  fullWidth
                  label="Nomor Ijazah"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="no_ijazah"
                  type="text"
                  onChange={this.handleChange}
                  value={values.no_ijazah || null}
                  error={this.hasError('no_ijazah')}
                  helperText={
                    this.hasError('no_ijazah') ? errors.no_ijazah[0] : null
                  }
                />
              </Col>
              <Col xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <MobileDatePicker
                      label="Tanggal Ijazah"
                      value={values.tgl_ijazah || null}
                      onChange={(newValue) => {
                        this.handleChangeSetValue("tgl_ijazah", newValue)
                      }}
                      renderInput={(params) => <TextField
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={
                          this.hasError('tgl_ijazah') ? errors.tgl_ijazah[0] : null
                        }
                        {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </Col>
            </Row>
          </CardContent>
        </Card>
      </Col>

      <Col xs={24} style={{ textAlign: "right" }}>
        <Button style={{ margin: 0 }} loading={this.state.loadingBtn} onClick={() => {
          this.setState({
            isUpdate: false
          })
        }}>
          Batal
        </Button>
        <Button style={{ margin: '0 0 0 8px' }} type="primary" htmlType="submit" icon={<SaveOutlined />} loading={this.state.loadingBtn} onClick={this.onSubmit}>
          Simpan
        </Button>
      </Col>
    </Row>

    return (
      <>
        {this.state.isUpdate ? form : compDetail}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(ActivitasBelajar);
