import React from 'react';

import { validate } from 'validate.js';
import { Row, Col, Modal, Button, Input, Divider, Form } from 'antd';
import { _success, _setAxios, _validationFormMsg } from '../../../../lib/Helper';
import SelectDropdown from '../../../../components/select/SelectDropdown';
import SwitchBasic from '../../../../components/switch/SwitchBasic';
const { TextArea } = Input;

const schema = {
    nama: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
    label: {
        presence: { allowEmpty: false, message: 'wajib diisi' },
    },
};

class FormContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,

            visible: false,
        };
    }

    componentDidMount() {
        this.setState({
            values: {
                id: this.props.data ? this.props.data.id : null,
                nama: this.props.data ? this.props.data.modul_id : null,
                label: this.props.data ? this.props.data.list : null,
                keterangan: this.props.data ? this.props.data.keterangan : null,
                is_active: this.props.data ? (this.props.data.is_active === "1" ? true : false) : false
            },
        })
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;
        const errors = validate(values, schema);

        this.setState({
            values: values,
            errors: errors || {},
        });
    }

    handleSubmit = async () => {
        const { values } = this.state
        const errors = validate(values, schema);
        if (errors) {
            return _validationFormMsg(errors)
        }
        this.setState({
            loading: true
        });

        const params = {
            modul_id: values.nama ? values.nama : null,
            list: values.label ? values.label : null,
            keterangan: values.keterangan ? values.keterangan : null,
            is_active: values.is_active ? 1 : 0
        }

        let endpoint = "setting/dropdown/list"
        let method = "POST"
        if (values.id) {
            endpoint = "setting/dropdown/list/" + values.id
            method = "PUT"
        }

        _setAxios(endpoint, method, params).then(resp => {
            if (resp.status === true) {
                _success('topRight', 'Success', resp.data.message)
                this.props.fetchTable(true)
                this.props.hideModal()

                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        })
    };

    render() {

        const { values } = this.state
        const data = this.props.data
        console.log("values", values);
        return (
            <Modal
                maskClosable={false}
                title={"Form Dropdown List"}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                closable={false}
                footer={null}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                    onFinish={this.handleSubmit}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24}>
                            <Form.Item label="Dropdown *">
                                <SelectDropdown
                                    defaultValue={data.nama}
                                    value={values.nama}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("nama", val ? val : null)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label="Label *">
                                <Input
                                    style={{ width: '100%' }}
                                    name="label"
                                    placeholder="label"
                                    defaultValue={values.label}
                                    value={values.label || null}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("label", e.target.value)
                                    }} />
                            </Form.Item>
                            <Form.Item label="Keterangan">
                                <TextArea
                                    rows={4}
                                    placeholder="keterangan..."
                                    defaultValue={this.props.data.keterangan}
                                    onChange={(e) => {
                                        this.handleChangeSetValue("keterangan", e.target.value)
                                    }}
                                />
                            </Form.Item>
                            {values.id ? <Form.Item label="">
                                <SwitchBasic
                                    value={values.is_active}
                                    defaultChecked={values.is_active}
                                    onChange={(val) => {
                                        this.handleChangeSetValue("is_active", val)
                                    }} /> Aktifkan jika ingin data tersebut tampil
                            </Form.Item> : null}
                        </Col>
                        <Col xs={24} style={{ textAlign: "right" }}>
                            <Divider />
                            <Button onClick={this.props.hideModal}>Batal</Button> &nbsp;
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>Simpan</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
}

export default FormContent;
