import React from 'react';
import { Modal, Button, Form, Row, Col } from 'antd';
import { SelectRombel } from '../../../../components/select';

class Filter extends React.Component {
    render() {
        return (
            <Modal
                title="Filter"
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Rombel">
                                <SelectRombel
                                    allowClear
                                    value={this.props.values.rombel_id}
                                    onChange={(val) => {
                                        this.props.setTempTableFilter("rombel_id", val ? val : null)
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
