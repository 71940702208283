import React from 'react';
import { Modal, DatePicker, Button, Form, Row, Col, Select } from 'antd';
import { jenisTamuOptions, tujuanBukuTamuOptions } from '../../../data/options';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';

dayjs.extend(customParseFormat);
const dateFormat = 'DD-MM-YYYY';

const { Option } = Select;
const { RangePicker } = DatePicker;

class Filter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {},
            errors: {},
            loading: false,
        };
    }

    handleChangeSetValue = (name, value) => {
        const { values } = this.state;
        this.setForm(values, name, value)
    };

    setForm = (values, name, value) => {
        values[name] = value;

        this.setState({
            values: values,
        });
    }

    hasError = field => {
        return this.state.errors[field] ? true : false;
    }

    render() {

        return (
            <Modal
                title="Filter"
                width={300}
                visible={this.props.visible}
                onCancel={this.props.hideModal}
                footer={<>
                    <Button onClick={this.props.hideModal}>Tutup</Button>
                    <Button type='primary' onClick={this.props.setTableFilterFromTemp}>Terapkan</Button>
                </>}
            >
                <Form
                    name="basic"
                    autoComplete="off"
                    layout="vertical"
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item label="Jenis Tamu">
                                <Select
                                    allowClear
                                    name="jenis_tamu"
                                    placeholder="Jenis tamu"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("jenis_tamu", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {jenisTamuOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Tujuan">
                                <Select
                                    allowClear
                                    name="tujuan"
                                    placeholder="Tujuan"
                                    onChange={(e, newValue) => {
                                        this.props.setTempTableFilter("tujuan", newValue ? newValue.value : null)
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    {tujuanBukuTamuOptions.map((row, i) => <Option key={i} value={row.value}>{row.label}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Tanggal *">
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format={dateFormat}
                                    onChange={val => {

                                        if (val) {
                                            let periodeAwal = moment(val[0]).format(dateFormat)
                                            let periodeAkhir = moment(val[1]).format(dateFormat)

                                            this.props.setTempTableFilter("tgl_awal", periodeAwal)
                                            this.props.setTempTableFilter("tgl_akhir", periodeAkhir)
                                        }else{
                                            this.props.setTempTableFilter("tgl_awal", null)
                                            this.props.setTempTableFilter("tgl_akhir", null)
                                        }
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal >
        )
    }
}

export default Filter;
